import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    ContentLoader,
    ErrorMessage,
    Typography,
} from '@capture/capture-components'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import { RoutePath } from '~/routing'
import { _ } from '~/assets/localization/util'
import TrashIllustration from '~/assets/illustrations/trash-illustration.svg'
import { fontSize } from '~/assets/styleConstants'
import { PageWrapper } from '~/components/Common/PageWrapper'
import { useDeletedAlbums } from '~/components/Trash/useDeletedAlbums'
import { TrashAlbumEntry } from '~/components/Trash/TrashAlbumEntry'
import { TwoAreasTopNavBar } from '~/components/Navigation/TwoAreasTopNavBar'
import { IconTextButton } from '~/components/Common/IconTextButton'
import { EmptyStatePage } from '../Common/EmptyStatePage'

const PageContent = styled.main`
    padding: 1.5rem 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ImageGrid = styled.section`
    display: grid;
    // fill with as many as possible, keeping each thumbnail at least 160px wide
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    column-gap: 1rem;
    row-gap: 1.5rem;
`

export const TrashAlbumPage = () => {
    const navigate = useNavigate()
    const goToTrashEntry = () => {
        navigate(RoutePath.Trash)
    }
    const { data, error, isLoading } = useDeletedAlbums()

    const content = (() => {
        if (isLoading) {
            return <ContentLoader />
        }

        if (error) {
            return <ErrorMessage>{_('something_went_wrong')}</ErrorMessage>
        }

        if (data === undefined) {
            return null
        }

        if (data.length === 0) {
            return (
                <EmptyStatePage
                    illustration={
                        <img src={TrashIllustration} alt="Empty trash" />
                    }
                    header={_('trash_no_albums')}
                    subHeader={
                        <Typography.Paragraph>
                            {_('trash_delete_info_albums')}
                        </Typography.Paragraph>
                    }
                />
            )
        }
        return (
            <PageContent>
                <Typography.SectionHeader>
                    {_('recently_deleted_albums')}
                </Typography.SectionHeader>
                <ImageGrid>
                    {data.map(
                        ({ id, coverUrl, name, remainingDays, subtitle }) => {
                            return (
                                <TrashAlbumEntry
                                    key={id}
                                    id={id}
                                    coverUrl={coverUrl}
                                    name={name}
                                    remainingDays={remainingDays}
                                    subtitle={subtitle}
                                />
                            )
                        },
                    )}
                </ImageGrid>
            </PageContent>
        )
    })()

    return (
        <PageWrapper
            isContentReady={!isLoading}
            navBar={
                <TwoAreasTopNavBar
                    hideUserAvi
                    left={
                        <IconTextButton
                            onClick={goToTrashEntry}
                            text={_('recently_deleted')}
                            icon={BackArrowIcon}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }
            hideFooter>
            {content}
        </PageWrapper>
    )
}
