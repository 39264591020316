import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { acceptedExtensions } from '~/utilities/fileTarget'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import UploadOptionIllustration from '~/assets/illustrations/upload-options-illustration.svg'
import { colors, mediaQueries } from '~/assets/styleConstants'
import { isMobileDevice } from '~/utilities/device'
import { FileAcceptElement } from '../Uploader/FileAcceptElement'
import type { Folder } from '../Uploader/FileAcceptZone'
import { FileAcceptZone } from '../Uploader/FileAcceptZone'
import { Button } from './Button'
import { FilledTextButton } from './IconTextButton'
import { OptionsOverlay } from './Overlay'
import { useDragDetectionEffect } from './withDragDetection'
import { ReadonlyTooltip } from './ReadonlyTooltip'

const OptionsContainer = styled.div<{
    isActive: boolean
    isMobile: boolean
    isDragSupported: boolean
}>`
    position: relative;
    width: 100%;
    min-height: ${(props) => (props.isMobile ? '350px' : '425px')};
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border: ${(props) =>
        props.isDragSupported ? `3px dashed ${colors.captureBlue}` : 'none'};
    text-align: center;
    line-height: 2;
    padding: 18px;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
            props.isActive ? `rgba(${colors.captureBlueRGB}, 0.4)` : 'inherit'};
        pointer-events: none;
    }

    img {
        max-width: 60%;
    }
`

const OptionsWrapper = styled.div`
    min-width: 568px;

    ${mediaQueries.mobile} {
        min-width: inherit;
    }
`

type Props = {
    doHandleDraggedFiles: (files: Folder) => void
    doHandleFiles: (files: File[]) => void
    illustration?: React.ReactNode
    onClose: () => void
    doAddFromCapture?: () => void
    isDeviceUploadDisabled?: boolean
    isCaptureUploadDisabled?: boolean
    isDisabledForReadonly?: boolean
    handleDeviceUploadClicked?: (e: React.TouchEvent<HTMLDivElement>) => void
}
export const UploadOptions: React.FC<Omit<Props, 'onClose'>> = ({
    doAddFromCapture,
    doHandleFiles,
    doHandleDraggedFiles,
    illustration,
    isDeviceUploadDisabled,
    isCaptureUploadDisabled,
    isDisabledForReadonly,
    handleDeviceUploadClicked,
}) => {
    const isDragging = useDragDetectionEffect()
    const isMobile = isMobileDevice.any()
    const isDragSupported = !isMobile
    const [dragDrop, or] = _('drag_drop_files__format').split('%split%')

    const addFromCaptureBtn = () => {
        if (isDisabledForReadonly) {
            return (
                <ReadonlyTooltip
                    renderElement={(isReadOnlyUser) => (
                        <FilledTextButton
                            text={_('add_from_capture__format').replace(
                                '%product_name%',
                                PRODUCT_NAME,
                            )}
                            onClick={doAddFromCapture}
                            style={{ marginTop: 8 }}
                            data-cy={'uploadFromCaptureOption'}
                            isDisabled={
                                isCaptureUploadDisabled || isReadOnlyUser
                            }
                        />
                    )}
                />
            )
        }

        return (
            <FilledTextButton
                text={_('add_from_capture__format').replace(
                    '%product_name%',
                    PRODUCT_NAME,
                )}
                onClick={doAddFromCapture}
                style={{ marginTop: 8 }}
                data-cy={'uploadFromCaptureOption'}
                isDisabled={isCaptureUploadDisabled}
            />
        )
    }

    const content = (
        <OptionsContainer
            isActive={isDragging}
            isMobile={isMobile}
            isDragSupported={isDragSupported}>
            {illustration || (
                <img src={UploadOptionIllustration} alt="Upload options" />
            )}
            <div>
                {isDragSupported && (
                    <>
                        {dragDrop}
                        <br />
                        {or} <br />
                    </>
                )}
                <div>
                    <FileAcceptElement
                        fileHandler={doHandleFiles}
                        isDisabled={isDeviceUploadDisabled}
                        acceptTypes={acceptedExtensions}>
                        {isDisabledForReadonly ? (
                            <ReadonlyTooltip
                                renderElement={(isReadOnlyUser) => (
                                    <FilledTextButton
                                        text={_('select_from_device')}
                                        data-cy={'uploadFromDeviceOption'}
                                        isDisabled={
                                            isReadOnlyUser ||
                                            isDeviceUploadDisabled
                                        }
                                        onClick={handleDeviceUploadClicked}
                                    />
                                )}
                            />
                        ) : (
                            <FilledTextButton
                                text={_('select_from_device')}
                                data-cy={'uploadFromDeviceOption'}
                                isDisabled={isDeviceUploadDisabled}
                                onClick={handleDeviceUploadClicked}
                            />
                        )}
                    </FileAcceptElement>
                    {doAddFromCapture && addFromCaptureBtn()}
                </div>
            </div>
        </OptionsContainer>
    )

    let fileAcceptOverlay = null
    if (isDragSupported && isDragging) {
        const overlayTarget = document.getElementById('upload-overlay-portal')
        const portalContent = (
            <div style={{ position: 'fixed', inset: 0, zIndex: 1001 }}>
                <FileAcceptZone fileHandler={doHandleDraggedFiles} />
            </div>
        )
        if (overlayTarget) {
            fileAcceptOverlay = createPortal(portalContent, overlayTarget)
        }
    }

    return (
        <>
            {content}
            {fileAcceptOverlay}
        </>
    )
}

export const UploadOptionsModal = (props: Props) => {
    return (
        <OptionsOverlay
            onClose={props.onClose}
            cancelButton={Button(_('cancel'), props.onClose)}>
            <OptionsWrapper>
                <UploadOptions {...props} />
            </OptionsWrapper>
        </OptionsOverlay>
    )
}
