import type { CAPBAKGetThumbnailTrash3TrashJobUuidFileUuidGetParams } from '@capture/client-api/src/schemas/data-contracts'
import type { ExtraQueryParamsOf } from '~/@types/backend-types'
import { HostUrl } from '../toolbox'

export class ThumbService {
    private hostUrl: HostUrl

    constructor(hostname: string, authToken: string) {
        const commonQueryParams: DictionaryOf<string> = {
            auth: authToken,
            key: import.meta.env?.VITE_API_KEY,
            client_v: import.meta.env?.VITE_VERSION,
        }
        this.hostUrl = new HostUrl(hostname, commonQueryParams)
    }

    // area[small, medium, large] = [256, 512, 1280]
    public getThumbUrl(jobID: JobID, fileID: FileID, area = 256): URLstring {
        return this.hostUrl.getPath(`/th/3/${fileID}`, {
            area,
            share: jobID,
        })
    }

    // area[small, medium, large] = [256, 512, 1280]
    public getTrashedThumbUrl(
        jobID: JobID,
        fileID: FileID,
        params: ExtraQueryParamsOf<
            CAPBAKGetThumbnailTrash3TrashJobUuidFileUuidGetParams,
            'jobUuid' | 'fileUuid'
        >,
    ): URLstring {
        return this.hostUrl.getPath(`/th/3/trash/${jobID}/${fileID}`, params)
    }
}
