import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    PageCard,
    PageNeutralButton,
    Typography,
} from '@capture/capture-components'
import AlbumIcon from '@capture/capture-components/src/icons/album-page.svg?react'
import BackArrowIcon from '@capture/capture-components/src/icons/arrow-back.svg?react'
import PhotosIcon from '@capture/capture-components/src/icons/photos-and-videos.svg?react'
import { Pages, RoutePath } from '../../routing'
import { PageWrapper } from '../Common/PageWrapper'
import { _ } from '../../assets/localization/util'
import { fontSize } from '../../assets/styleConstants'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { IconTextButton } from '../Common/IconTextButton'

const Content = styled.section`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const TrashPageEntry = () => {
    const navigate = useNavigate()

    return (
        <PageWrapper
            hideFooter
            navBar={
                <TwoAreasTopNavBar
                    left={
                        <IconTextButton
                            onClick={() => navigate(Pages.Settings.url)}
                            text={_('settings')}
                            icon={BackArrowIcon}
                            fontSize={fontSize.small_14}
                        />
                    }
                />
            }>
            <PageCard>
                <Typography.SectionHeader>
                    {_('recently_deleted')}
                </Typography.SectionHeader>
                <Typography.Divider />
                <Content>
                    <PageNeutralButton
                        onPress={() => navigate(RoutePath.TrashPhotos)}>
                        <PhotosIcon />
                        {_('photos_and_videos')}
                    </PageNeutralButton>
                    <PageNeutralButton
                        onPress={() => navigate(RoutePath.TrashAlbums)}>
                        <AlbumIcon />
                        {_('albums')}
                    </PageNeutralButton>
                </Content>
            </PageCard>
        </PageWrapper>
    )
}
