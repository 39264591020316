import { useDispatch } from 'react-redux'
import {
    AlbumThumbnail,
    ContextMenu,
    DropdownMenu,
} from '@capture/capture-components'
import ExportIcon from '@capture/capture-components/src/icons/export.svg?react'
import { _ } from '~/assets/localization/util'
import { downloadDeletedAlbum } from '~/API/download'

interface TrashAlbumEntryProps {
    id: JobID
    coverUrl?: URLstring
    name: string
    remainingDays: string
    subtitle: string
}

export const TrashAlbumEntry = ({
    id,
    coverUrl,
    name,
    remainingDays,
    subtitle,
}: TrashAlbumEntryProps) => {
    const dispatch = useDispatch()

    const exportAlbum = async () => downloadDeletedAlbum(dispatch, id, name)

    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger>
                <AlbumThumbnail
                    key={id}
                    title={name}
                    subtitle={subtitle}
                    coverPhoto={coverUrl}>
                    <AlbumThumbnail.TopRight>
                        <AlbumThumbnail.MenuButton>
                            <DropdownMenu.Item
                                onSelect={exportAlbum}
                                id="export"
                                icon={ExportIcon}>
                                {_('export')}
                            </DropdownMenu.Item>
                        </AlbumThumbnail.MenuButton>
                    </AlbumThumbnail.TopRight>

                    <AlbumThumbnail.BottomRight>
                        {remainingDays}
                    </AlbumThumbnail.BottomRight>
                </AlbumThumbnail>
                <ContextMenu.Content>
                    <ContextMenu.Item
                        onSelect={exportAlbum}
                        id="export"
                        icon={ExportIcon}>
                        {_('export')}
                    </ContextMenu.Item>
                </ContextMenu.Content>
            </ContextMenu.Trigger>
        </ContextMenu.Root>
    )
}
