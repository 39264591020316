import { consoleLog } from '~/utilities/logging'
import { FetchObject } from './FetchObject'

type ParameterVaule = string | number | boolean | undefined
/**
 *
 * @param params: Dictionary of strings
 * @returns {string}
 */
export const getQueryString = (
    params: Record<string, ParameterVaule>,
): string => {
    if (Object.keys(params).length === 0) {
        return ''
    }

    return (
        '?' +
        Object.entries(params)
            .filter(
                (
                    entry,
                ): entry is [string, Exclude<ParameterVaule, undefined>] =>
                    entry[1] !== undefined,
            )
            .map(([key, value]) => {
                if (typeof value === 'boolean') {
                    return `${key}=${value ? '1' : '0'}`
                }

                return `${key}=${encodeURIComponent(value)}`
            })
            .join('&')
    )
}

export class HostUrl {
    constructor(
        private hostname: string,
        private commonQueryParams?: Record<string, ParameterVaule>,
    ) {}

    public getPath(
        path: string,
        params?: Record<string, ParameterVaule>,
    ): string {
        const queryParams: Record<string, ParameterVaule> = {
            ...this.commonQueryParams,
            ...params,
        }
        return `https://${this.hostname + path}${getQueryString(queryParams)}`
    }

    public getCleanPath(path: string): string {
        return `https://${this.hostname + path}`
    }
}

export class ResponseNotOKError extends Error {
    constructor(public response: Response) {
        // 'Error' breaks prototype chain here
        super(response.statusText, { cause: response })

        // restore prototype chain
        const actualProto = new.target.prototype
        Object.setPrototypeOf(this, actualProto)
    }
}

function makeSureResponseIsOK(response: Response): Response {
    if (!response.ok) {
        if (response.status === 401) {
            onUnauthenticated.forEach((f) => f())
        }
        throw new ResponseNotOKError(response)
    }
    return response
}

const onOffline: Array<() => unknown> = []
export function whenNetworkGoesOffline(callback: () => unknown) {
    onOffline.push(callback)
}

const onOnline: Array<() => unknown> = []
export function whenNetworkComesOnline(callback: () => unknown) {
    onOnline.push(callback)
}

const onUnauthenticated: Array<() => unknown> = []
export function whenUnauthenticated(callback: () => unknown) {
    onUnauthenticated.push(callback)
}

let isOnline = true
function detectedOnlineState(newState: boolean) {
    if (newState !== isOnline) {
        isOnline = newState
        consoleLog('New online-state: isOnline = ', isOnline)
        ;(isOnline ? onOnline : onOffline).forEach((fun) => fun())
    }
}
export type FetchMethod = (url: string, init?: RequestInit) => Promise<Response>
const _fetch: FetchMethod = (url, init) => {
    return fetch(url, init)
        .then(
            (ok: Response) => {
                detectedOnlineState(true)
                return ok
            },
            (err) => {
                detectedOnlineState(false)
                throw err
            },
        )
        .then(makeSureResponseIsOK)
}

/**
 * Downloads data from endpoint leveraging an <a> tag
 * Similar to sendPOSTRedirect
 * @param downloadUrl
 */
export const downloadThroughAnchor = (downloadUrl: URLstring) => {
    window.onbeforeunload = null
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)
    downloadLink.setAttribute('style', 'display: none')
    downloadLink.setAttribute('href', downloadUrl)
    downloadLink.setAttribute('download', '')
    downloadLink.click()
    downloadLink.remove()
}

/**
 * Downloads data from endpoint leveraging a form
 * Similar to downloadThroughAnchor, potentially more robust for cross vendor compatibility
 * @param url
 * @param data
 */
export const sendPOSTRedirect = async (url: string, data: unknown) => {
    const wrapper = document.createElement('div')
    wrapper.style.height = '0 px'
    wrapper.style.overflow = 'hidden'
    wrapper.style.display = 'none'

    const form = document.createElement('form')
    form.method = 'POST'
    form.action = url

    const inputElement = document.createElement('input')
    inputElement.name = 'data'
    inputElement.value = JSON.stringify(data)

    document.body.appendChild(wrapper)
    wrapper.appendChild(form)
    form.appendChild(inputElement)
    form.submit()
    document.body.removeChild(wrapper)
}

/**
 * Tool-functions to abstract away the inner workings of fetch returning a Promise in the way we are used to when using
 * tools like jQuery (ie treating a HTTP-status-error as an error etc)
 *
 * @param url
 * @returns {Promise<any>}
 */
export const BrowserFetchObject = new FetchObject(_fetch)

/** @deprecated use FetchObject.get instead */
export function get(
    url: string,
    headers: DictionaryOf<string> = {},
): Promise<Response> {
    return BrowserFetchObject.get(url, headers).rawResponse()
}

/** @deprecated use FetchObject.get instead */
export function getJSON<T>(
    url: string,
    headers: DictionaryOf<string> = {},
): Promise<T> {
    return BrowserFetchObject.get(url, headers).asJson<T>()
}

/** @deprecated use FetchObject.post instead */
export function post(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<Response> {
    return BrowserFetchObject.post(url, body, headers).rawResponse()
}
/** @deprecated use FetchObject.post instead */
export function postJSON<T>(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<T> {
    return BrowserFetchObject.post(url, body, headers).asJson<T>()
}

// delete-function is named Delete with capital D because the keyword delete is reserved...
/** @deprecated use FetchObject.delete instead */
export function Delete(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<Response> {
    return BrowserFetchObject.delete(url, body, headers).rawResponse()
}
/** @deprecated use FetchObject.delete instead */
export function deleteJSON<T>(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<T> {
    return BrowserFetchObject.delete(url, body, headers).asJson<T>()
}

/** @deprecated use FetchObject.put instead */
export function put(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<Response> {
    return BrowserFetchObject.put(url, body, headers).rawResponse()
}

/** @deprecated use FetchObject.put instead */
export function putJSON<T>(
    url: string,
    body?: FormData | string,
    headers: DictionaryOf<string> = {},
): Promise<T> {
    return BrowserFetchObject.put(url, body, headers).asJson<T>()
}

export const SHA256 = async (text: string): Promise<string> => {
    const encoder = new TextEncoder()
    const textUint8 = encoder.encode(text)
    const hashBuffer = await crypto.subtle.digest('SHA-256', textUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => String.fromCharCode(b)).join('')
}

export const base64URLEncode = (text: string): string => {
    return btoa(text).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const generatePkce = (): string => {
    const array = new Uint32Array(128)
    window.crypto.getRandomValues(array)
    const pickString = (item: number) => {
        const str = `0${item.toString(16)}`
        return str.substring(str.length - 2)
    }
    const pkce = Array.from(array, (item) => pickString(item)).join('')
    return pkce
}
