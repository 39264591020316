import type { ReactElement } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import NoInternetIcon from '@capture/capture-components/src/icons/offline.svg?react'
import WarningIcon from '@capture/capture-components/src/icons/warning.svg?react'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import {
    addOnBeforeUnloadBlock,
    removeOnBeforeUnloadBlock,
} from '~/utilities/onBeforeUnload'
import type { ButtonProps } from '../Common/Button'
import { Button } from '../Common/Button'
import { AsyncPreviewThumb } from './AsyncPreviewThumb'
import { MultiThumb } from './MultiThumb'
import { StopPrompt } from './StopUploadPrompt'
import { UploadStatusBoxContent } from './UploadStatusBoxContent'
import { UploadStatusBoxFooter } from './UploadStatusBoxFooter'
import { UploadStatusBoxList } from './UploadStatusBoxList'
import { useUploadStatusPlacement } from './useUploadStatusPlacement'

const Wrapper = styled.div`
    position: fixed;
    bottom: 8px;
    left: 40px;
    width: 304px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 5px;
`

const StatusBoxWrapper = styled.div`
    height: ${(props: { height: number }) => props.height}px;
    background-color: white;
    box-shadow: 3px -3px 20px 2px rgba(0, 0, 0, 0.21);
    transition: height 0.3s ease-in-out;
`

export const UploadStatusPlacement = () => {
    const {
        fileToCheckForUploading,
        isMobile,
        isExpanded,
        isVisible,
        isUploadOffline,
        isUploadRetrying,
        isUploading,
        isUploadPendingStop,
        isUploadOutOfStorage,
        uploadProgress,
        visibleFiles,
        totalFilesInQueue,
        completedFiles,
        unSuccessfulFiles,
        numberOfRejectedFiles,
        currentFile,
        uploaderHeight,
        randomFinishedImages,
        isBackendSucceeded,
        doExpand,
        doCollapse,
        doClose,
        doOpen,
        doRetry,
        doStopPrompt,
        doStopAborted,
        onFilterList,
        clearUploadQueue,
    } = useUploadStatusPlacement()

    const navigate = useNavigate()

    const prevIsUploading = useRef(isUploading)
    const prevNumberOfRejectedFiles = useRef(numberOfRejectedFiles)
    const prevIsVisible = useRef(isVisible)

    const [retryingIn, setRetryingIn] = useState<number | undefined>(undefined)
    const [retryTimer, setRetryTimer] = useState<number | undefined>(undefined)

    useEffect(() => {
        addOnBeforeUnloadBlock(() => isUploading)
        return () => {
            removeOnBeforeUnloadBlock(() => isUploading)
        }
    }, [isUploading])

    useEffect(() => {
        if (
            (isUploading ||
                numberOfRejectedFiles > prevNumberOfRejectedFiles.current) &&
            !prevIsVisible.current
        ) {
            doOpen()
        } else if (!isVisible && !isUploading && prevIsUploading.current) {
            clearUploadQueue()
        }
        prevIsVisible.current = isVisible
        prevIsUploading.current = isUploading
        prevNumberOfRejectedFiles.current = numberOfRejectedFiles
    }, [
        clearUploadQueue,
        doOpen,
        isUploading,
        isVisible,
        numberOfRejectedFiles,
    ])

    const retryNow = useCallback(() => {
        if (retryTimer) {
            clearInterval(retryTimer)
        }
        setRetryingIn(undefined)
        setRetryTimer(undefined)
        doRetry()
    }, [doRetry, retryTimer])

    useEffect(() => {
        if (isUploadOffline && retryingIn === undefined) {
            const timer = window.setInterval(() => {
                if (retryingIn !== undefined && retryingIn > 1) {
                    setRetryingIn(retryingIn - 1)
                } else {
                    retryNow()
                }
            }, 1000)
            setRetryingIn(4)
            setRetryTimer(timer)
        }
    }, [isUploadOffline, retryNow, retryingIn])

    const close = () => {
        doClose()
        if (isExpanded) {
            doCollapse()
        }
    }

    const filter = () => {
        onFilterList()
        doExpand()
    }

    const getCurrentElementConfig = () => {
        let mainText = '',
            subText = '',
            buttons: ButtonProps[] = [],
            thumb: ReactElement<HTMLDivElement>,
            isExpandable = true,
            leftButtonPosition = 120

        if (isUploadOffline) {
            mainText = _('offline')
            subText = `
                ${completedFiles} ${_('of')} ${totalFilesInQueue} \n
                ${_('retrying_in')} ${retryingIn} ${_('sec')}
            `
            buttons = [
                Button(_('stop'), doStopPrompt),
                Button(_('try_again'), () => retryNow()),
            ]
            thumb = (
                <NoInternetIcon
                    width={56}
                    height={56}
                    color={colors.captureGrey800}
                />
            )
            leftButtonPosition = 38
        } else if (isUploadRetrying) {
            mainText = _('offline')
            subText = _('retrying_now')
            buttons = [
                Button(_('try_again'), () => undefined, { isDisabled: true }),
            ]
            thumb = (
                <NoInternetIcon
                    width={56}
                    height={56}
                    color={colors.captureGrey800}
                />
            )
        } else if (isUploadOutOfStorage) {
            mainText = _('out_of_storage')
            subText = _('free_up_space')
            buttons = [Button(_('stop'), doStopPrompt)]
            thumb = <WarningIcon width={56} height={56} />
        } else {
            if (uploadProgress < 1 || isBackendSucceeded || currentFile) {
                mainText = _('uploading')
                subText = `${completedFiles} ${_('of')} ${totalFilesInQueue}`
                buttons = [Button(_('stop'), doStopPrompt)]
                thumb = (
                    <AsyncPreviewThumb
                        fileName={fileToCheckForUploading.name}
                        uploadId={fileToCheckForUploading.id}
                    />
                )
            } else if (unSuccessfulFiles > 0) {
                isExpandable = false
                mainText = `${unSuccessfulFiles} ${_(
                    'of',
                )} ${totalFilesInQueue} ${_('file_s')} \n
                            ${_('did_not_upload')}`
                subText = ''
                buttons = [Button(_('ok'), () => close())]
                if (!isExpanded) {
                    buttons.unshift(Button(_('see_which'), () => filter()))
                }
                thumb =
                    numberOfRejectedFiles > 0 ? (
                        <WarningIcon width={56} height={56} />
                    ) : (
                        <MultiThumb files={randomFinishedImages} />
                    )
            } else {
                isExpandable = false
                mainText = `${completedFiles} ${_('file_s')} \n${_('uploaded')}`
                subText = `${completedFiles} ${_('of')} ${totalFilesInQueue}`
                buttons = [Button(_('ok'), () => close())]
                thumb = <MultiThumb files={randomFinishedImages} />
            }
        }

        return {
            mainText,
            subText,
            buttons,
            thumb,
            isExpandable,
            leftButtonPosition,
        }
    }

    const stopUpload = () => {
        getConnectedInstance().stop(navigate)
    }

    if (isMobile || !isVisible) {
        return null
    }

    return (
        <Wrapper data-cy={'upload_status'}>
            <StatusBoxWrapper height={uploaderHeight}>
                {isExpanded ? (
                    <UploadStatusBoxList
                        filesInList={visibleFiles}
                        availableHeight={uploaderHeight - 64}
                        isOffline={isUploadOffline}
                        statusText={
                            isUploading && !isUploadOffline
                                ? getCurrentElementConfig().subText
                                : getCurrentElementConfig().mainText
                        }
                        displayWarningIcon={
                            !isUploading && numberOfRejectedFiles > 0
                        }
                        doCollapse={doCollapse}
                    />
                ) : (
                    <UploadStatusBoxContent
                        mainText={getCurrentElementConfig().mainText}
                        subText={getCurrentElementConfig().subText}
                        thumb={getCurrentElementConfig().thumb}
                        doExpand={doExpand}
                    />
                )}
            </StatusBoxWrapper>
            <UploadStatusBoxFooter
                hasBorder={isExpanded}
                buttons={getCurrentElementConfig().buttons}
                disableStatusBar={isUploadOffline || isUploadOutOfStorage}
                uploadProgress={uploadProgress}
                leftButtonMargin={getCurrentElementConfig().leftButtonPosition}
            />
            {isUploadPendingStop && (
                <StopPrompt doStopAborted={doStopAborted} doStop={stopUpload} />
            )}
        </Wrapper>
    )
}
