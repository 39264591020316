import LivePhotoIcon from '@capture/capture-components/src/icons/live-photo.svg?react'
import type { MouseEvent, ReactNode, SyntheticEvent } from 'react'
import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import type { FileGroupType } from '~/@types/backend-types'
import { BrowserFetchObject } from '~/API/toolbox'
import { colors, fontSize, zIndex } from '~/assets/styleConstants'
import CheckFilledIconBlue from '~/assets/svg/CheckFilledIconBlue.svg'
import CheckFilledIconGrey from '~/assets/svg/CheckFilledIconGrey.svg'
import CircleIcon from '~/assets/svg/CircleIcon.svg'
import ExpandPreviewIcon from '~/assets/svg/ExpandPreviewIcon.svg'
import ThumbnailPlaceholder from '~/assets/svg/ThumbnailPlaceholder.svg'
import FileContextMenu from '~/components/FileContextMenu'
import type { Icon } from '~/components/Icons'
import type { Dispatch } from '~/state/common/actions'
import { setContextMenuTarget } from '~/state/contextMenu/actions'
import type { CursorType } from '~/state/cursor/cursorSlice'
import { getCursorState } from '~/state/cursor/cursorSlice'
import type { BasicViewFile } from '~/state/files/selectors'
import { getVideoURL } from '~/state/files/selectors'
import { isMobileDevice } from '~/utilities/device'
import type { Point } from '../Common/ContextMenu'
import { IconButton } from '../Common/IconTextButton'
import { LongPressProvider } from '../Common/LongPressProvider'
import {
    localizedDateStringDefault,
    localizedTimeStringShort,
} from '../Common/TimeStrings'
import { VideoIndicator } from '../Common/VideoIndicator'
import { GridElement } from '../GridView/GridElement'
import { FullscreenPhotoOverlay } from '../PhotoSelection/FullscreenPhotoOverlay'
import type { FileSelectionStatus } from './ImageGroupList'

export const NormalItemImage = styled(GridElement)`
    margin: 0;
    background-color: rgba(${colors.captureGrey500rgb}, 0.1);
    cursor: ${(props: {
        isFocused?: boolean
        scaleItem?: boolean
        cursorType?: CursorType
    }) => (props.cursorType === 'auto' ? 'pointer' : props.cursorType)};
    user-select: none;
    outline: none;
`

export const NormalItemImageWrapper = styled.div`
    position: relative;
`

const SelectModeItemImage = styled(NormalItemImage)`
    ${(props: { selected: boolean }) =>
        props.selected
            ? 'transform: translateZ(0px) scale3d(0.9, 0.9, 1); opacity: 0.6;'
            : ''};
`

const SelectModeItemWrapper = styled.div`
    position: relative;
    cursor: pointer;
    user-select: none;
    outline: none;
`

const ItemTools = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    ${() =>
        !isMobileDevice.any()
            ? `
        &:hover .item-select-indicator {
            visibility: visible;
        }
    `
            : ''};
`

const NormalItemTools = styled(ItemTools)`
    ${() =>
        !isMobileDevice.any()
            ? `
        &:hover {
            background: linear-gradient(rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 25%);
        }
    `
            : ''};
`

const selectModeItemToolsBgStyles: Record<FileSelectionStatus, string> = {
    NotSelected:
        'background: linear-gradient(rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 25%);',
    ToBeSelected: `background: rgba(${colors.captureBlueRGB},0.5);`,
    Selected: '',
    ToBeDeselected: '',
}

const SelectModeItemTools = styled(ItemTools)`
    ${(props: { fileStatus: FileSelectionStatus }) =>
        selectModeItemToolsBgStyles[props.fileStatus]};
`

const LeftTopIndicator = styled.div`
    position: relative;
    top: 16px;
    left: 16px;
    cursor: pointer;
`

const ExpandPreview = styled.div`
    position: absolute;
    top: 0px;
    right: 32px;
    cursor: pointer;
`

const ItemSelectIndicator = styled.div`
    cursor: pointer;
    img {
        opacity: ${(props: { opacity?: number }) =>
            props.opacity ? props.opacity : 1};
    }
    ${!isMobileDevice.any()
        ? `
        &:hover {
            img {
                opacity: 1;
            }
            .item-select-indicator {
                visibility: visible;
            }
        }
    `
        : ''}
`
const CheckMark = styled.div.attrs({
    className: 'item-select-indicator',
})`
    position: absolute;
    top: 0;
    left: 0;
    visibility: ${(props: { selected: boolean }) =>
        props.selected ? 'visible' : 'hidden'};
`

const BottomArea = styled.div`
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 16px;
    box-sizing: border-box;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    ${(props: { selected?: boolean; scaledAreaHight?: number }) =>
        props.selected
            ? `transform: translateY(-${props.scaledAreaHight?.toString()}px) scale3d(0.9, 1, 1); opacity: 0.6;`
            : ''};
`

const DateTimeTooltip = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: ${colors.captureGrey700};
    border-radius: 4px;
    padding: 4px 8px;
    font-size: ${fontSize.small_14};
    color: white;
    z-index: ${zIndex.tooltip};
    visibility: ${(props: { show: boolean }) =>
        props.show ? 'visible' : 'hidden'};
`

// contextMenu reference component for positioning
const ContextMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.menu};
`

// TODO: make burst icon
const getGroupIndicator = (groupType: FileGroupType) =>
    groupType === 'live' ? <LivePhotoIcon color={colors.white} /> : undefined

const getFileTypeIndicator = (file: BasicViewFile) => {
    return file.duration ? (
        <VideoIndicator duration={file.duration} />
    ) : (
        file.group && getGroupIndicator(file.group.type)
    )
}

const getDateTimeTooltipText = (file: BasicViewFile) => {
    const date = new Date((file.ctime || file.mtime) * 1000)
    return `${localizedDateStringDefault(date)} | ${localizedTimeStringShort(
        date,
    )}`
}

const VideoThumbElement = styled.video.attrs({
    loop: true,
    muted: true,
    preload: 'none',
})`
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
`

interface ItemProps {
    file: BasicViewFile
    width: number
    height: number
    spaceAround: number
    selection?: {
        onToggleSelect: (file: BasicViewFile, selected: boolean) => void
    }
    shouldFocus?: boolean
    focusedItemScale: number
    id?: string
    additionalBottomAreaElements?: ReactNode
    isFirstVisibleMonth?: boolean
    isAlbum?: boolean
    isSharedAlbum?: boolean
}

interface NormalItemProps extends ItemProps {
    onItemClick: (fileID: FileID) => void
    onItemDoubleClick?: (fileID: FileID) => void
}

type StateProps = {
    videoUrl?: string
    cursor: CursorType
}

type DispatchProps = {
    setContextMenuTarget: (file: BasicViewFile) => void
}

type GroupListItemProps = NormalItemProps & StateProps & DispatchProps

type ComponentState = {
    loaded: boolean
    showDateTooltip: boolean
    contextMenuPosition: Point
    showContextMenu: boolean
}
class _GroupListItem extends Component<GroupListItemProps, ComponentState> {
    public state: ComponentState = {
        loaded: false,
        showDateTooltip: false,
        contextMenuPosition: { x: 0, y: 0 },
        showContextMenu: false,
    }

    private videoThumb = createRef<HTMLVideoElement>()
    private dateTooltip = createRef<HTMLDivElement>()
    private contextMenuWrapper = createRef<HTMLDivElement>()

    private mounted = false
    public file: BasicViewFile = this.props.file

    public componentDidMount() {
        this.mounted = true
    }
    public componentWillUnmount() {
        this.mounted = false
    }

    private handleClickSelectButton = (
        event: SyntheticEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation()
        this.props.selection?.onToggleSelect(this.props.file, true)
    }
    private handleClickItem = () => {
        this.props.onItemClick(this.props.file.fileID)
    }
    private handleDoubleClickItem = () => {
        if (this.props.onItemDoubleClick) {
            this.props.onItemDoubleClick(this.props.file.fileID)
        }
    }

    private enterSelectMode = () =>
        this.props.selection?.onToggleSelect(this.props.file, true)

    private showItemDateTimer: number | undefined
    private updateTooltipPosition = (e: MouseEvent) => {
        // manipulate DOM element directly to avoid jumpy result
        if (this.dateTooltip.current) {
            const tooltipRect = this.dateTooltip.current.getBoundingClientRect()
            this.dateTooltip.current.style.transform = `translate(${
                e.clientX - tooltipRect.width / 2
            }px, ${e.clientY + 24}px)`
        }
    }

    private playPromise: Promise<void> = Promise.resolve()

    private setVideoPreviewSource = (
        size: number,
        videoElement: HTMLVideoElement,
        url: string,
    ): void => {
        // Fetching a range of mb that has the headers, blob it and using it as a source for the preview.
        BrowserFetchObject.get(url, {
            Range: `bytes=0-${size}`,
        })
            .rawResponse()
            .then((response) => response.blob())
            .then((data) => (videoElement.src = URL.createObjectURL(data)))
            .catch(() => {
                // There is a CORS error on Firefox only. When that happen just fetch the entire video until the error is fixed
                videoElement.src = url
            })
    }

    private playVideoPreviewTimer: number | undefined
    private playVideoPreview = (): void => {
        if (this.videoThumb.current && this.props.videoUrl) {
            this.setVideoPreviewSource(
                5000000,
                this.videoThumb.current,
                this.props.videoUrl,
            )

            this.videoThumb?.current.play().catch(() => {
                if (this.videoThumb.current) {
                    this.videoThumb.current.style.opacity = '0'
                }
            })
            this.videoThumb.current.style.opacity = '1'
        }
    }

    private onMouseEnter = (e: MouseEvent) => {
        this.updateTooltipPosition(e)
        this.showItemDateTimer = window.setTimeout(() => {
            if (this.mounted) {
                this.setState({ showDateTooltip: true })
            }
        }, 1000)

        this.playVideoPreviewTimer = window.setTimeout(() => {
            // Setting this inside the timer to avoid fetching all the videos on mouse runover
            this.playVideoPreview()
        }, 300)

        this.onMouseMove(e)
    }
    private onMouseMove = (e: MouseEvent) => {
        this.updateTooltipPosition(e)
    }
    private onMouseLeave = async () => {
        await this.playPromise
        if (this.videoThumb.current) {
            this.videoThumb.current.pause()
            this.videoThumb.current.style.opacity = '0'
        }
        clearTimeout(this.showItemDateTimer)
        if (this.mounted) {
            this.setState({ showDateTooltip: false })
        }
        clearTimeout(this.playVideoPreviewTimer)
    }

    private getBottomInfoArea = () => {
        if (
            this.props.file.duration ||
            this.props.file.group ||
            this.props.additionalBottomAreaElements
        ) {
            return (
                <BottomArea className="BottomArea">
                    {getFileTypeIndicator(this.props.file)}
                    {this.props.additionalBottomAreaElements}
                </BottomArea>
            )
        }
    }

    private onContextMenu = (e: MouseEvent) => {
        e.preventDefault()
        this.props.setContextMenuTarget(this.props.file)
        if (this.contextMenuWrapper.current) {
            this.setState({
                contextMenuPosition: { x: e.clientX, y: e.clientY },
                showContextMenu: true,
            })
        }
    }

    // Passed to the Context Menu component as props
    private onClickOutside = () => {
        this.setState({ showContextMenu: false })
    }

    // Using as a function to not render it for every image in the first place
    private showContextMenu = () => {
        if (this.state.showContextMenu) {
            return (
                <FileContextMenu
                    onClickOutside={this.onClickOutside}
                    isAlbum={this.props.isAlbum}
                    position={this.state.contextMenuPosition}
                    showContextMenu={this.state.showContextMenu}
                    isSharedAlbum={this.props.isSharedAlbum}
                />
            )
        }
    }

    private ItemSelectionIndicator = () => {
        const icon: Icon = () => <img alt="" src={CheckFilledIconGrey} />
        return (
            <ItemSelectIndicator opacity={0.5}>
                <CheckMark selected={false} data-is-selected={false}>
                    <IconButton
                        onClick={this.handleClickSelectButton}
                        icon={icon}
                    />
                </CheckMark>
            </ItemSelectIndicator>
        )
    }

    private addFallbackImage = (event: SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.onerror = null // prevents looping
        event.currentTarget.src = ThumbnailPlaceholder
        event.currentTarget.style.opacity = '1'
    }

    public shouldComponentUpdate(
        nextProps: GroupListItemProps,
        nextState: ComponentState,
    ) {
        if (
            nextState.showDateTooltip ||
            nextState.showDateTooltip !== this.state.showDateTooltip ||
            nextState.showContextMenu ||
            nextState.showContextMenu !== this.state.showContextMenu ||
            nextProps.file.thumbURLSmall !== this.props.file.thumbURLSmall || // changes for fresh uploaded files
            nextProps.width !== this.props.width // changes with screen layout (e.g. portrait/landscape)
        ) {
            return true
        }
        return false
    }

    public render() {
        const leftTopIndicator = this.props.selection !== undefined && (
            <LeftTopIndicator data-cy="timeline__groupItemSelector">
                {this.ItemSelectionIndicator()}
            </LeftTopIndicator>
        )
        return (
            <LongPressProvider
                onLongPress={this.enterSelectMode}
                data-cy="wrapper">
                {(_isLongPressing) => (
                    <NormalItemImageWrapper
                        className="ImageWrapper"
                        style={{
                            height: this.props.height,
                            width: this.props.width,
                        }}>
                        <NormalItemImage
                            data-cy="timeline__groupListItem"
                            id={this.props.id}
                            elementWidth={
                                this.props.width *
                                (this.props.shouldFocus
                                    ? this.props.focusedItemScale
                                    : 1)
                            }
                            elementHeight={
                                this.props.height *
                                (this.props.shouldFocus
                                    ? this.props.focusedItemScale
                                    : 1)
                            }
                            elementSpaceAround={this.props.spaceAround}
                            src={this.props.file.thumbURLSmall}
                            onError={this.addFallbackImage}
                            loading={
                                this.props.isFirstVisibleMonth
                                    ? 'eager'
                                    : 'lazy'
                            }
                            decoding={'async'}
                            isFocused={this.props.shouldFocus}
                            scaleItem={this.props.focusedItemScale > 1}
                            cursorType={this.props.cursor}
                        />
                        {this.props.file.duration && (
                            <VideoThumbElement
                                ref={this.videoThumb}
                                poster={this.props.file.thumbURLSmall}
                            />
                        )}
                        <NormalItemTools
                            className="NormalItemTools"
                            data-cy="timeline__groupListItemTools"
                            data-cy-filepath={this.props.file.path}
                            onClick={this.handleClickItem}
                            onKeyUp={this.handleDoubleClickItem}
                            onDoubleClick={this.handleDoubleClickItem}
                            onMouseEnter={this.onMouseEnter}
                            onMouseMove={this.onMouseMove}
                            onMouseLeave={this.onMouseLeave}
                            onContextMenu={this.onContextMenu}
                            role="button"
                            tabIndex={0}>
                            {leftTopIndicator}
                        </NormalItemTools>
                        {this.getBottomInfoArea()}
                        <DateTimeTooltip
                            className="DateTimeTooltip"
                            ref={this.dateTooltip}
                            show={this.state.showDateTooltip}>
                            {getDateTimeTooltipText(this.props.file)}
                        </DateTimeTooltip>
                        <ContextMenuWrapper
                            className="ContextMenuWrapper"
                            ref={this.contextMenuWrapper}>
                            {this.showContextMenu()}
                        </ContextMenuWrapper>
                    </NormalItemImageWrapper>
                )}
            </LongPressProvider>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof getVideoURL> &
        StateOfSelector<typeof getCursorState>,
    ownProps: NormalItemProps,
): StateProps => ({
    videoUrl: getVideoURL(
        state,
        ownProps.file.jobID,
        ownProps.file.fileID,
        'v-low',
    ),
    cursor: getCursorState(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    setContextMenuTarget: (file: BasicViewFile) =>
        dispatch(setContextMenuTarget(file)),
})

export const GroupListItem = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_GroupListItem)

interface ItemPropsWithSelection extends ItemProps {
    selection: {
        fileSelectionStatus: FileSelectionStatus
        onToggleSelect: (file: BasicViewFile) => void
    }
    selectHint?: ReactNode
}
type SelectComponentState = {
    showPreview: boolean
}
export class SelectModeGroupListItem extends Component<
    ItemPropsWithSelection,
    SelectComponentState
> {
    public state: SelectComponentState = {
        showPreview: false,
    }

    private toggleSelect = () => {
        this.props.selection.onToggleSelect(this.props.file)
    }

    private handleClickSelectButton = (
        event: SyntheticEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation()
        this.toggleSelect()
    }

    private handleClickPreviewButton = (
        event: SyntheticEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation() // don't trigger selection
        this.setState({ showPreview: true })
    }

    private closePreview = (event: SyntheticEvent<HTMLDivElement>) => {
        event.stopPropagation()
        this.setState({ showPreview: false })
    }

    private getBottomArea = (selected?: boolean) => {
        if (
            this.props.file.duration ||
            this.props.file.group ||
            this.props.additionalBottomAreaElements
        ) {
            // calculate area hight for selected images (varies with image width)
            const scaledHight = (0.1 * this.props.height) / 2
            return (
                <BottomArea
                    className="BottomArea"
                    selected={selected}
                    scaledAreaHight={scaledHight}>
                    {getFileTypeIndicator(this.props.file)}
                    {this.props.additionalBottomAreaElements}
                </BottomArea>
            )
        }
    }

    public shouldComponentUpdate(
        nextProps: ItemPropsWithSelection,
        nextState: SelectComponentState,
    ) {
        return (
            nextState.showPreview !== this.state.showPreview ||
            nextProps.file.fileID !== this.props.file.fileID ||
            nextProps.selectHint !== this.props.selectHint ||
            nextProps.selection.fileSelectionStatus !==
                this.props.selection.fileSelectionStatus ||
            nextProps.file.thumbURLSmall !== this.props.file.thumbURLSmall || // changes for fresh uploaded files
            nextProps.width !== this.props.width // changes with screen layout (e.g. portrait/landscape)
        )
    }

    private ItemSelectionIndicator = (isSelected: boolean) => {
        const checkIcon: Icon = () => <img alt="" src={CheckFilledIconBlue} />
        const previewIcon: Icon = () => {
            if (!isMobileDevice.any()) {
                return <img alt="" src={ExpandPreviewIcon} />
            }
            return null
        }

        return (
            <ItemSelectIndicator>
                <img alt="" src={CircleIcon} />
                <CheckMark selected={isSelected} data-is-selected={isSelected}>
                    <IconButton
                        onClick={this.handleClickSelectButton}
                        icon={checkIcon}
                    />
                </CheckMark>
                <ExpandPreview>
                    <IconButton
                        onClick={this.handleClickPreviewButton}
                        icon={previewIcon}
                        color={colors.white}
                    />
                </ExpandPreview>
            </ItemSelectIndicator>
        )
    }

    private getContent = (isLongPressing: boolean) => {
        const fileStatus = this.props.selection.fileSelectionStatus
        const selected =
            fileStatus === 'Selected' || fileStatus === 'ToBeDeselected'
        return (
            <SelectModeItemWrapper
                className="SelectModeItemWrapper"
                style={{ height: this.props.height, width: this.props.width }}>
                <SelectModeItemImage
                    selected={selected}
                    src={this.props.file.thumbURLSmall}
                    elementWidth={this.props.width}
                    elementHeight={this.props.height}
                    elementSpaceAround={this.props.spaceAround}
                    loading={'lazy'}
                    decoding={'async'}
                />
                {this.getBottomArea(selected)}
                <SelectModeItemTools
                    className="SelectModeItemTools"
                    onClick={this.toggleSelect}
                    onKeyUp={this.toggleSelect}
                    role="button"
                    tabIndex={0}
                    fileStatus={fileStatus}
                    data-cy="timeline__selectModeGroupListItem">
                    <LeftTopIndicator>
                        {this.ItemSelectionIndicator(
                            fileStatus !== 'NotSelected',
                        )}
                        {this.props.selectHint}
                    </LeftTopIndicator>
                </SelectModeItemTools>
                {(isLongPressing || this.state.showPreview) && (
                    <FullscreenPhotoOverlay
                        onOverlayClick={this.closePreview}
                        currentFile={this.props.file}
                    />
                )}
            </SelectModeItemWrapper>
        )
    }

    public render() {
        return <LongPressProvider>{this.getContent}</LongPressProvider>
    }
}
