import { createSelector } from 'reselect'
import type { UserAccountAttributeKey } from '~/@types/backend-types'
import { TestFlagService } from '~/API/services/TestFlagService'
import { inArray, withoutTheUndefined } from '~/utilities/arrayUtils'
import type { StateWithJobInfo } from '../jobInfo/reducer'
import {
    getCreatorOfJob,
    getJobInformation,
    getProvidedPassword,
} from '../jobInfo/selectors'
import type { StateWithUploader } from '../uploader/reducer'
import { getTotalPendingQuotaFileSize } from '../uploader/selectors'
import type { User } from '../users/reducer'
import {
    accountAttributeAdapter,
    type StateWithAccountAttributes,
} from './accountAttributesSlice'
import type { MissingNameModalContext, RequireUserInfoReason } from './actions'
import { LoginProgressStep, type StateWithCurrentUser } from './reducer'

export const getUserLoginStatus = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus

export const isLoggedIn = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus === LoginProgressStep.AUTHENTICATED
export const isLoggingIn = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus === LoginProgressStep.FETCHING

export const getUsedStorage = (state: StateWithCurrentUser) => {
    if (state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.used_space
    }
    return 0
}
export const getMaxStorage = (state: StateWithCurrentUser) => {
    if (state.currentUser.accountInfo) {
        // For users with unlimited storage, the available storage is set to 1PB by the backend
        const UNLIMITED_STORAGE_QUOTA_GB = 1000000
        const hasUnlimited =
            state.currentUser.accountInfo.max_space >=
            Math.pow(1024, 3) * UNLIMITED_STORAGE_QUOTA_GB
        return hasUnlimited ? Infinity : state.currentUser.accountInfo.max_space
    }
    return 0
}

export const getUserHasGrants = (state: StateWithCurrentUser) =>
    Boolean(state.currentUser.accountInfo?.max_space)

export const getUnusedStorage = (state: StateWithCurrentUser) =>
    getMaxStorage(state) - getUsedStorage(state)
export const getAvailableStorage = (
    state: StateWithCurrentUser & StateWithUploader,
) => getUnusedStorage(state) - getTotalPendingQuotaFileSize(state)
export const isOutOfStorage = (state: StateWithCurrentUser) => {
    return (
        state.currentUser.accountInfo !== undefined &&
        getMaxStorage(state) <= getUsedStorage(state)
    )
}
export const getUsedStorageRatio = (state: StateWithCurrentUser) =>
    getUsedStorage(state) / getMaxStorage(state)
export const isTNNCustomer = (
    state: StateWithCurrentUser,
): boolean | undefined => {
    const maxStorage = getMaxStorage(state)
    if (maxStorage > 0) {
        return maxStorage === Infinity
    }
}
export const getConnectedDevices = (state: StateWithCurrentUser) =>
    state.currentUser.connectedDevices

export const getCurrentUserUUID = (
    state: StateWithCurrentUser,
): UserID | undefined => {
    if (state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.uuid
    }
}
export const getEmailAdressOfLoggedInUser = (
    state: StateWithCurrentUser,
): string | undefined => {
    if (state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.logged_in_as
    }
}

export const getNameOfLoggedInUser = (
    state: StateWithCurrentUser,
): string | undefined => {
    return state.currentUser.accountInfo?.name
}

export const mustProvideUserInfo = (state: StateWithCurrentUser): boolean => {
    return state.currentUser.userInfoRequired.isRequired && !isLoggedIn(state)
}

export const getProvideUserInfoReason = (
    state: StateWithCurrentUser,
): RequireUserInfoReason | undefined => {
    return state.currentUser.userInfoRequired.reason
}

export const getTOSVerificationInfo = (state: StateWithCurrentUser) => {
    if (state.currentUser.accountInfo) {
        return state.currentUser.accountInfo.tos_to_approve
    }
}

export const isJobCreatedByCurrentUser = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): boolean | undefined => {
    const info = getJobInformation(state, job)
    if (info && info.type === 'timeline') {
        return true
    }
    const creator = getCreatorOfJob(state, job)
    if (creator !== undefined) {
        return creator === getCurrentUserUUID(state)
    }
}

export const getAuthTokenForJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): string => {
    // If the job required password and the user have provided one: Use that as auth token
    const providedPassword = getProvidedPassword(state, job)
    if (providedPassword !== undefined) {
        return providedPassword
    }

    // Authenticate as logged in user unless we know the job is created by another user
    if (
        isJobCreatedByCurrentUser(state, job) !== false &&
        state.currentUser.accountInfo
    ) {
        return state.currentUser.accountInfo.auth_token
    }

    return ''
}

export const getCurrentAuthToken = (
    state: StateWithCurrentUser,
): string | undefined => {
    return state.currentUser.accountInfo
        ? state.currentUser.accountInfo.auth_token
        : undefined
}

// ForeignAuth is used when the current user is not the owner of the job
// and is required to perform write-operations on the job
export const getForeignAuthForJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): string | undefined => {
    if (isJobCreatedByCurrentUser(state, job) === false) {
        if (state.currentUser.accountInfo) {
            return state.currentUser.accountInfo.auth_token
        }
    }
}

export const getSubscribedJobs = (state: StateWithCurrentUser): JobID[] =>
    state.currentUser.subscribedJobs

export const isAlbumListReady = (state: StateWithCurrentUser): boolean =>
    state.currentUser.haveFetchedAlbumList

export const isCurrentUserSubscribedToJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): boolean => {
    const isOwnJob = isJobCreatedByCurrentUser(state, job)
    const subscribedJobs = getSubscribedJobs(state)

    return isOwnJob || inArray(subscribedJobs, job)
}

export type CurrentUserInfo = User & { isUploadingProfilePhoto: boolean }
export const getCurrentUserInfo = (
    state: StateWithCurrentUser,
): CurrentUserInfo | undefined => {
    const accountInfo = state.currentUser.accountInfo
    if (accountInfo) {
        return {
            userID: accountInfo.uuid,
            name: accountInfo.name,
            profilePicture: accountInfo.profile_picture,
            isUploadingProfilePhoto:
                accountInfo.isUploadingProfilePhoto === true,
        }
    }
}

export type FileTypeCount = {
    picture_count: number
    video_count: number
    document_count?: number
}
export const getFileCount = (
    state: StateWithCurrentUser,
): FileTypeCount | undefined => {
    const accountInfo = state.currentUser.accountInfo
    if (accountInfo) {
        const total_picture_count =
            accountInfo.file_type_counters.picture_count +
            accountInfo.file_type_counters.screenshot_count
        return {
            picture_count: total_picture_count,
            video_count: accountInfo.file_type_counters.video_count,
            document_count: accountInfo.file_type_counters.document_count,
        }
    }
}

export const isCurrentUserBeingShutDown = (
    state: StateWithCurrentUser,
): boolean => {
    return (
        state.currentUser.accountInfo !== undefined &&
        state.currentUser.accountInfo.isBeingShutDown === true
    )
}

export const getLastVersionSeen = (
    state: StateWithCurrentUser,
): number | undefined => {
    return state.currentUser.lastWebVersionSeen
}

export type VersionNote = {
    header: string
    notes: string[]
}

export const versionNotes: Array<VersionNote | undefined> = [
    undefined, // Removed for new users, keep a space in list to allow adding new messages for users who have seen this
]

export const currentAppVersion = versionNotes.length

export const getVersionNotes = createSelector(
    getLastVersionSeen,
    (lastVersionSeen: number | undefined): VersionNote[] => {
        if (
            lastVersionSeen === undefined ||
            lastVersionSeen === currentAppVersion
        ) {
            return []
        }
        return withoutTheUndefined(versionNotes.slice(lastVersionSeen))
    },
)

export const getMissingNameModalVisibility = (
    state: StateWithCurrentUser,
): boolean => {
    return state.currentUser.missingNameModal.isVisible
}
export const getMissingNameModalContext = (
    state: StateWithCurrentUser,
): MissingNameModalContext => {
    return state.currentUser.missingNameModal.context
}

// TODO: CAPWEB-2859 - Remove feature flag when backend is ready
export const getUserReadOnlyStatus = (state: StateWithCurrentUser) =>
    state.currentUser.accountInfo?.is_read_only_user ||
    TestFlagService.isEnabled('read-only-user')

export const getIsNewSignup = (state: StateWithCurrentUser): boolean =>
    state.currentUser.is_new_signup || TestFlagService.isEnabled('new-signup')

const accountAttributeSelectors = accountAttributeAdapter.getSelectors()
export const getAccountAttributes = (state: StateWithAccountAttributes) =>
    accountAttributeSelectors.selectAll(state.accountAttributes.attributes)
export const getAccountAttribute = (
    state: StateWithAccountAttributes,
    id: UserAccountAttributeKey,
) =>
    accountAttributeSelectors.selectById(state.accountAttributes.attributes, id)
export const getAccountAttributesStatus = (state: StateWithAccountAttributes) =>
    state.accountAttributes.status

export const getSunsetDeletionDate = (state: StateWithCurrentUser) =>
    state.currentUser.accountInfo?.sunset_delete_date
