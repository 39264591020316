import {
    CAPBAKUploadPolicy,
    type CAPBAKChangesAndAsyncUploadStatusJobsJobUuidChangesAndAsyncUploadStatusGetParams,
    type CAPBAKClientStatsClientStatsPostParams,
    type CAPBAKCommentResponse,
    type CAPBAKDataProtectionRequestResult,
    type CAPBAKDedupFileJobsJobUuidFilesDedupPostParams,
    type CAPBAKDevicesWithAuth,
    type CAPBAKGetAlbumUsersJobsJobUuidUsersGetParams,
    type CAPBAKGetJobListJobsGetParams,
    type CAPBAKGetTrashCanFilesTrashCanGetParams,
    type CAPBAKJobResponse,
    type CAPBAKJobSetPermissionsResponse,
    type CAPBAKPartialMetadataResponse,
    type CAPBAKPostApproveTosApproveTosPostParams,
    type CAPBAKPostStripePurchaseStripePurchasePostParams,
    type CAPBAKPostSyncUploadWithAsyncProcessingJobsJobUuidUploadPostParams,
    type CAPBAKPrivacyMode,
    type CAPBAKPublishShareResponse,
    type CAPBAKSetPermissionsJobsJobUuidPermissionsPostParams,
    type CAPBAKSetReactionResponse,
    type CAPBAKStripeDeleteCCResponse,
    type CAPBAKSupportedExtensionsResponse,
    type CAPBAKSyncUploadExistsResponse,
    type CAPBAKTimelineMonths,
    type CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetData,
    type CAPBAKUploadResponse,
    type CAPBAKUsageAndQuotaResponse,
    type CAPBAKUsersResponse,
} from '@capture/client-api/src/schemas/data-contracts'
import type {
    AccountInfoResponse,
    ChangesAndAsyncUploadStatus,
    ClientStats,
    CreateJobProperties,
    DataProtectionConsentData,
    DataProtectionConsentsResponse,
    DeletedFile,
    DeletedFileResponse,
    ExtraJobQueryParamsOf,
    ExtraQueryParamsOf,
    JobChange,
    JobFile,
    JobFilesRequestOptions,
    JobInfoResponse,
    PublishJobOptions,
    StoryJobInfoResponse,
    StripePaymentInfo,
    StripeProductsResponse,
    StripePurchaseResponse,
    UserAccountAttributeKey,
    UpsertUserAccountAttribute,
    UserAccountAttributesResponse,
    UserStatistics,
    PostAccountAttributeAccountAttributePostParams,
    UserStatisticsEventsData,
    StripeProductsMode,
    JobListResponse,
    GrantInfoResponse,
} from '~/@types/backend-types'
import { PRODUCT_NAME, getCurrentLocale } from '~/config/constants'
import { Reactions } from '~/state/reaction/actions'
import { canReadFileContent } from '~/state/uploader/uploadFileURL'
import { UploadError, UploadFailedReason } from '~/state/uploader/uploadQueue'
import type { CommentService } from '../capabilities'
import type { ServiceDict } from '../externals'
import type { FetchObject } from '../FetchObject'
import { HostUrl, downloadThroughAnchor, sendPOSTRedirect } from '../toolbox'

export class AppService implements CommentService {
    private hostUrl: HostUrl
    private downloadHost: HostUrl

    constructor(
        private fetchObject: FetchObject,
        hosts: ServiceDict,
        authToken: string,
        foreignAuth?: string,
    ) {
        const commonQueryParams: DictionaryOf<string> = {
            // TODO: Remove optional `import.meta.env`,
            // when we can load vite env variables through `vite-register`.
            // Currently `vite-register` only loads `.env` from root, not `tests/.env`
            key: (import.meta.env?.VITE_API_KEY || 'testing') as string,
            auth: authToken,
            client_v: (import.meta.env?.VITE_VERSION || 'testing') as string,
        }
        if (foreignAuth) {
            commonQueryParams['foreign-auth'] = foreignAuth
        }

        this.hostUrl = new HostUrl(hosts.appHost, commonQueryParams)
        this.downloadHost = new HostUrl(hosts.downloadHost, commonQueryParams)
    }

    public getAccountInfo(): Promise<AccountInfoResponse> {
        const params = {
            wantName: '0',
            app_lang: getCurrentLocale().substring(0, 2),
        }
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/account_info', params))
            .asJson<AccountInfoResponse>()
    }

    public setProfileName(name: string): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/name`, { name }))
            .rawResponse()
    }
    public async setProfilePictureFromBlob(blob: Blob): Promise<Response> {
        const url = this.hostUrl.getPath(`/st/4/profile/picture`, {
            path: 'profile.jpg',
        })
        const payload = new FormData()
        payload.append('file', blob)

        const request = new XMLHttpRequest()
        await new Promise((success, error) => {
            request.addEventListener('load', success)
            request.addEventListener('error', error)
            request.addEventListener('abort', error)

            request.open('POST', url)
            request.send(payload)
        })
        if (request.status !== 201) {
            throw new Error(request.responseText)
        }
        return request.response
    }

    public updatePushToken(push_token: string): Promise<Response> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/update_push_token`, { push_token }),
            )
            .rawResponse()
    }

    public getUserOption(optionKey: string) {
        return this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/options/${optionKey}`))
            .asText()
    }

    public setUserOption(optionKey: string, value: string) {
        return this.fetchObject
            .put(this.hostUrl.getPath(`/st/4/options/${optionKey}`), value)
            .rawResponse()
    }
    public deleteUserOption(optionKey: string) {
        return this.fetchObject
            .delete(this.hostUrl.getPath(`/st/4/options/${optionKey}`))
            .rawResponse()
    }

    public getConnectedDevices(): Promise<CAPBAKDevicesWithAuth> {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/devices'))
            .asJson<CAPBAKDevicesWithAuth>()
    }
    public deleteConnectedDevice(deviceID: string): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/devices/${deviceID}/delete`))
            .rawResponse()
    }

    public logout(): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath('/st/4/logout'))
            .rawResponse()
    }
    public getJobList(): Promise<JobListResponse> {
        const params: ExtraQueryParamsOf<CAPBAKGetJobListJobsGetParams> = {
            stories: true,
            include_details: true,
        }
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/jobs', params))
            .asJson<JobListResponse>()
    }

    public async getDefaultJob(): Promise<CAPBAKJobResponse> {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/jobs/default'))
            .asJson<CAPBAKJobResponse>()
    }

    public getTimelineMonths(jobID: JobID): Promise<CAPBAKTimelineMonths> {
        return this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/timeline/months`))
            .asJson<CAPBAKTimelineMonths>()
    }

    public getJobInfo(jobID: JobID): Promise<JobInfoResponse> {
        const params = {
            include_details: '1',
        }
        return this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/info`, params))
            .asJson<JobInfoResponse>()
    }

    public getJobChanges(jobID: JobID, since = 0): Promise<JobChange[]> {
        return this.fetchObject
            .get(
                this.hostUrl.getPath(`/st/4/jobs/${jobID}/changes`, {
                    serial: 1,
                    since,
                }),
            )
            .asJson<JobChange[]>()
    }
    public async getLastJobChangeID(jobID: JobID): Promise<number> {
        const resp = await this.fetchObject
            .get(
                this.hostUrl.getPath(`/st/4/jobs/${jobID}/changes`, {
                    since: Number.MAX_SAFE_INTEGER,
                }),
            )
            .rawResponse()
        return parseInt(resp.headers.get('x-last-event-serial') || '-1', 10)
    }

    public getJobContributors(
        jobID: JobID,
        includeProfilePicture = false,
    ): Promise<CAPBAKUsersResponse> {
        const options: ExtraJobQueryParamsOf<CAPBAKGetAlbumUsersJobsJobUuidUsersGetParams> =
            {
                include_profile_url: includeProfilePicture,
            }
        return this.fetchObject
            .get(
                this.hostUrl.getPath('/st/4/jobs/' + jobID + '/users', options),
            )
            .asJson<CAPBAKUsersResponse>()
    }

    public async createJob(
        opts: CreateJobProperties,
    ): Promise<StoryJobInfoResponse> {
        return this.fetchObject
            .post(this.hostUrl.getPath('/st/4/jobs', opts))
            .asJson<StoryJobInfoResponse>()
    }

    public publishJob(
        jobID: JobID,
        opts: PublishJobOptions,
    ): Promise<CAPBAKPublishShareResponse> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/publish`, opts))
            .asJson<CAPBAKPublishShareResponse>()
    }

    public deleteJob(jobID: JobID) {
        return this.fetchObject
            .delete(this.hostUrl.getPath('/st/4/jobs/' + jobID))
            .rawResponse()
    }

    public async getFiles(
        jobID: string,
        options: JobFilesRequestOptions,
    ): Promise<{
        lastEventSerial: number
        files: JobFile[]
    }> {
        const resp = await this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/files`, options))
            .rawResponse()
        const lastEventSerial = parseInt(
            resp.headers.get('x-last-event-serial') || '-1',
            10,
        )
        const files = (await resp.json()) as JobFile[]
        return { lastEventSerial, files }
    }

    public async getDeletedFiles(
        options: ExtraQueryParamsOf<CAPBAKGetTrashCanFilesTrashCanGetParams>,
    ): Promise<DeletedFileResponse> {
        const resp = await this.fetchObject
            .get(this.hostUrl.getPath('/st/4/trash_can', options))
            .rawResponse()
        const deletedFiles = (await resp.json()) as DeletedFile[]
        const limit = parseInt(resp.headers.get('x-limit') || '1000', 10)
        const resultCount = parseInt(
            resp.headers.get('x-result-count') ||
                deletedFiles.length.toString(),
            10,
        )
        return { deletedFiles, limit, resultCount }
    }

    public async getDeletedAlbums(): Promise<CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetData> {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/trash_can_albums'))
            .asJson<CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetData>()
    }

    public getFileMetadata(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKPartialMetadataResponse> {
        return this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/jobs/${jobID}/metadata/${fileID}`))
            .asJson<CAPBAKPartialMetadataResponse>()
    }

    private static getUUIDFromTextResponse(str: string): string {
        // Response-format of a successful upload/dedup: "OK [FileID] [UsedSpace] [timestamp]"
        const parts = str.split(' ')
        if (parts[0] !== 'OK') {
            throw new UploadError(
                UploadFailedReason.FileError,
                'File rejected by server',
            )
        }
        return parts[1]
    }

    public async dedupFile(
        targetJob: JobID,
        opts: ExtraJobQueryParamsOf<CAPBAKDedupFileJobsJobUuidFilesDedupPostParams>,
    ): Promise<string> {
        const resp = await this.fetchObject
            .post(
                this.hostUrl.getPath(
                    '/st/4/jobs/' + targetJob + '/files_dedup',
                    opts,
                ),
            )
            .rawResponse()
        const contentType = resp.headers.get('content-type')

        if (contentType && resp.status === 201) {
            if (contentType.match('application/json') !== null) {
                const value: CAPBAKUploadResponse = await resp.json()
                return value.uuid
            } else if (contentType.match('text/html') !== null) {
                return AppService.getUUIDFromTextResponse(await resp.text())
            } else if (contentType.match('text/plain') !== null) {
                return AppService.getUUIDFromTextResponse(await resp.text())
            }
        }

        throw new UploadError(
            UploadFailedReason.FileError,
            'File rejected by server',
        )
    }

    public async copyFilesToDefaultJob(
        sourceJobID: JobID,
        fileIDs: FileID[],
    ): Promise<void> {
        await this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/jobs/${sourceJobID}/keep-files`),
                fileIDs.join('\n'),
            )
            .rawResponse()
    }

    public async copyJobToDefaultJob(sourceJobID: JobID): Promise<void> {
        await this.fetchObject
            .post(
                this.hostUrl.getPath(
                    `/st/4/jobs/${sourceJobID}/keep-all-files`,
                ),
            )
            .rawResponse()
    }

    public async uploadFile(
        jobID: string,
        path: string,
        file: File | Blob,
        mtime?: number,
        opt_request?: XMLHttpRequest,
    ): Promise<CAPBAKSyncUploadExistsResponse> {
        const params: ExtraJobQueryParamsOf<CAPBAKPostSyncUploadWithAsyncProcessingJobsJobUuidUploadPostParams> =
            {
                path,
                mtime,
                policy: CAPBAKUploadPolicy.NoDuplicates,
            }
        const url = this.hostUrl.getPath(`/st/4/jobs/${jobID}/upload`, params)
        const payload = new FormData()
        payload.append('file', file)

        const request = opt_request || new XMLHttpRequest()
        try {
            await new Promise((success, error) => {
                request.addEventListener('load', success)
                request.addEventListener('error', error)
                request.addEventListener('abort', error)

                request.open('POST', url)
                request.send(payload)
            })
        } catch (e) {
            // CAPWEB-2088: Upload can fail because local files have been removed
            if (!(await canReadFileContent(file))) {
                throw new UploadError(
                    UploadFailedReason.LocalFileUnavailable,
                    'File no longer exists',
                )
            }

            throw new UploadError(
                UploadFailedReason.NetworkError,
                'Network error',
            )
        }

        if (
            request.status ===
            413 /* Request Entity Too Large => Out of storage */
        ) {
            throw new UploadError(
                UploadFailedReason.OutOfStorage,
                'Out of storage',
            )
        }

        return JSON.parse(request.responseText)
    }

    // null response value when deleting album file
    public deleteFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKUsageAndQuotaResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/delete`,
                ),
            )
            .asJson<CAPBAKUsageAndQuotaResponse>()
    }

    public async emptyTrashCan(fileid: FileID): Promise<void> {
        await this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/empty_trash_can`, { fileid }))
            .rawResponse()
    }

    // null response value when restoring album file
    public restoreFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<{ used_space: number } | null> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/jobs/${jobID}/rollback`, {
                    id: fileID,
                }),
            )
            .asJson()
    }

    public addComment(
        jobID: JobID,
        fileID: string,
        comment: string,
    ): Promise<CAPBAKCommentResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/comments`,
                ),
                comment,
            )
            .asJson<CAPBAKCommentResponse>()
    }

    public deleteComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
    ): Promise<CAPBAKCommentResponse> {
        return this.fetchObject
            .delete(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/comments/${commentID}`,
                ),
            )
            .asJson<CAPBAKCommentResponse>()
    }

    public editComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
        commentText: string,
    ): Promise<CAPBAKCommentResponse> {
        return this.fetchObject
            .put(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/comments/${commentID}`,
                ),
                commentText,
            )
            .asJson<CAPBAKCommentResponse>()
    }

    public subscribeToJob(jobID: JobID): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/subscribe`))
            .rawResponse()
    }

    public unsubscribeFromJob(jobID: JobID): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/unsubscribe`))
            .rawResponse()
    }

    public static getDownloadOptions(
        type: 'download' | 'export' | 'takeout',
        zipFileName: string,
        convertHEIC: boolean = false,
    ) {
        switch (type) {
            case 'download':
                return {
                    flattened: '1',
                    heic_to_jpeg: convertHEIC ? '1' : '0',
                    master_only: '1',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'export':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'takeout':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '1',
                    zip_filename: zipFileName,
                }
        }
    }

    /**
     * Downloads files from a specified job based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {FileID[]} fileIDs - An array of file IDs to download.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the files have been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     a. single file:
     *         uses `downloadThroughAnchor` with `GET` request to download the file through `a` tag.
     *         download single file thumbnail, for HEIC file it will convert to JPEG.
     *     b. multiple files:
     *         uses `sendPOSTRedirect` with `POST` request with `fileIDs` as body to download the files through `form` submit.
     *         download all files thumbnail as archive, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format as archive.
     * 2. downloadType === 'takeout':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format with its subrevisions as archive.
     *
     */
    public async downloadFilesFromJob(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        fileIDs: FileID[],
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        if (fileIDs.length === 1) {
            downloadThroughAnchor(
                this.getFilePreviewURL(
                    jobID,
                    fileIDs[0],
                    downloadType === 'download' && hasHEIC,
                ),
            )
            return
        }

        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
            fileIDs,
        )
    }
    /**
     * Downloads all files from a specified job as an archive based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the archive has been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     uses `downloadThroughAnchor` with `GET` request to download all files from job through `a` tag.
     *     download all files thumbnail from target job, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     download all files from target job in original format as archive.
     * 2. downloadType === 'takeout':
     *     download all files from target job in original format with its subrevisions as archive.
     *
     */
    public async downloadJobAsArchive(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        downloadThroughAnchor(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
        )
    }

    public getFilePreviewURL(
        jobID: JobID,
        fileID: FileID,
        thumbnail = false,
    ): URLstring {
        return this.hostUrl.getPath(
            `/st/4/jobs/${jobID}/files_by_id/${fileID}`,
            thumbnail ? { to_jpeg: '1' } : undefined,
        )
    }

    public downloadFilesFromTrashAsArchive(
        jobID: JobID,
        files: FileID[],
        zipFileName: string,
    ): Promise<void> {
        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_from_trash_as_archive`,
                {
                    flattened: 1,
                    zip_filename: zipFileName,
                },
            ),
            files,
        )
    }

    public setJobName(jobID: JobID, name: string): Promise<Response> {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/jobs/${jobID}/name`, { name }))
            .rawResponse()
    }

    public setCoverPhoto(jobID: JobID, fileID: FileID): Promise<Response> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/jobs/${jobID}/cover`, {
                    id: fileID,
                }),
            )
            .rawResponse()
    }

    public setPermissionsforJob(
        jobID: JobID,
        permissions: ExtraJobQueryParamsOf<CAPBAKSetPermissionsJobsJobUuidPermissionsPostParams>,
    ): Promise<CAPBAKJobSetPermissionsResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/permissions`,
                    permissions,
                ),
            )
            .asJson<CAPBAKJobSetPermissionsResponse>()
    }

    public setPrivacyModeForJob(
        jobID: JobID,
        mode: CAPBAKPrivacyMode,
    ): Promise<CAPBAKJobSetPermissionsResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/jobs/${jobID}/privacy_mode`, {
                    mode,
                }),
            )
            .asJson<CAPBAKJobSetPermissionsResponse>()
    }

    public loveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKSetReactionResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/reaction`,
                ),
                Reactions.Love,
            )
            .asJson<CAPBAKSetReactionResponse>()
    }

    public unLoveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKSetReactionResponse> {
        return this.fetchObject
            .delete(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/files_by_id/${fileID}/reaction`,
                ),
            )
            .asJson<CAPBAKSetReactionResponse>()
    }

    public getStripeProducts(
        mode: StripeProductsMode /* 'test'|'production'*/,
    ): Promise<StripeProductsResponse> {
        return this.fetchObject
            .get(
                this.hostUrl.getPath('/st/4/stripe_products', { [mode]: true }),
            )
            .asJson()
    }
    public stripePurchase({
        plan,
        token,
        card,
    }: ExtraQueryParamsOf<CAPBAKPostStripePurchaseStripePurchasePostParams>): Promise<StripePurchaseResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/stripe_purchase', {
                    plan,
                    token,
                    card,
                }),
            )
            .asJson<StripePurchaseResponse>()
    }
    public validateStripePurchase(
        subscription_id: string,
        payment_intent_id: string,
    ) {
        return this.fetchObject
            .get(
                this.hostUrl.getPath(
                    `/st/4/stripe_purchase/${subscription_id}/validate_purchase`,
                    { payment_intent_id },
                ),
            )
            .rawResponse()
    }

    public postStripePaymentMethod(token: string, card: string) {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/stripe_payment_method', {
                    token,
                    card,
                }),
            )
            .rawResponse()
    }

    public getStripePaymentMethodInfo() {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/stripe_payment_method'))
            .asJson<StripePaymentInfo>()
    }

    public getUserGrants() {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/user_grants'))
            .asJson<GrantInfoResponse>()
    }

    public executeGrantLink(link: string) {
        return this.fetchObject.post(link).rawResponse()
    }

    public updateCurrentPlan(plan: string) {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/update_stripe_purchase', { plan }),
            )
            .rawResponse()
    }

    public deleteUserCreditCard(
        card_id: string,
    ): Promise<CAPBAKStripeDeleteCCResponse> {
        return this.fetchObject
            .delete(
                this.hostUrl.getPath('/st/4/stripe_user_credit_card', {
                    card_id,
                }),
            )
            .asJson<CAPBAKStripeDeleteCCResponse>()
    }

    public setAlbumAttribute = (
        albumID: JobID,
        attributeName: string,
        attributeValue: string,
    ) => {
        return this.fetchObject
            .put(
                this.hostUrl.getPath(
                    `/st/4/jobs/${albumID}/attribute/${attributeName}`,
                    { value: attributeValue },
                ),
            )
            .rawResponse()
    }

    public getDataProtectionConsentValues(): Promise<DataProtectionConsentsResponse> {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/data_protection/consent_values'))
            .asJson()
    }

    public updateDataProtectionConsentValues(
        values: Partial<DataProtectionConsentData>,
    ) {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/data_protection/consent_values'),
                JSON.stringify(values),
            )
            .rawResponse()
    }
    public canDownloadDataProtectionRequestedDataAccess(): Promise<CAPBAKDataProtectionRequestResult> {
        // Throws 503 if call to request_data_access will yield error due to rate-limiting
        return this.fetchObject
            .get(
                this.hostUrl.getPath(
                    '/st/4/data_protection/request_data_access_availability',
                ),
            )
            .asJson<CAPBAKDataProtectionRequestResult>()
    }
    public downloadDataProtectionRequestedDataAccess() {
        window.location.href = this.hostUrl.getPath(
            '/st/4/data_protection/request_data_access',
        )
    }
    public sendDataProtectionRequestForAccountDeletion() {
        return this.fetchObject
            .post(
                this.hostUrl.getPath(
                    '/st/4/data_protection/request_account_deletion',
                ),
            )
            .rawResponse()
    }

    public postUserStatistics(userEvents: UserStatistics[]): Promise<any> {
        const data: UserStatisticsEventsData = { events: userEvents }
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/user_statistics_events`),
                JSON.stringify(data),
            )
            .rawResponse()
    }
    public postClientStats(device_id: string, client_status: ClientStats) {
        const options: ExtraQueryParamsOf<CAPBAKClientStatsClientStatsPostParams> =
            { device_id }
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/client_stats`, options),
                JSON.stringify(client_status),
            )
            .rawResponse()
    }

    public approveTOS(tos_version: string) {
        const options: ExtraQueryParamsOf<CAPBAKPostApproveTosApproveTosPostParams> =
            { tos_version }
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/approve_tos`, options))
            .rawResponse()
    }

    public getChangesAndAsyncUploadStatus(
        jobID: JobID,
        since: number,
    ): Promise<ChangesAndAsyncUploadStatus> {
        const options: ExtraJobQueryParamsOf<CAPBAKChangesAndAsyncUploadStatusJobsJobUuidChangesAndAsyncUploadStatusGetParams> =
            { since }
        return this.fetchObject
            .get(
                this.hostUrl.getPath(
                    `/st/4/jobs/${jobID}/changes_and_async_upload_status`,
                    options,
                ),
            )
            .asJson<ChangesAndAsyncUploadStatus>()
    }

    // Test endpoint for deleting stripe data
    public deleteStripeTestData() {
        return this.fetchObject
            .post(this.hostUrl.getPath(`/st/4/test/delete_stripe_data`))
            .rawResponse()
    }

    public async getSupportedExtensions(): Promise<CAPBAKSupportedExtensionsResponse> {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/supported_extensions'))
            .asJson<CAPBAKSupportedExtensionsResponse>()
    }

    // User account atributes endpoints
    public getAccountAttributes(): Promise<UserAccountAttributesResponse> {
        return this.fetchObject
            .get(this.hostUrl.getPath(`/st/4/account_attribute`))
            .asJson()
    }

    public upsertAccountAttributes(
        body: UpsertUserAccountAttribute,
    ): Promise<UserAccountAttributesResponse> {
        const options: PostAccountAttributeAccountAttributePostParams = {
            account_attribute_key: body.account_attribute_key,
        }
        return this.fetchObject
            .post(
                this.hostUrl.getPath(`/st/4/account_attribute`, options),
                JSON.stringify(body.value),
            )
            .asJson<UserAccountAttributesResponse>()
    }

    public deleteAccountAttribute(key: UserAccountAttributeKey) {
        return this.fetchObject
            .delete(
                this.hostUrl.getPath(
                    `/st/4/account_attribute?account_attribute_key=${key}`,
                ),
            )
            .rawResponse()
    }
}
