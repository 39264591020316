/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** APIKey */
export enum CAPBAKAPIKey {
    O7AHYgm13P = 'o7aHYgm13p',
    MiHHWlmDUn = 'MiHHWlmDUn',
    Xoo2Kath5O = 'Xoo2Kath5O',
    IdX8S4SEDF = 'idX8S4sEDF',
    KVEzWDMsj = 'KVEz-WDMsj',
    OyfPnKpTpH = 'oyfPnKpTpH',
    EY9NdlOcGr = 'eY9NdlOcGr',
    Value8MC08FjI54 = '8MC08FjI54',
    BT3D2S24Io = 'BT3d2S24io',
    VwLKSgXDiE = 'vwLKSgXDiE',
    Hsn6YzECfW = 'Hsn6yzECfW',
    OZzqAS81P7 = 'OZzqAS81P7',
    CBNeQZPnHx = 'cBNeQZPnHx',
    ZJukqEyVqR = 'ZJukqEyVqR',
    MFymNt3317 = 'MFymNt3317',
    Value78F9AkpvkI = '78f9akpvkI',
}

/** APIPingResponse */
export interface CAPBAKAPIPingResponse {
    hostname: string
    result?: 'pong'
}

/** AccountAttribute */
export interface CAPBAKAccountAttribute {
    user_uuid: string
    key: string
    value: string | any[] | object | number
    update_timestamp: number
    create_timestamp: number
}

/** AccountAttributeResponse */
export interface CAPBAKAccountAttributeResponse {
    result: CAPBAKAccountAttribute[]
}

/** AccountDeletionRequestResult */
export interface CAPBAKAccountDeletionRequestResult {
    status: CAPBAKDataProtectionRequestStatus
    undelete_url?: string
}

/** AccountInfo */
export interface CAPBAKAccountInfo {
    name: string
    used_space: string
    max_space: string
    uuid: string
    kissmetrics_id: string
    logged_in_as?: string
    username: string
    files_disabled: boolean
    has_unlimited_quota: number
    external_urls_hash: string
    user_groups: CAPBAKUserGroupMembership[]
    internal_privacy_dashboard: boolean
    latest_account_attribute_update_timestamp?: number
    full_screen_card_hash: string
    user_has_orden_account: boolean
    orden_account_link_status: object
    permitted_services?: CAPBAKPermittedServices
    push_token?: string
    shutdown_info?: object
    profile_picture_url?: string
    upload_error_log?: object
    tos_to_approve?: Record<string, string>
    sunset_delete_date?: string
    number_of_subscribed_albums?: number
    file_type_counters?: CAPBAKMediaCounters
    is_read_only_user?: boolean
}

/** AgentId */
export interface CAPBAKAgentId {
    agent_id: number
}

/** AlbumMessageResponse */
export interface CAPBAKAlbumMessageResponse {
    message: string
    status: string
    uuid: string
}

/** AndroidClientRight */
export interface CAPBAKAndroidClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    android_order_id: string
    android_product_id: string
}

/** AndroidPurchase */
export interface CAPBAKAndroidPurchase {
    is_sandbox: boolean
    order_id: string
    product_id: string
    is_active: boolean
}

/** AndroidRightInfoResponse */
export interface CAPBAKAndroidRightInfoResponse {
    quota: number
    active_grants: CAPBAKClientRight[]
    created_grants?: object[]
}

/** AndroidStoreResponse */
export interface CAPBAKAndroidStoreResponse {
    result: CAPBAKAndroidRightInfoResponse
}

/** AndroidSubscription */
export interface CAPBAKAndroidSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** AppleClientRight */
export interface CAPBAKAppleClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    apple_original_transaction_id: string
    apple_product_id: string
}

/** ApplePurchase */
export interface CAPBAKApplePurchase {
    is_sandbox: boolean
    transaction_id: string
    product_id: string
    is_active: boolean
}

/** AppleRightInfoResponse */
export interface CAPBAKAppleRightInfoResponse {
    quota: number
    active_grants: CAPBAKClientRight[]
    created_grants?: CAPBAKGrantInfo[]
}

/** AppleStoreResponse */
export interface CAPBAKAppleStoreResponse {
    result: CAPBAKAppleRightInfoResponse
}

/** AppleSubsInReceipt */
export interface CAPBAKAppleSubsInReceipt {
    transaction_id: string
    original_transaction_id: string
    product_id: string
    expires_date_ms: number
    is_sandbox: boolean
    cancelled_date_ms?: number
    size: number
}

/** AppleSubscription */
export interface CAPBAKAppleSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** AppleTransactionResponse */
export interface CAPBAKAppleTransactionResponse {
    largest_valid_transaction?: CAPBAKAppleSubsInReceipt
    transactions: CAPBAKAppleSubsInReceipt[]
}

/** AsyncUploadExistsResponse */
export interface CAPBAKAsyncUploadExistsResponse {
    content: CAPBAKAsyncUploadExistsResponseContent
}

/** AsyncUploadExistsResponseContent */
export interface CAPBAKAsyncUploadExistsResponseContent {
    uuid: string
    status: CAPBAKFileLifetimeStatus
}

/** AsyncUploadInfo */
export interface CAPBAKAsyncUploadInfo {
    uuid: string
    file_checksum?: string
    path: string
    status: CAPBAKAsyncUploadState
    bytes_received: number
}

/** AsyncUploadResponse */
export interface CAPBAKAsyncUploadResponse {
    content: CAPBAKAsyncUploadResponseContent
}

/** AsyncUploadResponseContent */
export interface CAPBAKAsyncUploadResponseContent {
    status: CAPBAKAsyncUploadState
    bytes_received: number
}

/**
 * AsyncUploadState
 * Type that replicates the ASYNC_UPLOAD_STATE enum in postgres
 */
export enum CAPBAKAsyncUploadState {
    Uploading = 'uploading',
    Resumable = 'resumable',
    ReadyForProcessing = 'ready_for_processing',
    Processing = 'processing',
    ReadyForTranscoding = 'ready_for_transcoding',
    Failed = 'failed',
    Done = 'done',
}

/** AsyncUploadStatus */
export interface CAPBAKAsyncUploadStatus {
    status: CAPBAKAsyncUploadState
    checksum?: string
    bytes_received?: number
}

/** AsyncUploadStatusAndChangesResponse */
export interface CAPBAKAsyncUploadStatusAndChangesResponse {
    changes: any[]
    uploads?: CAPBAKAsyncUploadInfo[]
    info?: CAPBAKSpaceInfo
}

/** AsyncUploadStatusResponse */
export interface CAPBAKAsyncUploadStatusResponse {
    content: CAPBAKAsyncUploadStatus
}

/** AuthVerificationResp */
export interface CAPBAKAuthVerificationResp {
    message: 'invalid_user_auth' | 'no_longer_valid' | 'valid_user_auth'
    valid_to_epoch?: number
    current_time?: number
}

/** BasicDeviceInfo */
export interface CAPBAKBasicDeviceInfo {
    username: string
    valid: boolean
    accessed: string
    valid_to: string
    api_key: string
    last_auth: string
}

/** Body_create_private_share_test_private_share_post */
export interface CAPBAKBodyCreatePrivateShareTestPrivateSharePost {
    recipients: string[]
}

/** CancelAccountDeletion */
export interface CAPBAKCancelAccountDeletion {
    status: string
}

/** Card */
export interface CAPBAKCard {
    title: string
    image_url: string
    action_url: string
    cover_color?: string
    cover_image_url?: string
    emoji?: string
    secondary_emoji?: string
    seen: boolean
}

/** CardSection */
export interface CAPBAKCardSection {
    section_title: string
    show_as_swimlane: boolean
    cards: CAPBAKCard[]
    card_width?: string
    card_height?: string
}

/** Cards */
export interface CAPBAKCards {
    card_sections: CAPBAKCardSection[]
}

/** ChangesOption */
export interface CAPBAKChangesOption {
    description: string
    details: string
}

/** ChristmasMessages */
export enum CAPBAKChristmasMessages {
    Dec23 = 'dec_23',
    Dec26 = 'dec_26',
    Dec31 = 'dec_31',
}

/** ClassificationCategory */
export enum CAPBAKClassificationCategory {
    Document = 'document',
}

/** ClientFacingPush */
export enum CAPBAKClientFacingPush {
    UnderperformingUploads = 'underperforming_uploads',
    PermissionIssue = 'permission_issue',
    PermissionIssueIos14 = 'permission_issue_ios_14',
    PermissionIssueOldClientsIos14 = 'permission_issue_old_clients_ios_14',
    InstantMemories = 'instant_memories',
    InstantMemoriesDataPush = 'instant_memories_data_push',
    ChristmasCollection = 'christmas_collection',
    NewYearsEveCollection = 'new_years_eve_collection',
    NorwegianConstitutionDayCollection = 'norwegian_constitution_day_collection',
    SummerTravelsCollection = 'summer_travels_collection',
    InactiveSessionsWeekBeforeLogout = 'inactive_sessions_week_before_logout',
    InactiveSessionsDayBeforeLogout = 'inactive_sessions_day_before_logout',
    EngagingInactiveUsers = 'engaging_inactive_users',
    Sunset = 'sunset',
    OldSunset = 'old_sunset',
}

/** ClientGrantor */
export enum CAPBAKClientGrantor {
    AppleStore = 'apple store',
    AndroidStore = 'android store',
    Capture = 'capture',
    CaptureStripe = 'capture stripe',
    B2B = 'b2b',
    ConnectStore = 'connect store',
    CustomerService = 'customer service',
    Other = 'other',
}

/** ClientLoginOrRegistrationDict */
export interface CAPBAKClientLoginOrRegistrationDict {
    auth: string
    account_info: CAPBAKAccountInfo
    user_creation: number
    country_code: string
    service: CAPBAKServiceInfo
    use_cellular?: string
    td_sls?: string
    state?: string
    missing_tos_review?: number
}

/** ClientRight */
export interface CAPBAKClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
}

/** Collection */
export interface CAPBAKCollection {
    title: string
    subtitle: string
    photos: string[]
    seen_photos?: string[]
    collection_uuid: string
    collection_type: CAPBAKCollectionType
    highlights: string[]
    labels: Record<string, string>
    updates_card: string[]
    updates_card_title: string
    updates_card_body: string
    cover_color: string
    cover_image_url: string
    theme_color: string
    emoji: string
    secondary_emoji: string
    unseen_photo_index: number
}

/** CollectionType */
export enum CAPBAKCollectionType {
    AutumnTravels = 'autumn_travels',
    Christmas = 'christmas',
    NewYearsEve = 'new_years_eve',
    NorwegianConstitutionDay = 'norwegian_constitution_day',
    SummerTravels = 'summer_travels',
}

/** CommentResponse */
export interface CAPBAKCommentResponse {
    comment_uuid: string
    result?: 'OK'
}

/** Consent */
export interface CAPBAKConsent {
    key: CAPBAKConsentKey
    value: boolean
    changed_timestamp?: string
    user_changed_timestamp?: string
    created_timestamp: string
}

/** ConsentKey */
export enum CAPBAKConsentKey {
    FacialRecognition = 'facial_recognition',
    AdvancedFilteringAndCategorization = 'advanced_filtering_and_categorization',
    FaceGrouping = 'face_grouping',
    HelpUsImprove = 'help_us_improve',
    StayUpToDateEmail = 'stay_up_to_date__email',
    StayUpToDatePushNotification = 'stay_up_to_date__push_notification',
    StayUpToDateSms = 'stay_up_to_date__sms',
}

/** ConsentResponsev2 */
export interface CAPBAKConsentResponsev2 {
    value: boolean
    is_user_set: boolean
}

/** ConsentsResponse */
export interface CAPBAKConsentsResponse {
    data: Record<string, boolean>
    data_v2: Record<string, CAPBAKConsentResponsev2>
}

/** CsamDelayResponse */
export interface CAPBAKCsamDelayResponse {
    disable_user_new_due_at?: number
    delete_shadow_account_new_due_at?: number
}

/** CsamExistResponse */
export interface CAPBAKCsamExistResponse {
    is_csam_found: boolean
}

/** CumulusEventsData */
export interface CAPBAKCumulusEventsData {
    event_ids: string[]
}

/** Currency */
export enum CAPBAKCurrency {
    NOK = 'NOK',
    SEK = 'SEK',
    DKK = 'DKK',
    BGN = 'BGN',
}

/** CustomerServiceAgent */
export interface CAPBAKCustomerServiceAgent {
    username: string
    name: string
    comment: string
    id: number
    created: number
    disabled?: number
    role_ids: number[]
    roles: string[]
    permissions: CAPBAKCustomerServicePermission[]
}

/** CustomerServiceFileInfo */
export interface CAPBAKCustomerServiceFileInfo {
    file_uuid: string
    file_id: number
    flags: number
    ctime: number
    timestamp: number
    upload_time: number
    checksum: string
    size: number
}

/** CustomerServicePermission */
export enum CAPBAKCustomerServicePermission {
    CaptureFindSubscriptions = 'capture:find_subscriptions',
    CaptureGetInfo = 'capture:get_info',
    CaptureGetMerge = 'capture:get_merge',
    CapturePostMerge = 'capture:post_merge',
    CaptureMergeTicketGet = 'capture:merge_ticket_get',
    CaptureMergeTicketPost = 'capture:merge_ticket_post',
    CaptureMergeTicketSpecificGet = 'capture:merge_ticket_specific_get',
    CaptureMergeTicketSpecificPut = 'capture:merge_ticket_specific_put',
    CaptureMergeTicketSpecificPost = 'capture:merge_ticket_specific_post',
    CaptureMergeTicketSpecificDelete = 'capture:merge_ticket_specific_delete',
    CaptureDeleteDevice = 'capture:delete_device',
    CaptureToggleDisabled = 'capture:toggle_disabled',
    CaptureRegenerateThumbnails = 'capture:regenerate_thumbnails',
    CaptureRestoreDeletedFiles = 'capture:restore_deleted_files',
    CaptureCreatePoliceReportShare = 'capture:create_police_report_share',
    CaptureGetUserCsamStatus = 'capture:get_user_csam_status',
    CaptureAddTelenorIdEmail = 'capture:add_telenor_id_email',
    CaptureRemoveTelenorIdEmail = 'capture:remove_telenor_id_email',
    CaptureAddTelenorIdPhone = 'capture:add_telenor_id_phone',
    CaptureRemoveTelenorIdPhone = 'capture:remove_telenor_id_phone',
    CaptureDelayCsamNotificationsAndDeletionOfShadowAccount = 'capture:delay_csam_notifications_and_deletion_of_shadow_account',
    CaptureCancelCsamReport = 'capture:cancel_csam_report',
    CaptureDeleteUserAccount = 'capture:delete_user_account',
    CaptureDeleteTestUserAccount = 'capture:delete_test_user_account',
    CaptureCancelRegisteredAccountDeletionForUserWithoutTelenorId = 'capture:cancel_registered_account_deletion_for_user_without_telenor_id',
    CaptureUpdateDeleteTimeForAccount = 'capture:update_delete_time_for_account',
    CaptureCreateCaptureRight = 'capture:create_capture_right',
    CaptureDeleteCaptureRight = 'capture:delete_capture_right',
    CaptureResetThrottling = 'capture:reset_throttling',
    CaptureRestorePlayStorePurchase = 'capture:restore_play_store_purchase',
    CaptureRestoreAppStorePurchase = 'capture:restore_app_store_purchase',
    CaptureGetAppStorePurchase = 'capture:get_app_store_purchase',
    CaptureDelaySunsetUserHardDeletion = 'capture:delay_sunset_user_hard_deletion',
    GeneralSendSmsOtp = 'general:send_sms_otp',
    GeneralAdmin = 'general:admin',
    OrdenGetAccountInfo = 'orden:get_account_info',
    OrdenDeleteDevice = 'orden:delete_device',
    MimirGetRights = 'mimir:get_rights',
}

/** CustomerServiceRole */
export interface CAPBAKCustomerServiceRole {
    title: string
    permissions: CAPBAKCustomerServicePermission[]
    id: number
    created: number
    disabled?: number
}

/** CustomerServiceToolAuthResp */
export interface CAPBAKCustomerServiceToolAuthResp {
    customer_service_tool_client_auth: string
}

/** DBPingResponse */
export interface CAPBAKDBPingResponse {
    hostname: string
    result: Record<string, CAPBAKDBPingResult>
}

/** DBPingResult */
export interface CAPBAKDBPingResult {
    time: number
    error?: string
}

/** DataProtectionRequestResult */
export interface CAPBAKDataProtectionRequestResult {
    status: CAPBAKDataProtectionRequestStatus
}

/** DataProtectionRequestStatus */
export enum CAPBAKDataProtectionRequestStatus {
    OK = 'OK',
    CREATED = 'CREATED',
    COOLDOWN = 'COOLDOWN',
}

/** DelaySunsetUserHardDeletionResponse */
export interface CAPBAKDelaySunsetUserHardDeletionResponse {
    new_deletion_date: string
}

/** DeleteAlbumsResponse */
export interface CAPBAKDeleteAlbumsResponse {
    success: CAPBAKAlbumMessageResponse[]
    errors: CAPBAKAlbumMessageResponse[]
}

/** DeleteFilesResp */
export interface CAPBAKDeleteFilesResp {
    result: 'All_files_deleted'
}

/** DeleteTemplateResp */
export interface CAPBAKDeleteTemplateResp {
    result: 'deleted'
}

/** DeletedFilesInfoItem */
export interface CAPBAKDeletedFilesInfoItem {
    name: string
    size: number
    checksum: string
    ctime: number
}

/** DeletedFilesInfoResponse */
export interface CAPBAKDeletedFilesInfoResponse {
    deleted_files: CAPBAKDeletedFilesInfoItem[]
}

/** DetailedStoryInfoResponse */
export interface CAPBAKDetailedStoryInfoResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    owner: CAPBAKJobOwner
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: CAPBAKJobPermissionsExtended
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
}

/** DetailedStoryResponse */
export interface CAPBAKDetailedStoryResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: number
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
    owner?: CAPBAKJobOwner
}

/** DeviceInfo */
export interface CAPBAKDeviceInfo {
    deletecount?: number
    auto_upload?: string
    client_version?: string
    free_space?: number
    total_space?: number
    platform?: string
    os_version?: string
    uploadcount?: number
    downloadcount?: number
    rollbackcount?: number
    trashcan_visitcount?: number
    last_delete?: number | boolean
    last_upload?: number | boolean
    last_download?: number | boolean
    last_rollback?: number | boolean
    last_trashcan_visit?: number | boolean
    auth_id: string
    device_id: string
    device_name: string
    is_authorized: string
    login_methods: string[]
    last_connection: number
    login_time: number
    user_agent: string
    has_push_token: boolean
    last_push_feedback_time?: number
}

/** DeviceModel */
export interface CAPBAKDeviceModel {
    device_id?: string
    device_name?: string
}

/** DeviceResp */
export interface CAPBAKDeviceResp {
    auth_token: string
}

/** DeviceWithAuth */
export interface CAPBAKDeviceWithAuth {
    id: string
    name: string
    expires: string
}

/** DevicesWithAuth */
export interface CAPBAKDevicesWithAuth {
    current: string
    devices: CAPBAKDeviceWithAuth[]
}

/** DigitalLegacyChoice */
export enum CAPBAKDigitalLegacyChoice {
    OnlyProvideContentExplicitlyDetailedInMyWill = 'only_provide_content_explicitly_detailed_in_my_will',
    DoNotProvideContentToAnybody = 'do_not_provide_content_to_anybody',
    ProvideContentToContact = 'provide_content_to_contact',
}

/** DigitalLegacyContact */
export interface CAPBAKDigitalLegacyContact {
    name: string
    address: string
    email_address: string
    phone_number: string
}

/** DigitalLegacyData */
export interface CAPBAKDigitalLegacyData {
    user_uuid: string
    created_at: string
    updated_at?: string
    contact_information: CAPBAKDigitalLegacyContact[]
}

/** DigitalLegacyInfo */
export interface CAPBAKDigitalLegacyInfo {
    choice: CAPBAKDigitalLegacyChoice
    digital_legacy_data?: CAPBAKDigitalLegacyData
}

/** DummyPoliceJwtResp */
export interface CAPBAKDummyPoliceJwtResp {
    jwt: string
}

/** EmailEntry */
export interface CAPBAKEmailEntry {
    email: string
    timestamp: number
}

/** ErrorLogDataResult */
export interface CAPBAKErrorLogDataResult {
    key: string
    content: string
}

/** FeatureFlag */
export interface CAPBAKFeatureFlag {
    version: number
    force_reset: number
}

/** FeatureFlags */
export interface CAPBAKFeatureFlags {
    flags: Record<string, CAPBAKFeatureFlag>
}

/** FileDeletionsByDevice */
export interface CAPBAKFileDeletionsByDevice {
    deleted: number
    not_recoverable: number
    device_name: string
    info: Record<string, string | string[] | number | null>
}

/** FileExtensionsInfo */
export interface CAPBAKFileExtensionsInfo {
    file_extensions_info: CAPBAKImageTypeAndTimeRange[]
}

/** FileGroupType */
export enum CAPBAKFileGroupType {
    Live = 'live',
    SamsungMotion = 'samsung_motion',
    Burst = 'burst',
    GenericSuffixControlled = 'generic_suffix_controlled',
}

/** FileLifetimeStatus */
export enum CAPBAKFileLifetimeStatus {
    Exists = 'exists',
    Deleted = 'deleted',
    Trashed = 'trashed',
}

/** FileObjectResponse */
export interface CAPBAKFileObjectResponse {
    id: string
    path: string
    size: number
    checksum: string
    mtime: number
    file_type: CAPBAKFileTypeName
    ctime?: number
    width?: number
    height?: number
    duration?: number
    timestamp?: number
    group_type?: string
    master?: string
    user_uuid?: string
    revisions?: string[]
    aspect_ratio?: string
    group_id?: string
}

/** FilePropResponse */
export interface CAPBAKFilePropResponse {
    group_id?: string
    master: boolean
    group_type?: string
}

/** FileRecoveryStatus */
export interface CAPBAKFileRecoveryStatus {
    ref_id: string
    starting_time: number
    ending_time: number
    is_completed: boolean
    completed_at: number
    recovered_files_count: number
}

/** FileRefID */
export interface CAPBAKFileRefID {
    ref_id: string
}

/** FileTypeName */
export enum CAPBAKFileTypeName {
    Unclassified = 'unclassified',
    Video = 'video',
    Picture = 'picture',
    Document = 'document',
    Screenshot = 'screenshot',
}

/** FileUUIDList */
export interface CAPBAKFileUUIDList {
    file_uuids: string[]
}

/** FixedAppleSub */
export interface CAPBAKFixedAppleSub {
    old_size: number
    new_size: number
    transaction_id: string
}

/** FixedAppleSubsResponse */
export interface CAPBAKFixedAppleSubsResponse {
    result: CAPBAKFixedAppleSub[]
}

/** FullScreenTemplate */
export enum CAPBAKFullScreenTemplate {
    UserSunset = 'user_sunset',
    FacialGroupingMigration = 'facial_grouping_migration',
    GenericMessage1 = 'generic_message_1',
}

/** GetAgentResponse */
export interface CAPBAKGetAgentResponse {
    result: CAPBAKCustomerServiceAgent[]
}

/** GetDevicesResponse */
export interface CAPBAKGetDevicesResponse {
    device_info: CAPBAKBasicDeviceInfo[]
}

/** GetFileEventsResponse */
export interface CAPBAKGetFileEventsResponse {
    result: CAPBAKSessionMonthActivity[]
}

/** GetFileExtensionsResponse */
export interface CAPBAKGetFileExtensionsResponse {
    result: CAPBAKFileExtensionsInfo
}

/** GetFileInfoResponse */
export interface CAPBAKGetFileInfoResponse {
    files: CAPBAKCustomerServiceFileInfo[]
}

/** GetFileRecoveryStatusResponse */
export interface CAPBAKGetFileRecoveryStatusResponse {
    result: CAPBAKFileRecoveryStatus[]
}

/** GetJobInfoResponse */
export interface CAPBAKGetJobInfoResponse {
    result: CAPBAKJobClientInfo[]
}

/** GetPermissionsResponse */
export interface CAPBAKGetPermissionsResponse {
    result: CAPBAKCustomerServicePermission[]
}

/** GetPersonalInfoResponse */
export interface CAPBAKGetPersonalInfoResponse {
    result: CAPBAKPersonalInfo
}

/** GetRoleResponse */
export interface CAPBAKGetRoleResponse {
    result: CAPBAKCustomerServiceRole[]
}

/** GetSmsOtpResponse */
export interface CAPBAKGetSmsOtpResponse {
    result: CAPBAKSmsOtp
}

/** GetSubsResponse */
export interface CAPBAKGetSubsResponse {
    result: CAPBAKGroupedSubscriptions
}

/** GetUserActivityStatsResponse */
export interface CAPBAKGetUserActivityStatsResponse {
    content: CAPBAKUserActivityStats
}

/** GetUserEventsResponse */
export interface CAPBAKGetUserEventsResponse {
    events: CAPBAKUserEvent[]
}

/** GrantInfo */
export interface CAPBAKGrantInfo {
    created_grant_for_product: string
    grant_id: string
}

/** GrantInfoResponse */
export interface CAPBAKGrantInfoResponse {
    result: CAPBAKGrants
}

/** Grants */
export interface CAPBAKGrants {
    grants: (
        | CAPBAKStripeClientRight
        | CAPBAKAppleClientRight
        | CAPBAKAndroidClientRight
        | CAPBAKClientRight
    )[]
}

/** GroupedSubscriptions */
export interface CAPBAKGroupedSubscriptions {
    active: (
        | CAPBAKAndroidSubscription
        | CAPBAKAppleSubscription
        | CAPBAKStripeSubscription
    )[]
    deactivated: (
        | CAPBAKAndroidSubscription
        | CAPBAKAppleSubscription
        | CAPBAKStripeSubscription
    )[]
}

/** HTTPValidationError */
export interface CAPBAKHTTPValidationError {
    detail?: CAPBAKValidationError[]
}

/** ImageTypeAndTimeRange */
export interface CAPBAKImageTypeAndTimeRange {
    file_extension: string
    from_time: number
    to_time: number
    count: number
}

/** JobClientInfo */
export interface CAPBAKJobClientInfo {
    id: number
    uuid: string
    job_type: CAPBAKJobClientType
    active_file_count: number
    active_media_file_count: number
    deleted_file_count: number
    title: string
}

/** JobClientType */
export enum CAPBAKJobClientType {
    Timeline = 'Timeline',
    Share = 'Share',
    Story = 'Story',
    Deleted = 'Deleted',
    Blocked = 'Blocked',
    Unknown = 'Unknown',
    Purged = 'Purged',
}

/** JobListLight */
export interface CAPBAKJobListLight {
    feature_flag_hash: string
    max_space: string
    used_space: string
    jobs?: CAPBAKLightJob[]
}

/** JobOwner */
export interface CAPBAKJobOwner {
    uuid: string
    name?: string
    email?: string
    profile_picture_url?: string
}

/** JobPermissionsExtended */
export interface CAPBAKJobPermissionsExtended {
    allow_comments?: 0 | 1
    allow_uploads?: 0 | 1
    allow_reactions?: 0 | 1
    allow_sharing?: 0 | 1
    allow_anonymous_access?: 0 | 1
    allow_discovery?: 0 | 1
    allow_all_recipients?: 0 | 1
}

/** JobPermissionsResponse */
export interface CAPBAKJobPermissionsResponse {
    permissions: CAPBAKJobPermissionsExtended
}

/** JobPrivacyModeResponse */
export interface CAPBAKJobPrivacyModeResponse {
    privacy_mode: CAPBAKPrivacyMode
}

/**
 * JobResponse
 * This is used both in the job list and job info endpoints
 */
export interface CAPBAKJobResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
}

/** JobServiceResp */
export interface CAPBAKJobServiceResp {
    service: CAPBAKServiceInfo
}

/** JobSetPermissionsResponse */
export interface CAPBAKJobSetPermissionsResponse {
    permissions: CAPBAKJobPermissionsExtended
    result: 'changed' | 'unchanged'
}

/** JobUUIDList */
export interface CAPBAKJobUUIDList {
    job_uuids: string[]
}

/** KeepFilesQuotaExceeded */
export interface CAPBAKKeepFilesQuotaExceeded {
    message: string
    space_required: number
    space_remaining: number
}

/** Language */
export enum CAPBAKLanguage {
    No = 'no',
    En = 'en',
    Sv = 'sv',
}

/** LightJob */
export interface CAPBAKLightJob {
    id: string
    last_update: number
    last_user_update: number
}

/** LocaleResp */
export interface CAPBAKLocaleResp {
    locale: string
}

/** LoginIdentifierResp */
export interface CAPBAKLoginIdentifierResp {
    login_identifier: string
}

/** LoginTypeResp */
export interface CAPBAKLoginTypeResp {
    login_type: string
}

/** LogoutResponse */
export interface CAPBAKLogoutResponse {
    message: string
    result: CAPBAKLogoutResult
}

/** LogoutResult */
export enum CAPBAKLogoutResult {
    Success = 'success',
    ConnectLogoutFailed = 'connect_logout_failed',
}

/** MSISDNEntry */
export interface CAPBAKMSISDNEntry {
    msisdn: number
    timestamp: number
}

/** MediaCounters */
export interface CAPBAKMediaCounters {
    picture_count: number
    video_count: number
    screenshot_count: number
}

/**
 * MergeStatus
 * The different permitted statuses of a merge-ticket
 *
 * The status of a ticket can be changed. However, there are rules, in order
 * to prevent invalid status changes (like COMPLETED to CANCELLED, or others).
 * These rules are summarized by the following diagram:
 *
 *         { start }
 *             |
 *         <PENDING>
 *             |
 *             |(reject?)---[REJECTED]
 *             |
 *         <APPROVED>
 *             |
 *             |(cancel?)---[CANCELLED]
 *             |
 *         <STARTED>
 *             |
 *             |(fails?)----[FAILED]
 *             |
 *             |(error?)----[COMPLETED_WITH_ERRORS]
 *             |
 *         [COMPLETED]
 *
 * (Lines going DOWN or RIGHT are legal status changes)
 * (Angle-bracket statuses can be changed; Square-brackets are final)
 */
export enum CAPBAKMergeStatus {
    PendingApproval = 'pending_approval',
    Rejected = 'rejected',
    Approved = 'approved',
    Cancelled = 'cancelled',
    Started = 'started',
    Failed = 'failed',
    CompletedWithErrors = 'completed_with_errors',
    Completed = 'completed',
}

/** MergeTicket */
export interface CAPBAKMergeTicket {
    source: string
    target: string
    emails: string[]
    description: string
    id: number
    status: CAPBAKMergeStatus
    events: CAPBAKMergeTicketEvent[]
    created_event: CAPBAKMergeTicketEvent
    resolved_event?: CAPBAKMergeTicketEvent
}

/** MergeTicketEvent */
export interface CAPBAKMergeTicketEvent {
    status: CAPBAKMergeStatus
    agent_id?: number
    timestamp: number
}

/** MergeTicketId */
export interface CAPBAKMergeTicketId {
    ticket_id: number
}

/** MergeUserDryRunStatus */
export interface CAPBAKMergeUserDryRunStatus {
    source_user: CAPBAKMergeUserInfo
    target_user: CAPBAKMergeUserInfo
    contact_emails: string[]
    have_common_device: boolean
    have_common_advertising_id: boolean
    triggered: boolean
    strategy: string
}

/** MergeUserInfo */
export interface CAPBAKMergeUserInfo {
    name: string
    email?: string
    device_ids: string[]
    advertising_ids: string[]
}

/** MergeUserStatus */
export interface CAPBAKMergeUserStatus {
    source_user: CAPBAKMergeUserInfo
    target_user: CAPBAKMergeUserInfo
    contact_emails: string[]
    have_common_device: boolean
    have_common_advertising_id: boolean
    description: string
}

/** MessageAndResultResponse */
export interface CAPBAKMessageAndResultResponse {
    message: string
    result: CAPBAKResult
}

/** MessageResponse */
export interface CAPBAKMessageResponse {
    message: string
}

/** NewCustomerServiceAgent */
export interface CAPBAKNewCustomerServiceAgent {
    username: string
    name: string
    comment: string
}

/** NewCustomerServiceRole */
export interface CAPBAKNewCustomerServiceRole {
    title: string
    permissions: CAPBAKCustomerServicePermission[]
}

/** NewMergeTicket */
export interface CAPBAKNewMergeTicket {
    source: string
    target: string
    emails: string[]
    description: string
}

/** NotificationAttrs */
export interface CAPBAKNotificationAttrs {
    processing_is_done?: boolean
}

/** NotificationModel */
export interface CAPBAKNotificationModel {
    attrs: CAPBAKNotificationAttrs
}

/** OptionResponse */
export interface CAPBAKOptionResponse {
    value?: string
}

/**
 * PartialMetadataResponse
 * The metadata returned to the client if full=True is not provided
 */
export interface CAPBAKPartialMetadataResponse {
    Make?: string
    Model?: string
    ApertureValue?: string
    FNumber?: string
    ExposureTime?: string
    FocalLength?: string
    ISOSpeedRatings?: string
    'Capture.GpsPosition'?: number[]
    'Capture.OrigHeight'?: number
    'Capture.OrigWidth'?: number
}

/** PaymentInfoResponse */
export interface CAPBAKPaymentInfoResponse {
    account_balance: number
    currency?: CAPBAKCurrency
    card: string
    email?: string
    exp_month: number
    exp_year: number
    card_id: string
    invoice_payment_method: boolean
}

/** PermittedServices */
export interface CAPBAKPermittedServices {
    analytics: boolean
}

/** PersonalInfo */
export interface CAPBAKPersonalInfo {
    capture: CAPBAKPersonalInfoCapture
    telenorid: CAPBAKPersonalInfoTelenorIdResponse
}

/** PersonalInfoCapture */
export interface CAPBAKPersonalInfoCapture {
    name?: string
    email?: string
    email_history?: CAPBAKEmailEntry[]
    msisdn?: string
    msisdn_history?: CAPBAKMSISDNEntry[]
}

/** PersonalInfoTelenorID */
export interface CAPBAKPersonalInfoTelenorID {
    name?: string
    email?: string
    email_secondary?: string[]
    msisdn?: string
    msisdn_secondary?: string[]
}

/** PersonalInfoTelenorIdResponse */
export interface CAPBAKPersonalInfoTelenorIdResponse {
    result?: CAPBAKPersonalInfoTelenorID
    error?: number
}

/** PictureJobResponse */
export interface CAPBAKPictureJobResponse {
    id: string
    name: string
    mtime: number
    last_update?: number
}

/** PoliceReportCancelledResponse */
export interface CAPBAKPoliceReportCancelledResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
    cancelled_at: number
}

/** PoliceReportCompletedResponse */
export interface CAPBAKPoliceReportCompletedResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
    completed_at: number
    report_info: CAPBAKPoliceReportInfo
}

/** PoliceReportInfo */
export interface CAPBAKPoliceReportInfo {
    frozen_username: string
    share_job_uuid: string
    report_size: number
    duplication_started_at: number
    duplication_finished_at: number
}

/** PoliceReportParameters */
export interface CAPBAKPoliceReportParameters {
    metadata_only: boolean
    report_recipients: string[]
    since?: number
    until?: number
}

/** PoliceReportScheduledResponse */
export interface CAPBAKPoliceReportScheduledResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
}

/** PostAgentResponse */
export interface CAPBAKPostAgentResponse {
    result: CAPBAKAgentId
}

/** PostCumulusEventsResponse */
export interface CAPBAKPostCumulusEventsResponse {
    data: CAPBAKCumulusEventsData
}

/** PostFlightErrorResponse */
export interface CAPBAKPostFlightErrorResponse {
    status: 'success' | 'error' | 'failed'
    reason: string
    from_telenor_id?: boolean
    error_description?: string
}

/** PostFlightFailedResponse */
export interface CAPBAKPostFlightFailedResponse {
    status: 'success' | 'error' | 'failed'
    reason: string
    undelete_url?: string
    show_url?: string
}

/** PostFlightSuccessResponse */
export interface CAPBAKPostFlightSuccessResponse {
    status: 'success' | 'error' | 'failed'
    data: CAPBAKClientLoginOrRegistrationDict
}

/** PostRoleResponse */
export interface CAPBAKPostRoleResponse {
    result: CAPBAKRoleId
}

/** PreFlightAuthParams */
export interface CAPBAKPreFlightAuthParams {
    response_type: string
    redirect_uri: string
    client_id: string
    state: string
    scope: string
    claims?: string
    ui_locales: string
    acr_values: string
    log_session_id: string
    max_age?: string
    prompt?: string
}

/** PreFlightOauthState */
export interface CAPBAKPreFlightOauthState {
    authorization_url: string
    authorization_params: CAPBAKPreFlightAuthParams | object
    use_staging_authorization_server: boolean
}

/** PrivacyMode */
export enum CAPBAKPrivacyMode {
    Shared = 'shared',
    Protected = 'protected',
    Private = 'private',
    Public = 'public',
}

/** PrivateShareResp */
export interface CAPBAKPrivateShareResp {
    job_uuid: string
}

/** ProductListResponse */
export interface CAPBAKProductListResponse {
    result: any[]
}

/** ProfilePictureUploadResponse */
export interface CAPBAKProfilePictureUploadResponse {
    uuid: string
    used_space: number
    timestamp: number
    width?: number
    height?: number
    result?: string
    status?: CAPBAKFileLifetimeStatus
    profile_picture_url?: string
}

/** PublishShareResponse */
export interface CAPBAKPublishShareResponse {
    url: string
}

/** PurchaseStripeSubscriptionResponse */
export interface CAPBAKPurchaseStripeSubscriptionResponse {
    status: CAPBAKStripeSubscriptionStatus
    message: string
}

/** PushStatisticsResponse */
export interface CAPBAKPushStatisticsResponse {
    last_sent_time?: number
    last_received_time?: number
}

/** PushTokenResponse */
export interface CAPBAKPushTokenResponse {
    message: string
    status: string
}

/** ReactionResponse */
export interface CAPBAKReactionResponse {
    reaction_uuid: string
    file_uuid: string
    reaction: CAPBAKReactionType
    submitted: number
}

/** ReactionType */
export enum CAPBAKReactionType {
    Love = 'love',
    Favorite = 'favorite',
}

/** ReactionsResponse */
export interface CAPBAKReactionsResponse {
    content: CAPBAKReactionResponse[]
}

/** RecipientList */
export interface CAPBAKRecipientList {
    recipients: string[]
}

/** RemoteVar */
export interface CAPBAKRemoteVar {
    name: string
    value: string
}

/** RemoteVarData */
export interface CAPBAKRemoteVarData {
    variables: CAPBAKRemoteVar[]
}

/** RemoteVarDefinition */
export interface CAPBAKRemoteVarDefinition {
    name: string
    required_version: string
    api_key: string
    default_value: string
}

/** RemoteVarDefinitionData */
export interface CAPBAKRemoteVarDefinitionData {
    variables: CAPBAKRemoteVarDefinition[]
}

/** RestoreAlbumsResponse */
export interface CAPBAKRestoreAlbumsResponse {
    success: CAPBAKAlbumMessageResponse[]
    errors: CAPBAKAlbumMessageResponse[]
}

/** Result */
export enum CAPBAKResult {
    OK = 'OK',
}

/** Right */
export interface CAPBAKRight {
    right_id: string
    state: string
    grantor_id: string
    grantor_context: string
    time_interval: string
    sku: string
    used: number
    active: boolean
}

/** RightLinks */
export interface CAPBAKRightLinks {
    cancellation?: string
    reactivation?: string
}

/** RightPeriod */
export enum CAPBAKRightPeriod {
    Monthly = 'monthly',
    Yearly = 'yearly',
    Unknown = 'unknown',
}

/** RightSpec */
export interface CAPBAKRightSpec {
    sku: string
    grantor_context?: string
}

/** RoleId */
export interface CAPBAKRoleId {
    role_id: number
}

/** RunTimeIntervalModel */
export interface CAPBAKRunTimeIntervalModel {
    start_time: number
    end_time: number
    start_battery_level: number
    end_battery_level: number
    with_ac: boolean
    with_wifi: boolean
    with_background_boost?: boolean
}

/** RunTimeStatsModel */
export interface CAPBAKRunTimeStatsModel {
    run_time_intervals: CAPBAKRunTimeIntervalModel[]
}

/** SaveTemplateResp */
export interface CAPBAKSaveTemplateResp {
    result: 'overwritten' | 'created'
}

/** ScheduleForcedThumbnailRegenResponse */
export interface CAPBAKScheduleForcedThumbnailRegenResponse {
    result: CAPBAKThumbnailCount
}

/** ScheduleRestoreDeletedFilesResponse */
export interface CAPBAKScheduleRestoreDeletedFilesResponse {
    result: CAPBAKFileRefID
}

/** SearchProperties */
export interface CAPBAKSearchProperties {
    blocking_of_face_grouping_is_valid_from: number
}

/** SearchStatus */
export interface CAPBAKSearchStatus {
    job_uuid: string
    serial: number
    status: 'complete' | 'not complete'
}

/** SearchUserAttrs */
export interface CAPBAKSearchUserAttrs {
    user_uuid: string
    attrs: CAPBAKNbsStorageSearchIntegrationSearchPermissions
    props?: CAPBAKSearchProperties
}

/** SendCsamResp */
export interface CAPBAKSendCsamResp {
    request_status: 'OK' | 'ERROR'
    error?: string
}

/** ServerInfo */
export interface CAPBAKServerInfo {
    current_time: number
    server_name: string
    version: CAPBAKVersion
    python_version: (number | string)[]
    commit_hash: string
}

/** ServiceInfo */
export interface CAPBAKServiceInfo {
    'app-host': string
    'thumb-host': string
    'poll-host': string
    'video-host': string
    'search-host': string
    'download-host': string
}

/** ServiceStatus */
export interface CAPBAKServiceStatus {
    status?: string
    message?: string
}

/** SessionMonthActivity */
export interface CAPBAKSessionMonthActivity {
    yyyy_mm: string
    uploads: number
    downloads: number
    zip_downs: number
    deletions: number
    rollbacks: number
    auth_id?: string
    platform: string
}

/** SetJobRecipientResponse */
export interface CAPBAKSetJobRecipientResponse {
    user_uuids: string[]
}

/** SetReactionResponse */
export interface CAPBAKSetReactionResponse {
    message: string
    serial?: number
    uuid?: string
    result?: 'OK'
}

/** ShareExpiration */
export interface CAPBAKShareExpiration {
    expiration_date: string
}

/** ShouldSendUserStatsEventsResponse */
export interface CAPBAKShouldSendUserStatsEventsResponse {
    should_send_user_statistics_events: boolean
}

/** SmsOtp */
export interface CAPBAKSmsOtp {
    smsotp: string
}

/** SpaceInfo */
export interface CAPBAKSpaceInfo {
    used_space: number
    max_space: number
}

/** StoryInfoResponse */
export interface CAPBAKStoryInfoResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    owner: CAPBAKJobOwner
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: CAPBAKJobPermissionsExtended
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
}

/** StoryResponse */
export interface CAPBAKStoryResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: number
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    owner?: string
}

/** StripeClientRight */
export interface CAPBAKStripeClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    stripe_order_id: string
    stripe_product_id: string
    status?: CAPBAKStripeSubscriptionStatus
    cancelled_at?: number
}

/** StripeDeleteCCResponse */
export interface CAPBAKStripeDeleteCCResponse {
    result: CAPBAKStripeDeleteCCResult
}

/** StripeDeleteCCResult */
export interface CAPBAKStripeDeleteCCResult {
    is_deleted: boolean
    is_the_last_card: boolean
    failed_reason?: string
}

/** StripeEnv */
export enum CAPBAKStripeEnv {
    Production = 'production',
    Test = 'test',
}

/** StripeIdInfo */
export interface CAPBAKStripeIdInfo {
    customer_id: string
    username: string
}

/** StripeProduct */
export interface CAPBAKStripeProduct {
    id: string
    period: CAPBAKRightPeriod
    size: number
    trial?: boolean
    price?: number
    currency: CAPBAKCurrency
    env: CAPBAKStripeEnv
}

/** StripeProductListResponse */
export interface CAPBAKStripeProductListResponse {
    result: CAPBAKStripeProduct[]
    trial_period_months: number
    unavailable_product_ids?: string[]
}

/** StripePurchase */
export interface CAPBAKStripePurchase {
    is_sandbox: boolean
    subscription_id: string
    plan_id: string
    is_active: boolean
}

/** StripeSetupIntentCreateResponse */
export interface CAPBAKStripeSetupIntentCreateResponse {
    client_secret: string
    setup_intent_id: string
}

/** StripeSubscription */
export interface CAPBAKStripeSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** StripeSubscriptionActionRequiredResponse */
export interface CAPBAKStripeSubscriptionActionRequiredResponse {
    status: CAPBAKStripeSubscriptionStatus
    message: string
    client_secret: string
    subscription_id: string
}

/** StripeSubscriptionStatus */
export enum CAPBAKStripeSubscriptionStatus {
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    Trialing = 'trialing',
    Active = 'active',
    PastDue = 'past_due',
    Cancelled = 'cancelled',
    Unpaid = 'unpaid',
    Paused = 'paused',
    SubNotFoundInStripe = 'SubNotFoundInStripe',
}

/** SubFileStatus */
export enum CAPBAKSubFileStatus {
    Queued = 'queued',
    Ready = 'ready',
    Error = 'error',
}

/** SubFileStatusResponse */
export interface CAPBAKSubFileStatusResponse {
    result: CAPBAKSubFileStatus
}

/** Subscription */
export interface CAPBAKSubscription {
    subscription_id: string
    state: string
    grantor_id: string
    grantor_context: string
    orig_time_spec: string
    effective_time_spec?: string
    right_specs: CAPBAKRightSpec[]
}

/** SubscriptionService */
export enum CAPBAKSubscriptionService {
    GooglePlayStore = 'Google Play Store',
    AppleStore = 'Apple Store',
    Stripe = 'Stripe',
}

/** SunsetData */
export interface CAPBAKSunsetData {
    hard_deletion_time?: string
    flow?: CAPBAKSunsetFlow
    STEP_1_date?: string
    STEP_2_date?: string
    STEP_3_date?: string
    STEP_4_date?: string
    STEP_5_date?: string
    STEP_6_date?: string
    user_location?: 'NOR' | 'SWE' | 'GLOBAL'
    temp_excluded?: 'album' | 'no_telenor_id'
}

/** SunsetFlow */
export enum CAPBAKSunsetFlow {
    TNSE_STANDARD = 'TNSE_STANDARD',
    TNSE_ALBUM = 'TNSE_ALBUM',
    FREEMIUM_STANDARD = 'FREEMIUM_STANDARD',
    FREEMIUM_ALBUM = 'FREEMIUM_ALBUM',
    TNN_CHURNER_STANDARD = 'TNN_CHURNER_STANDARD',
    TNN_CHURNER_ALBUM = 'TNN_CHURNER_ALBUM',
    B2CCHURNERSTANDARD = 'B2C_CHURNER_STANDARD',
    B2CCHURNERALBUM = 'B2C_CHURNER_ALBUM',
}

/** SupportedAuds */
export enum CAPBAKSupportedAuds {
    PoliceIt = 'police.it',
    KriposIt = 'kripos.it',
}

/** SupportedExtensionsResponse */
export interface CAPBAKSupportedExtensionsResponse {
    media: string[]
    documents: string[]
    video: string[]
    image: string[]
}

/** SyncUploadExistsResponse */
export interface CAPBAKSyncUploadExistsResponse {
    content: CAPBAKSyncUploadExistsResponseContent
}

/** SyncUploadExistsResponseContent */
export interface CAPBAKSyncUploadExistsResponseContent {
    uuid: string
    status: CAPBAKFileLifetimeStatus
    width?: number
    height?: number
    max_space?: number
    used_space?: number
    estimated_used_space?: number
}

/** SyncUploadResponse */
export interface CAPBAKSyncUploadResponse {
    content: CAPBAKSyncUploadResponseContent
}

/** SyncUploadResponseContent */
export interface CAPBAKSyncUploadResponseContent {
    status: CAPBAKAsyncUploadState
    bytes_received: number
    uuid: string
    max_space?: number
    used_space?: number
    estimated_used_space?: number
}

/** ThrowawayAccountModel */
export interface CAPBAKThrowawayAccountModel {
    devices?: CAPBAKDeviceModel[]
    msisdn?: string
    email?: string
}

/** ThrowawayAccountResp */
export interface CAPBAKThrowawayAccountResp {
    result?: 'created'
    default_job: string
    username: string
    auth_token: string
    service: CAPBAKServiceInfo
    devices: CAPBAKDeviceResp[]
}

/** ThumbnailCount */
export interface CAPBAKThumbnailCount {
    thumbnails_count: number
}

/** TimelineDay */
export interface CAPBAKTimelineDay {
    year: number
    month: number
    picture_count: number
    video_count: number
    screenshot_count: number
    document_count: number
    other_count: number
    day: number
    count: number
}

/** TimelineDays */
export interface CAPBAKTimelineDays {
    days: CAPBAKTimelineDay[]
}

/** TimelineMonth */
export interface CAPBAKTimelineMonth {
    year: number
    month: number
    picture_count: number
    video_count: number
    screenshot_count: number
    document_count: number
    other_count: number
    count: number
}

/** TimelineMonths */
export interface CAPBAKTimelineMonths {
    months: CAPBAKTimelineMonth[]
}

/** TrashcanAlbumItem */
export interface CAPBAKTrashcanAlbumItem {
    id: string
    name: string
    mtime: number
    ctime: number
    dtime: number
    last_update: number
    size: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    privacy_mode: CAPBAKPrivacyMode
    participant_count: number
    cover_id?: string
    password_protected: boolean
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
}

/** TrashcanFileItem */
export interface CAPBAKTrashcanFileItem {
    id: string
    path: string
    dtime: number
    mtime: number
    size: number
    job: string
    group_id?: string
    group_type?: string
    master?: string
    width?: number
    height?: number
}

/** UndoDeletionRequestResp */
export interface CAPBAKUndoDeletionRequestResp {
    account_reset: boolean
}

/** UpdateStripePurchaseResponse */
export interface CAPBAKUpdateStripePurchaseResponse {
    message: string
    amount_due?: number
}

/** UploadErrorLogResult */
export interface CAPBAKUploadErrorLogResult {
    result: 'upload' | 'no_upload' | 'ok'
    url?: string
}

/** UploadPolicy */
export enum CAPBAKUploadPolicy {
    AllowDuplicates = 'allow_duplicates',
    NoDuplicates = 'no_duplicates',
    OnceOnly = 'once_only',
}

/** UploadResponse */
export interface CAPBAKUploadResponse {
    uuid: string
    used_space: number
    timestamp: number
    width?: number
    height?: number
    result?: string
    status?: CAPBAKFileLifetimeStatus
}

/** UsageAndQuotaResponse */
export interface CAPBAKUsageAndQuotaResponse {
    used_space: number
    max_space: number
}

/** UserActivityStats */
export interface CAPBAKUserActivityStats {
    engaged_users: number
    foreground_users: number
}

/** UserEvent */
export interface CAPBAKUserEvent {
    operation: string
    timestamp: number
    computer_name: string
    filename: string
    size: number
    id: number
    file_uuid: string
    file_id: number
    ctime: number
}

/** UserGroupCreationResponse */
export interface CAPBAKUserGroupCreationResponse {
    result: CAPBAKUserGroupCreationResult
}

/** UserGroupCreationResult */
export enum CAPBAKUserGroupCreationResult {
    Created = 'created',
    AlreadyCreated = 'already_created',
}

/** UserGroupDefinition */
export interface CAPBAKUserGroupDefinition {
    name: string
}

/** UserGroupEnrollmentResponse */
export interface CAPBAKUserGroupEnrollmentResponse {
    result: CAPBAKUserGroupEnrollmentResult
}

/** UserGroupEnrollmentResult */
export enum CAPBAKUserGroupEnrollmentResult {
    UserAdded = 'user_added',
    AlreadyMember = 'already_member',
}

/** UserGroupMembership */
export interface CAPBAKUserGroupMembership {
    name: string
    is_member: boolean
}

/** UserGroupWithdrawalResponse */
export interface CAPBAKUserGroupWithdrawalResponse {
    result: CAPBAKUserGroupWithdrawalResult
}

/** UserGroupWithdrawalResult */
export enum CAPBAKUserGroupWithdrawalResult {
    UserRemoved = 'user_removed',
    NotMember = 'not_member',
    GroupDoesNotExist = 'group_does_not_exist',
}

/** UserGroups */
export interface CAPBAKUserGroups {
    user_groups: CAPBAKUserGroupMembership[]
}

/** UserHealth */
export enum CAPBAKUserHealth {
    GOOD = 'GOOD',
    WARNING = 'WARNING',
    NEUTRAL = 'NEUTRAL',
}

/** UserLogoutResponse */
export interface CAPBAKUserLogoutResponse {
    user_logged_out: boolean
}

/** UserResponse */
export interface CAPBAKUserResponse {
    name: string
    subscribed: boolean
    self?: boolean
    profile_picture?: string
    profile_picture_url?: string
}

/** UserStatsEventModel */
export interface CAPBAKUserStatsEventModel {
    event_data: object
    event_name: string
    created_at: string
    user_uuid?: string
    session_id?: string
    device_id?: string
}

/** UserStatsEventsModel */
export interface CAPBAKUserStatsEventsModel {
    events: CAPBAKUserStatsEventModel[]
}

/** UserWithDeviceInfo */
export interface CAPBAKUserWithDeviceInfo {
    userinfo: CAPBAKNbsStorageCustomerServiceCustomerServiceUserInfo
    devices: CAPBAKDeviceInfo[]
}

/** UsersResponse */
export interface CAPBAKUsersResponse {
    last_user_update: number
    users: Record<string, CAPBAKUserResponse>
}

/** ValidationError */
export interface CAPBAKValidationError {
    loc: (string | number)[]
    msg: string
    type: string
}

/** Version */
export interface CAPBAKVersion {
    'capture-backend': string
}

/** VideoSub */
export enum CAPBAKVideoSub {
    VHigh = 'v-high',
    VLow = 'v-low',
    VCheck = 'v-check',
}

/** SearchPermissions */
export interface CAPBAKNbsStorageCustomerServiceCustomerServiceSearchPermissions {
    block_location_timestamp?: number
    block_face_timestamp?: number
    location_allowed: boolean
    face_allowed: boolean
}

/** UserInfo */
export interface CAPBAKNbsStorageCustomerServiceCustomerServiceUserInfo {
    uploadcount_android?: number
    uploadcount_ios?: number
    uploadcount_web?: number
    uploadcount_win?: number
    uploadcount_mac?: number
    uploadcount_linux?: number
    uploadcount_unknown?: number
    downloadcount_android?: number
    downloadcount_ios?: number
    downloadcount_web?: number
    downloadcount_win?: number
    downloadcount_mac?: number
    downloadcount_linux?: number
    downloadcount_unknown?: number
    deletecount_android?: number
    deletecount_ios?: number
    deletecount_web?: number
    deletecount_win?: number
    deletecount_mac?: number
    deletecount_linux?: number
    deletecount_unknown?: number
    rollbackcount?: number
    trashcan_visitcount?: number
    last_upload_android?: number | boolean
    last_upload_ios?: number | boolean
    last_upload_web?: number | boolean
    last_upload_win?: number | boolean
    last_upload_mac?: number | boolean
    last_upload_linux?: number | boolean
    last_upload_unknown?: number | boolean
    last_download_android?: number | boolean
    last_download_ios?: number | boolean
    last_download_web?: number | boolean
    last_download_win?: number | boolean
    last_download_mac?: number | boolean
    last_download_linux?: number | boolean
    last_download_unknown?: number | boolean
    last_delete_android?: number | boolean
    last_delete_ios?: number | boolean
    last_delete_web?: number | boolean
    last_delete_win?: number | boolean
    last_delete_mac?: number | boolean
    last_delete_linux?: number | boolean
    last_delete_unknown?: number | boolean
    last_rollback?: number | boolean
    last_trashcan_visit?: number | boolean
    usedspace: number
    username: string
    maxspace: number
    datacenter: string
    shard: number
    is_read_only_user: boolean
    subscription?: CAPBAKSubscription[]
    rights?: CAPBAKRight[]
    consents?: CAPBAKConsent[]
    creationtime: string
    first_upload?: string
    sunset_data?: CAPBAKSunsetData
    deletion_history: any[][]
    media_count_by_month: any[][]
    disabled?: string
    apple_purchases?: CAPBAKApplePurchase[]
    android_purchases?: CAPBAKAndroidPurchase[]
    stripe_purchases?: CAPBAKStripePurchase[]
    search_status?: CAPBAKSearchStatus[]
    search_permissions?: CAPBAKNbsStorageCustomerServiceCustomerServiceSearchPermissions
    albums_count: number
    shares_count: number
    subscribed_albums_count: number
}

/** UserInfo */
export interface CAPBAKNbsStorageMittTelenorUserInfo {
    user_health: CAPBAKUserHealth
    user_has_unlimited_quota: boolean
    background: string
    user_health_text: string
    button_text: string
    button_action: string
    connect_id?: string
    used_space_gb?: string
    quota_gb?: number
    out_of_quota?: boolean
    last_connection_time?: string
    last_upload_date?: string
    last_upload_file_name?: string
}

/** SearchPermissions */
export interface CAPBAKNbsStorageSearchIntegrationSearchPermissions {
    'search_backend.should_process_locations': boolean
    'search_backend.should_process_faces': boolean
    'search_backend.should_classify_images': boolean
    'search_backend.should_group_faces': boolean
}

export interface CAPBAKTestLocaleTestLocalePostParams {
    locale: string
}

export type CAPBAKTestLocaleTestLocalePostData = CAPBAKLocaleResp

export type CAPBAKTestLocaleTestLocalePostError = CAPBAKHTTPValidationError

export type CAPBAKTestCacheRemoveTestCacheRemoveJobUuidFileUuidPostData = any

export type CAPBAKTestCacheRemoveTestCacheRemoveJobUuidFileUuidPostError =
    CAPBAKHTTPValidationError

export type CAPBAKVerifyThumbsTestVerifyThumbsJobUuidFileUuidPostData = any

export type CAPBAKVerifyThumbsTestVerifyThumbsJobUuidFileUuidPostError =
    CAPBAKHTTPValidationError

export type CAPBAKVerifyThumbsCacheTestVerifyThumbsCacheJobUuidFileUuidPostData =
    any

export type CAPBAKVerifyThumbsCacheTestVerifyThumbsCacheJobUuidFileUuidPostError =
    CAPBAKHTTPValidationError

export type CAPBAKVerifyThumbsStorageTestVerifyThumbsStorageJobUuidFileUuidPostData =
    any

export type CAPBAKVerifyThumbsStorageTestVerifyThumbsStorageJobUuidFileUuidPostError =
    CAPBAKHTTPValidationError

export type CAPBAKTestDeleteAllFilesTestJobJobUuidDeleteAllFilesPostData =
    CAPBAKDeleteFilesResp

export type CAPBAKTestDeleteAllFilesTestJobJobUuidDeleteAllFilesPostError =
    CAPBAKHTTPValidationError

/** Model */
export type CAPBAKMakeThrowawayUserTestThrowawayUserPostPayload =
    CAPBAKThrowawayAccountModel

export interface CAPBAKMakeThrowawayUserTestThrowawayUserPostParams {
    key: CAPBAKAPIKey
    template?: string
    validity?: number
    is_read_only_user?: boolean
    'test-auth'?: string
    'super-auth'?: string
}

export type CAPBAKMakeThrowawayUserTestThrowawayUserPostData =
    CAPBAKThrowawayAccountResp

export type CAPBAKMakeThrowawayUserTestThrowawayUserPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKSaveThrowawayTemplateTestThrowawayUserTemplateJobUuidPostParams {
    template_name: string
    overwrite?: boolean
    'test-auth'?: string
    'super-auth'?: string
    jobUuid: string
}

export type CAPBAKSaveThrowawayTemplateTestThrowawayUserTemplateJobUuidPostData =
    CAPBAKSaveTemplateResp

export type CAPBAKSaveThrowawayTemplateTestThrowawayUserTemplateJobUuidPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteThrowawayTemplateTestThrowawayUserTemplateDeleteParams {
    template_name: string
    'test-auth'?: string
    'super-auth'?: string
}

export type CAPBAKDeleteThrowawayTemplateTestThrowawayUserTemplateDeleteData =
    CAPBAKDeleteTemplateResp

export type CAPBAKDeleteThrowawayTemplateTestThrowawayUserTemplateDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKRolloutUserToSearchTestSearchRolloutUsernamePostParams {
    'test-auth'?: string
    'super-auth'?: string
    username: string
}

export type CAPBAKRolloutUserToSearchTestSearchRolloutUsernamePostData = any

export type CAPBAKRolloutUserToSearchTestSearchRolloutUsernamePostError =
    CAPBAKHTTPValidationError

/**
 * Permissions
 * @uniqueItems true
 */
export type CAPBAKGetCustomerServiceToolAuthTestCustomerServiceToolAuthPostPayload =
    CAPBAKCustomerServicePermission[]

export type CAPBAKGetCustomerServiceToolAuthTestCustomerServiceToolAuthPostData =
    CAPBAKCustomerServiceToolAuthResp

export type CAPBAKGetCustomerServiceToolAuthTestCustomerServiceToolAuthPostError =
    CAPBAKHTTPValidationError

/** Message */
export type CAPBAKSendPushNotificationTestSendPushNotificationPostPayload =
    Record<string, string>

export interface CAPBAKSendPushNotificationTestSendPushNotificationPostParams {
    push_type?: CAPBAKClientFacingPush
    app_lang?: string
    abc_test_group_id?: string
}

export type CAPBAKSendPushNotificationTestSendPushNotificationPostData = any

export type CAPBAKSendPushNotificationTestSendPushNotificationPostError =
    CAPBAKHTTPValidationError

/** Message */
export type CAPBAKSendCollectionPushTestSendCollectionPushNotificationPostPayload =
    Record<string, string>

export interface CAPBAKSendCollectionPushTestSendCollectionPushNotificationPostParams {
    collection_type?: CAPBAKCollectionType
    app_lang?: string
}

export type CAPBAKSendCollectionPushTestSendCollectionPushNotificationPostData =
    any

export type CAPBAKSendCollectionPushTestSendCollectionPushNotificationPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKEraseStoreSubTestEraseStoreSubDeleteParams {
    store_type: 'APPLE' | 'STRIPE' | 'ANDROID'
}

export type CAPBAKEraseStoreSubTestEraseStoreSubDeleteData = any

export type CAPBAKEraseStoreSubTestEraseStoreSubDeleteError =
    CAPBAKHTTPValidationError

/** Response Remove Non Default Grants Test Remove Non Default Grants Delete */
export type CAPBAKRemoveNonDefaultGrantsTestRemoveNonDefaultGrantsDeleteData =
    any[]

export interface CAPBAKAddGrantTestAddGrantPostParams {
    grant_size: 1 | 10 | 100 | 1000 | 5000
    grantor_id: string
}

export type CAPBAKAddGrantTestAddGrantPostData = CAPBAKMessageResponse

export type CAPBAKAddGrantTestAddGrantPostError = CAPBAKHTTPValidationError

export interface CAPBAKGetLoginIdentifierTestLoginIdentifierGetParams {
    auth: string
}

export type CAPBAKGetLoginIdentifierTestLoginIdentifierGetData =
    CAPBAKLoginIdentifierResp

export type CAPBAKGetLoginIdentifierTestLoginIdentifierGetError =
    CAPBAKHTTPValidationError

export type CAPBAKCreatePrivateShareTestPrivateSharePostData =
    CAPBAKPrivateShareResp

export type CAPBAKCreatePrivateShareTestPrivateSharePostError =
    CAPBAKHTTPValidationError

export type CAPBAKUndoRequestedDeleteTestUndoRequestedDeleteUsernamePostData =
    CAPBAKUndoDeletionRequestResp

export type CAPBAKUndoRequestedDeleteTestUndoRequestedDeleteUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKCreateDummySignedJwtTestCreateDummySignedJwtGetParams {
    aud: CAPBAKSupportedAuds
}

export type CAPBAKCreateDummySignedJwtTestCreateDummySignedJwtGetData =
    CAPBAKDummyPoliceJwtResp

export type CAPBAKCreateDummySignedJwtTestCreateDummySignedJwtGetError =
    CAPBAKHTTPValidationError

export type CAPBAKSendCsamReportTestSendCsamReportPostData = CAPBAKSendCsamResp

export interface CAPBAKChristmasPushTestChristmasPushPostParams {
    push_type: CAPBAKChristmasMessages
    push_token: string
}

export type CAPBAKChristmasPushTestChristmasPushPostData = CAPBAKMessageResponse

export type CAPBAKChristmasPushTestChristmasPushPostError =
    CAPBAKHTTPValidationError

export type CAPBAKMigrateConsentsTestMigrateConsentsPostData =
    CAPBAKMessageResponse

export type CAPBAKRestoreOldConsentsTestRestoreOldConsentsPostData =
    CAPBAKMessageResponse

export type CAPBAKDeleteStripeDataTestDeleteStripeDataPostData =
    CAPBAKMessageResponse

export interface CAPBAKAddFullScreenCardTestFullScreenCardPostParams {
    scenario: string
    card_type?: CAPBAKFullScreenTemplate
}

export type CAPBAKAddFullScreenCardTestFullScreenCardPostData =
    CAPBAKMessageResponse

export type CAPBAKAddFullScreenCardTestFullScreenCardPostError =
    CAPBAKHTTPValidationError

export type CAPBAKCreateMemoryCollectionTestJobsJobUuidMemoryCollectionsCollectionTypePostData =
    any

export type CAPBAKCreateMemoryCollectionTestJobsJobUuidMemoryCollectionsCollectionTypePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetMemoryCollectionTestMemoryCollectionsCollectionUuidGetData =
    CAPBAKCollection

export type CAPBAKGetMemoryCollectionTestMemoryCollectionsCollectionUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKSunsetComsTestSunsetComsPostParams {
    sunset_flow: CAPBAKSunsetFlow
    loc_lang?: 'NOR' | 'SWE' | 'GLOBAL'
}

export type CAPBAKSunsetComsTestSunsetComsPostData = CAPBAKMessageResponse

export type CAPBAKSunsetComsTestSunsetComsPostError = CAPBAKHTTPValidationError

export interface CAPBAKDeleteUserWithoutTelenorIdComsTestDeleteUserWithoutTelenorIdComsPostParams {
    delete_user_email_lang?: 'no' | 'bg' | 'en' | 'se'
}

export type CAPBAKDeleteUserWithoutTelenorIdComsTestDeleteUserWithoutTelenorIdComsPostData =
    CAPBAKMessageResponse

export type CAPBAKDeleteUserWithoutTelenorIdComsTestDeleteUserWithoutTelenorIdComsPostError =
    CAPBAKHTTPValidationError

export type CAPBAKSetReadOnlyGrantTestSetReadOnlyGrantPostData =
    CAPBAKMessageResponse

export type CAPBAKSetFreemiumGrantTestSetFreemiumGrantPostData =
    CAPBAKMessageResponse

export interface CAPBAKResetTempFakeStipeCountryTestResetTempFakeStipeCountryPostParams {
    stripe_country: 'NOK' | 'SEK' | 'DKK' | 'BGN'
}

export type CAPBAKResetTempFakeStipeCountryTestResetTempFakeStipeCountryPostData =
    CAPBAKMessageResponse

export type CAPBAKResetTempFakeStipeCountryTestResetTempFakeStipeCountryPostError =
    CAPBAKHTTPValidationError

export type CAPBAKRemoveUserTestRemoveUserDeleteData = CAPBAKMessageResponse

export interface CAPBAKDigitalLegacyComsTestDigitalLegacyComsPostParams {
    name: string
    phone_number: string
    address: string
}

export type CAPBAKDigitalLegacyComsTestDigitalLegacyComsPostData =
    CAPBAKMessageResponse

export type CAPBAKDigitalLegacyComsTestDigitalLegacyComsPostError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteCaptureRightCsToolCaptureRightUsernameRightIdDeleteData =
    any

export type CAPBAKDeleteCaptureRightCsToolCaptureRightUsernameRightIdDeleteError =
    CAPBAKHTTPValidationError

export type CAPBAKPostResetThrottlingCsToolResetThrottlingUsernamePostData = any

export type CAPBAKPostResetThrottlingCsToolResetThrottlingUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKPostTelenorIdEmailCsToolTelenorIdUsernameEmailEmailPostData =
    CAPBAKMessageResponse

export type CAPBAKPostTelenorIdEmailCsToolTelenorIdUsernameEmailEmailPostError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteTelenorIdEmailCsToolTelenorIdUsernameEmailEmailDeleteData =
    CAPBAKMessageResponse

export type CAPBAKDeleteTelenorIdEmailCsToolTelenorIdUsernameEmailEmailDeleteError =
    CAPBAKHTTPValidationError

export type CAPBAKAddTelenorIdPhoneNumberCsToolTelenorIdUsernamePhoneNumberPhoneNumberPostData =
    CAPBAKMessageResponse

export type CAPBAKAddTelenorIdPhoneNumberCsToolTelenorIdUsernamePhoneNumberPhoneNumberPostError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteTelenorIdPhoneNumberCsToolTelenorIdUsernamePhoneNumberPhoneNumberDeleteData =
    CAPBAKMessageResponse

export type CAPBAKDeleteTelenorIdPhoneNumberCsToolTelenorIdUsernamePhoneNumberPhoneNumberDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostDelaySunsetUserHardDeletionCsToolDelaySunsetUserHardDeletionUsernameUsernamePostParams {
    delay_days: number
    username: string
}

export type CAPBAKPostDelaySunsetUserHardDeletionCsToolDelaySunsetUserHardDeletionUsernameUsernamePostData =
    CAPBAKDelaySunsetUserHardDeletionResponse

export type CAPBAKPostDelaySunsetUserHardDeletionCsToolDelaySunsetUserHardDeletionUsernameUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileInfoCsToolUsernameUsernameFilesInfoGetParams {
    count?: number
    start_id?: number
    active_files?: boolean
    deleted?: boolean
    unrefed?: boolean
    display_name?: string
    upload_time_min?: number
    upload_time_max?: number
    creation_time_min?: number
    creation_time_max?: number
    deletion_time_min?: number
    deletion_time_max?: number
    username: string
}

export type CAPBAKGetFileInfoCsToolUsernameUsernameFilesInfoGetData =
    CAPBAKGetFileInfoResponse

export type CAPBAKGetFileInfoCsToolUsernameUsernameFilesInfoGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetUserActivityStatsCsToolStatisticsUserActivityGetParams {
    cutoff: '1' | '2' | '7' | '30'
}

export type CAPBAKGetUserActivityStatsCsToolStatisticsUserActivityGetData =
    CAPBAKGetUserActivityStatsResponse

export type CAPBAKGetUserActivityStatsCsToolStatisticsUserActivityGetError =
    CAPBAKHTTPValidationError

/** Response Get Month Of File Deletions Cs Tool  Username  Month Of File Deletions  Year   Month  Get */
export type CAPBAKGetMonthOfFileDeletionsCsToolUsernameMonthOfFileDeletionsYearMonthGetData =
    CAPBAKFileDeletionsByDevice[]

export type CAPBAKGetMonthOfFileDeletionsCsToolUsernameMonthOfFileDeletionsYearMonthGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAndroidSubscriptionCsToolAndroidSubscriptionSubscriptionIdGetData =
    CAPBAKGetSubsResponse

export type CAPBAKGetAndroidSubscriptionCsToolAndroidSubscriptionSubscriptionIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAppleSubscriptionCsToolAppleSubscriptionSubscriptionIdGetData =
    CAPBAKGetSubsResponse

export type CAPBAKGetAppleSubscriptionCsToolAppleSubscriptionSubscriptionIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetStripeSubscriptionCsToolStripeSubscriptionSubscriptionIdGetData =
    CAPBAKGetSubsResponse

export type CAPBAKGetStripeSubscriptionCsToolStripeSubscriptionSubscriptionIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetStripeCustomerSubscriptionsCsToolStripeCustomerSubscriptionsCustomerIdGetData =
    CAPBAKGetSubsResponse

export type CAPBAKGetStripeCustomerSubscriptionsCsToolStripeCustomerSubscriptionsCustomerIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetCustomerServiceStripeCsToolStripeCustomerIdCustomerIdGetData =
    CAPBAKStripeIdInfo

export type CAPBAKGetCustomerServiceStripeCsToolStripeCustomerIdCustomerIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetFileExtensionsAndUploadedTimeRangeCsToolUsernameUsernameFileExtensionsInfoGetData =
    CAPBAKGetFileExtensionsResponse

export type CAPBAKGetFileExtensionsAndUploadedTimeRangeCsToolUsernameUsernameFileExtensionsInfoGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetFileRecoveriesCsToolUsernameUsernameFileRecoveriesGetData =
    CAPBAKGetFileRecoveryStatusResponse

export type CAPBAKGetFileRecoveriesCsToolUsernameUsernameFileRecoveriesGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKScheduleRestoreDeletedFilesCsToolUsernameUsernameFileRecoveriesPostParams {
    starting_time: number
    ending_time: number
    username: string
}

export type CAPBAKScheduleRestoreDeletedFilesCsToolUsernameUsernameFileRecoveriesPostData =
    CAPBAKScheduleRestoreDeletedFilesResponse

export type CAPBAKScheduleRestoreDeletedFilesCsToolUsernameUsernameFileRecoveriesPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKScheduleForcedThumbnailRegenerationCsToolScheduleForcedThumbnailRegenerationUsernamePostParams {
    image_type: string
    starting_time: number
    ending_time: number
    username: string
}

export type CAPBAKScheduleForcedThumbnailRegenerationCsToolScheduleForcedThumbnailRegenerationUsernamePostData =
    CAPBAKScheduleForcedThumbnailRegenResponse

export type CAPBAKScheduleForcedThumbnailRegenerationCsToolScheduleForcedThumbnailRegenerationUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetSmsOtpCsToolSmsOtpMsisdnGetData = CAPBAKGetSmsOtpResponse

export type CAPBAKGetSmsOtpCsToolSmsOtpMsisdnGetError =
    CAPBAKHTTPValidationError

export type CAPBAKPostEnableUserCsToolEnableUsernamePostData = any

export type CAPBAKPostEnableUserCsToolEnableUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostDisableUserCsToolDisableUsernamePostParams {
    reason: string
    username: string
}

export type CAPBAKPostDisableUserCsToolDisableUsernamePostData = any

export type CAPBAKPostDisableUserCsToolDisableUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetUserJobsCsToolUsernameUsernameJobsGetData =
    CAPBAKGetJobInfoResponse

export type CAPBAKGetUserJobsCsToolUsernameUsernameJobsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetLastEventsForUserCsToolUsernameUsernameLastEventsGetParams {
    count?: number
    username: string
}

/** Response Get Last Events For User Cs Tool Username  Username  Last Events Get */
export type CAPBAKGetLastEventsForUserCsToolUsernameUsernameLastEventsGetData =
    CAPBAKGetUserEventsResponse[]

export type CAPBAKGetLastEventsForUserCsToolUsernameUsernameLastEventsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetEventsForUserCsToolUsernameUsernameEventsGetParams {
    count?: number
    start_id?: number
    username: string
}

/** Response Get Events For User Cs Tool Username  Username  Events Get */
export type CAPBAKGetEventsForUserCsToolUsernameUsernameEventsGetData =
    CAPBAKGetUserEventsResponse[]

export type CAPBAKGetEventsForUserCsToolUsernameUsernameEventsGetError =
    CAPBAKHTTPValidationError

/** Response Get File Events For User Cs Tool Username  Username  File Events Get */
export type CAPBAKGetFileEventsForUserCsToolUsernameUsernameFileEventsGetData =
    CAPBAKGetFileEventsResponse[]

export type CAPBAKGetFileEventsForUserCsToolUsernameUsernameFileEventsGetError =
    CAPBAKHTTPValidationError

/** Response Get Devices For User Cs Tool Username  Username  Devices Get */
export type CAPBAKGetDevicesForUserCsToolUsernameUsernameDevicesGetData =
    CAPBAKGetDevicesResponse[]

export type CAPBAKGetDevicesForUserCsToolUsernameUsernameDevicesGetError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteDeviceForUserCsToolUsernameUsernameDevicesDeviceIdDeleteData =
    CAPBAKMessageResponse

/** Response Get Mimir Rights Cs Tool Mimir Rights Kurt  Kurt Id  Get */
export type CAPBAKGetMimirRightsCsToolMimirRightsKurtKurtIdGetData = any

export type CAPBAKGetMimirRightsCsToolMimirRightsKurtKurtIdGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostCaptureRightCsToolCaptureRightUsernamePostParams {
    duration: 1 | 3 | 6 | 12
    quota_size: 25 | 50 | 100 | 5000
    username: string
}

export type CAPBAKPostCaptureRightCsToolCaptureRightUsernamePostData = any

export type CAPBAKPostCaptureRightCsToolCaptureRightUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDelayCsamUserNotificationsAndDeletionOfShadowAccountCsToolDelayCsamUserNotificationsAndDeletionOfShadowAccountUsernamePostParams {
    delay_in_seconds: number
    username: string
}

export type CAPBAKDelayCsamUserNotificationsAndDeletionOfShadowAccountCsToolDelayCsamUserNotificationsAndDeletionOfShadowAccountUsernamePostData =
    CAPBAKCsamDelayResponse

export type CAPBAKDelayCsamUserNotificationsAndDeletionOfShadowAccountCsToolDelayCsamUserNotificationsAndDeletionOfShadowAccountUsernamePostError =
    CAPBAKHTTPValidationError

/** Response Get Police Report Share Status Cs Tool Police Report Share Status  Username  Get */
export type CAPBAKGetPoliceReportShareStatusCsToolPoliceReportShareStatusUsernameGetData =
    (
        | CAPBAKPoliceReportScheduledResponse
        | CAPBAKPoliceReportCancelledResponse
        | CAPBAKPoliceReportCompletedResponse
    )[]

export type CAPBAKGetPoliceReportShareStatusCsToolPoliceReportShareStatusUsernameGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetIsCsamFoundOnAccountCsToolIsCsamFoundOnAccountUsernameGetData =
    CAPBAKCsamExistResponse

export type CAPBAKGetIsCsamFoundOnAccountCsToolIsCsamFoundOnAccountUsernameGetError =
    CAPBAKHTTPValidationError

/**
 * Police Report Param
 * When `metadata_only = true`, files are not included in the report.For `report_recipients`, Array of email addresses or MSISDNs of those who shall have access to the share, once it is done. If these are not associated with a Capture account, there is a deadlineof 30 days to sign up and accessing the share.For `since`, If included, restrict which files to preserve by upload timestamp with a lower bound.For `until`, If included, restrict which files to preserve by upload timestamp with an upper bound.
 */
export type CAPBAKScheduleCreatingPoliceReportShareCsToolScheduleCreatingPoliceReportShareUsernamePostPayload =
    CAPBAKPoliceReportParameters

export type CAPBAKScheduleCreatingPoliceReportShareCsToolScheduleCreatingPoliceReportShareUsernamePostData =
    CAPBAKMessageResponse

export type CAPBAKScheduleCreatingPoliceReportShareCsToolScheduleCreatingPoliceReportShareUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKCancelCsamReportCsToolCancelCsamReportUsernamePostData =
    CAPBAKMessageResponse

export type CAPBAKCancelCsamReportCsToolCancelCsamReportUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAppStoreSubCsToolAppStoreTransactionIdTransactionIdGetData =
    CAPBAKAppleTransactionResponse

export type CAPBAKGetAppStoreSubCsToolAppStoreTransactionIdTransactionIdGetError =
    CAPBAKHTTPValidationError

/**
 * Receipt
 * Base64 encoded apple receipt
 */
export type CAPBAKGetAppStoreSubsCsToolAppStoreSubscriptionsGetPayload = string

/** Response Get App Store Subs Cs Tool App Store Subscriptions Get */
export type CAPBAKGetAppStoreSubsCsToolAppStoreSubscriptionsGetData =
    CAPBAKAppleSubsInReceipt[]

export type CAPBAKGetAppStoreSubsCsToolAppStoreSubscriptionsGetError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteUserCsToolUserAccountUsernameDeleteData =
    CAPBAKMessageResponse

export type CAPBAKDeleteUserCsToolUserAccountUsernameDeleteError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteTestUserCsToolTestUserAccountUsernameDeleteData =
    CAPBAKMessageResponse

export type CAPBAKDeleteTestUserCsToolTestUserAccountUsernameDeleteError =
    CAPBAKHTTPValidationError

export type CAPBAKRestorePlayStorePurchaseCsToolRestorePlayStorePurchaseUsernamePostData =
    CAPBAKMessageResponse

export type CAPBAKRestorePlayStorePurchaseCsToolRestorePlayStorePurchaseUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKCancelRegisteredAccountDeletionForUserWithoutTelenorIdCsToolCancelRegisteredAccountDeletionForUserWithoutTelenorIdUsernamePostData =
    CAPBAKMessageResponse

export type CAPBAKCancelRegisteredAccountDeletionForUserWithoutTelenorIdCsToolCancelRegisteredAccountDeletionForUserWithoutTelenorIdUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKUpdateDeleteTimeForAccountCsToolUpdateDeleteTimeForAccountUsernamePostParams {
    delete_time: string
    username: string
}

export type CAPBAKUpdateDeleteTimeForAccountCsToolUpdateDeleteTimeForAccountUsernamePostData =
    CAPBAKMessageResponse

export type CAPBAKUpdateDeleteTimeForAccountCsToolUpdateDeleteTimeForAccountUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernamePostParams {
    contact_email: string
    srcUsername: string
    tgtUsername: string
}

/** Response Post Merge Accounts Cs Tool Merge  Src Username  Into  Tgt Username  Post */
export type CAPBAKPostMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernamePostData =
    CAPBAKMergeUserDryRunStatus | CAPBAKMergeUserStatus

export type CAPBAKPostMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernameGetParams {
    contact_email: string
    srcUsername: string
    tgtUsername: string
}

/** Response Get Merge Accounts Cs Tool Merge  Src Username  Into  Tgt Username  Get */
export type CAPBAKGetMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernameGetData =
    CAPBAKMergeUserDryRunStatus | CAPBAKMergeUserStatus

export type CAPBAKGetMergeAccountsCsToolMergeSrcUsernameIntoTgtUsernameGetError =
    CAPBAKHTTPValidationError

export type CAPBAKForceUpdateQuotaCsToolRefreshCaptureRightUsernamePostData =
    any

export type CAPBAKForceUpdateQuotaCsToolRefreshCaptureRightUsernamePostError =
    CAPBAKHTTPValidationError

/** Response Get Merge Tickets Cs Tool Merge Ticket Get */
export type CAPBAKGetMergeTicketsCsToolMergeTicketGetData = CAPBAKMergeTicket[]

export type CAPBAKGetMergeTicketsCsToolMergeTicketGetError =
    CAPBAKHTTPValidationError

export type CAPBAKPostMergeTicketCsToolMergeTicketPostData = CAPBAKMergeTicketId

export type CAPBAKPostMergeTicketCsToolMergeTicketPostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetMergeTicketCsToolMergeTicketTicketIdGetData =
    CAPBAKMergeTicket

export type CAPBAKGetMergeTicketCsToolMergeTicketTicketIdGetError =
    CAPBAKHTTPValidationError

export type CAPBAKPutMergeTicketCsToolMergeTicketTicketIdPutData =
    CAPBAKMessageResponse

export type CAPBAKPutMergeTicketCsToolMergeTicketTicketIdPutError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteMergeTicketCsToolMergeTicketTicketIdDeleteData =
    CAPBAKMessageResponse

export type CAPBAKDeleteMergeTicketCsToolMergeTicketTicketIdDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKResolveMergeTicketCsToolMergeTicketTicketIdPostParams {
    action: 'approve' | 'cancel'
    ticketId: number
}

export type CAPBAKResolveMergeTicketCsToolMergeTicketTicketIdPostData =
    CAPBAKMessageResponse

export type CAPBAKResolveMergeTicketCsToolMergeTicketTicketIdPostError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteCustomerServiceAgentCsToolAgentAgentIdDeleteData = any

export type CAPBAKDeleteCustomerServiceAgentCsToolAgentAgentIdDeleteError =
    CAPBAKHTTPValidationError

/**
 * New Agent
 * New agent values
 */
export type CAPBAKUpdateCustomerServiceAgentCsToolAgentAgentIdPutPayload =
    CAPBAKNewCustomerServiceAgent

export type CAPBAKUpdateCustomerServiceAgentCsToolAgentAgentIdPutData = any

export type CAPBAKUpdateCustomerServiceAgentCsToolAgentAgentIdPutError =
    CAPBAKHTTPValidationError

/**
 * New Agent
 * Agent to create
 */
export type CAPBAKPostCustomerServiceAgentCsToolAgentPostPayload =
    CAPBAKNewCustomerServiceAgent

export type CAPBAKPostCustomerServiceAgentCsToolAgentPostData =
    CAPBAKPostAgentResponse

export type CAPBAKPostCustomerServiceAgentCsToolAgentPostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAllCustomerServiceAgentsCsToolAgentGetData =
    CAPBAKGetAgentResponse

export type CAPBAKGetAllCustomerServiceAgentsCsToolAgentGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAllCustomerServiceRolesCsToolRoleGetData =
    CAPBAKGetRoleResponse

export type CAPBAKGetAllCustomerServiceRolesCsToolRoleGetError =
    CAPBAKHTTPValidationError

/**
 * New Role
 * Role to create
 */
export type CAPBAKPostCustomerServiceRoleCsToolRolePostPayload =
    CAPBAKNewCustomerServiceRole

export type CAPBAKPostCustomerServiceRoleCsToolRolePostData =
    CAPBAKPostRoleResponse

export type CAPBAKPostCustomerServiceRoleCsToolRolePostError =
    CAPBAKHTTPValidationError

/**
 * New Role
 * new role values
 */
export type CAPBAKUpdateCustomerServiceRoleCsToolRoleRoleIdPutPayload =
    CAPBAKNewCustomerServiceRole

export type CAPBAKUpdateCustomerServiceRoleCsToolRoleRoleIdPutData = any

export type CAPBAKUpdateCustomerServiceRoleCsToolRoleRoleIdPutError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteCustomerServiceRoleCsToolRoleRoleIdDeleteData = any

export type CAPBAKDeleteCustomerServiceRoleCsToolRoleRoleIdDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostCustomerServiceGrantCsToolGrantPostParams {
    agent_id: number
    role_id: number
}

export type CAPBAKPostCustomerServiceGrantCsToolGrantPostData = any

export type CAPBAKPostCustomerServiceGrantCsToolGrantPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteCustomerServiceGrantCsToolGrantDeleteParams {
    agent_id: number
    role_id: number
}

export type CAPBAKDeleteCustomerServiceGrantCsToolGrantDeleteData = any

export type CAPBAKDeleteCustomerServiceGrantCsToolGrantDeleteError =
    CAPBAKHTTPValidationError

export type CAPBAKGetAllCustomerServicePermissionsCsToolPermissionGetData =
    CAPBAKGetPermissionsResponse

export type CAPBAKGetAllCustomerServicePermissionsCsToolPermissionGetError =
    CAPBAKHTTPValidationError

export type CAPBAKRestoreAppStorePurchaseCsToolRestoreAppStorePurchaseUsernamePostData =
    CAPBAKFixedAppleSubsResponse

export type CAPBAKRestoreAppStorePurchaseCsToolRestoreAppStorePurchaseUsernamePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetUserInfoByUsernameCsToolUsernameUsernameInfoGetData =
    CAPBAKUserWithDeviceInfo

export type CAPBAKGetUserInfoByUsernameCsToolUsernameUsernameInfoGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetUserInfoByUuidCsToolUserUuidUserUuidInfoGetData =
    CAPBAKUserWithDeviceInfo

export type CAPBAKGetUserInfoByUuidCsToolUserUuidUserUuidInfoGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostTelenoridLogoutCsToolTelenoridLogoutPostParams {
    auth: string
    key: string
}

export type CAPBAKPostTelenoridLogoutCsToolTelenoridLogoutPostData =
    CAPBAKUserLogoutResponse

export type CAPBAKPostTelenoridLogoutCsToolTelenoridLogoutPostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetUserExistsByUsernameCsToolUsernameUsernameExistsGetData =
    any

export type CAPBAKGetUserExistsByUsernameCsToolUsernameUsernameExistsGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetPersonalInfoCsToolUsernameUsernamePersonalInfoGetData =
    CAPBAKGetPersonalInfoResponse

export type CAPBAKGetPersonalInfoCsToolUsernameUsernamePersonalInfoGetError =
    CAPBAKHTTPValidationError

export type CAPBAKPingPingGetData = CAPBAKAPIPingResponse

export type CAPBAKPingDbPingDbGetData = CAPBAKDBPingResponse

export type CAPBAKPingStoryDbServerStatsPingStoryDbGetData =
    CAPBAKDBPingResponse

export type CAPBAKPingKeyDbServerStatsPingKeyDbGetData = CAPBAKDBPingResponse

export interface CAPBAKServerInfoServerInfoGetParams {
    key: string
}

export type CAPBAKServerInfoServerInfoGetData = CAPBAKServerInfo

export type CAPBAKServerInfoServerInfoGetError = CAPBAKHTTPValidationError

export interface CAPBAKServiceStatusServiceStatusGetParams {
    key: string
    app_lang: string
}

export type CAPBAKServiceStatusServiceStatusGetData = CAPBAKServiceStatus

export type CAPBAKServiceStatusServiceStatusGetError = CAPBAKHTTPValidationError

/** Response Read Error Log Connect User  Username  Error Logs Get */
export type CAPBAKReadErrorLogConnectUserUsernameErrorLogsGetData =
    CAPBAKErrorLogDataResult[]

export type CAPBAKReadErrorLogConnectUserUsernameErrorLogsGetError =
    CAPBAKHTTPValidationError

export type CAPBAKRequestErrorLogsConnectUserUsernameRequestErrorLogsPostData =
    CAPBAKMessageResponse

export type CAPBAKRequestErrorLogsConnectUserUsernameRequestErrorLogsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKShouldUploadErrorLogsUserShouldUploadErrorLogsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKShouldUploadErrorLogsUserShouldUploadErrorLogsGetData =
    CAPBAKUploadErrorLogResult

export type CAPBAKShouldUploadErrorLogsUserShouldUploadErrorLogsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKUploadErrorLogUserShouldUploadErrorLogsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUploadErrorLogUserShouldUploadErrorLogsPostData =
    CAPBAKUploadErrorLogResult

export type CAPBAKUploadErrorLogUserShouldUploadErrorLogsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetCardsCardsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetCardsCardsGetData = CAPBAKCards

export type CAPBAKGetCardsCardsGetError = CAPBAKHTTPValidationError

export interface CAPBAKDismissFullScreenCardFullScreenCardsFullScreenCardIdDismissPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    fullScreenCardId: string
}

export type CAPBAKDismissFullScreenCardFullScreenCardsFullScreenCardIdDismissPostData =
    CAPBAKMessageResponse

export type CAPBAKDismissFullScreenCardFullScreenCardsFullScreenCardIdDismissPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKSetTosReviewedSetTosReviewedPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKSetTosReviewedSetTosReviewedPostData = any

export type CAPBAKSetTosReviewedSetTosReviewedPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobJobsPostParams {
    name?: string
    password?: string
    message?: string
    ctime?: number
    type?: string
    public?: boolean
    allow_comments?: boolean
    allow_uploads?: boolean
    allow_reactions?: boolean
    allow_sharing?: boolean
    allow_anonymous_access?: boolean
    allow_discovery?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostJobJobsPostData = any

export type CAPBAKPostJobJobsPostError = CAPBAKHTTPValidationError

export interface CAPBAKGetJobListJobsGetParams {
    public?: boolean
    stories?: boolean
    account_jobs?: boolean
    include_details?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Get Job List Jobs Get */
export type CAPBAKGetJobListJobsGetData = (
    | CAPBAKJobResponse
    | CAPBAKStoryResponse
    | CAPBAKDetailedStoryResponse
)[]

export type CAPBAKGetJobListJobsGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetJobListLightJobsLightGetParams {
    public?: boolean
    account_jobs?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetJobListLightJobsLightGetData = CAPBAKJobListLight

export type CAPBAKGetJobListLightJobsLightGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetJobInfoJobsJobUuidInfoGetParams {
    include_details?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Get Job Info Jobs  Job Uuid  Info Get */
export type CAPBAKGetJobInfoJobsJobUuidInfoGetData =
    | CAPBAKJobResponse
    | CAPBAKStoryInfoResponse
    | CAPBAKDetailedStoryInfoResponse

export type CAPBAKGetJobInfoJobsJobUuidInfoGetError = CAPBAKHTTPValidationError

export type CAPBAKJobServiceGetHandlerJobsJobUuidServiceGetData =
    CAPBAKJobServiceResp

export type CAPBAKJobServiceGetHandlerJobsJobUuidServiceGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetAlbumUsersJobsJobUuidUsersGetParams {
    include_profile_url?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetAlbumUsersJobsJobUuidUsersGetData = CAPBAKUsersResponse

export type CAPBAKGetAlbumUsersJobsJobUuidUsersGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKTimelineDaysJobsJobUuidTimelineDaysGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKTimelineDaysJobsJobUuidTimelineDaysGetData =
    CAPBAKTimelineDays

export type CAPBAKTimelineDaysJobsJobUuidTimelineDaysGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKTimelineMonthsJobsJobUuidTimelineMonthsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKTimelineMonthsJobsJobUuidTimelineMonthsGetData =
    CAPBAKTimelineMonths

export type CAPBAKTimelineMonthsJobsJobUuidTimelineMonthsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKJobChangesOptionsJobsChangesOptionsParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Job Changes Options Jobs Changes Options */
export type CAPBAKJobChangesOptionsJobsChangesOptionsData = Record<
    string,
    CAPBAKChangesOption
>

export type CAPBAKJobChangesOptionsJobsChangesOptionsError =
    CAPBAKHTTPValidationError

export interface CAPBAKPutAttributeOnJobJobsJobUuidAttributeAttributePutParams {
    value: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    attribute: string
}

export type CAPBAKPutAttributeOnJobJobsJobUuidAttributeAttributePutData = any

export type CAPBAKPutAttributeOnJobJobsJobUuidAttributeAttributePutError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileMetadataJobsJobUuidMetadataFileUuidGetParams {
    full?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

/** Response Get File Metadata Jobs  Job Uuid  Metadata  File Uuid  Get */
export type CAPBAKGetFileMetadataJobsJobUuidMetadataFileUuidGetData =
    | CAPBAKPartialMetadataResponse
    | object

export type CAPBAKGetFileMetadataJobsJobUuidMetadataFileUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostSyncUploadJobsJobUuidFilesPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    want_json?: boolean
    want_dimensions?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostSyncUploadJobsJobUuidFilesPostData = CAPBAKUploadResponse

export type CAPBAKPostSyncUploadJobsJobUuidFilesPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetJobFilesJobsJobUuidFilesGetParams {
    path?: string
    start?: string
    end?: string
    file_type_filters?: string
    uploaded_since?: number
    reaction?: CAPBAKReactionType
    dirs_only?: boolean
    recursive?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Get Job Files Jobs  Job Uuid  Files Get */
export type CAPBAKGetJobFilesJobsJobUuidFilesGetData =
    CAPBAKFileObjectResponse[]

export type CAPBAKGetJobFilesJobsJobUuidFilesGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostSyncUploadWithAsyncProcessingJobsJobUuidUploadPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Post Sync Upload With Async Processing Jobs  Job Uuid  Upload Post */
export type CAPBAKPostSyncUploadWithAsyncProcessingJobsJobUuidUploadPostData =
    | CAPBAKSyncUploadExistsResponse
    | CAPBAKSyncUploadResponse

export type CAPBAKPostSyncUploadWithAsyncProcessingJobsJobUuidUploadPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileJobsJobUuidFilesByIdFileUuidGetParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    allow_file_from_trash?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKGetFileJobsJobUuidFilesByIdFileUuidGetData =
    CAPBAKSubFileStatusResponse

export type CAPBAKGetFileJobsJobUuidFilesByIdFileUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteFileDeleteJobsJobUuidFilesByIdFileUuidDeleteParams {
    recursive: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

/** Response Delete File Delete Jobs  Job Uuid  Files By Id  File Uuid  Delete */
export type CAPBAKDeleteFileDeleteJobsJobUuidFilesByIdFileUuidDeleteData =
    CAPBAKUsageAndQuotaResponse

export type CAPBAKDeleteFileDeleteJobsJobUuidFilesByIdFileUuidDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKDedupFileJobsJobUuidFilesDedupGetParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDedupFileJobsJobUuidFilesDedupGetData = CAPBAKUploadResponse

export type CAPBAKDedupFileJobsJobUuidFilesDedupGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKDedupFileJobsJobUuidFilesDedupPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDedupFileJobsJobUuidFilesDedupPostData = CAPBAKUploadResponse

export type CAPBAKDedupFileJobsJobUuidFilesDedupPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKAsyncUploadCheckJobsJobUuidAsyncUploadChecksumGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum: string
}

export type CAPBAKAsyncUploadCheckJobsJobUuidAsyncUploadChecksumGetData =
    CAPBAKAsyncUploadStatusResponse

export type CAPBAKAsyncUploadCheckJobsJobUuidAsyncUploadChecksumGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKAsyncUploadFileJobsJobUuidAsyncUploadChecksumPostParams {
    path: string
    offset?: number
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum?: string
}

/** Response Async Upload File Jobs  Job Uuid  Async Upload  Checksum  Post */
export type CAPBAKAsyncUploadFileJobsJobUuidAsyncUploadChecksumPostData =
    | CAPBAKAsyncUploadResponse
    | CAPBAKAsyncUploadExistsResponse

export type CAPBAKAsyncUploadFileJobsJobUuidAsyncUploadChecksumPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKChangesAndAsyncUploadStatusJobsJobUuidChangesAndAsyncUploadStatusGetParams {
    since?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKChangesAndAsyncUploadStatusJobsJobUuidChangesAndAsyncUploadStatusGetData =
    CAPBAKAsyncUploadStatusAndChangesResponse

export type CAPBAKChangesAndAsyncUploadStatusJobsJobUuidChangesAndAsyncUploadStatusGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileWithNameJobsJobUuidFilesByIdFileUuidNameGetParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    name: string
}

export type CAPBAKGetFileWithNameJobsJobUuidFilesByIdFileUuidNameGetData =
    CAPBAKSubFileStatusResponse

export type CAPBAKGetFileWithNameJobsJobUuidFilesByIdFileUuidNameGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileByPathJobsJobUuidFilesByIdFileUuidPathGetParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    path: string
}

export type CAPBAKGetFileByPathJobsJobUuidFilesByIdFileUuidPathGetData =
    CAPBAKSubFileStatusResponse

export type CAPBAKGetFileByPathJobsJobUuidFilesByIdFileUuidPathGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFilesAsArchiveJobsJobUuidFilesAsArchiveGetParams {
    zip_filename?: string
    heic_to_jpeg?: boolean
    master_only?: boolean
    include_subrevisions?: boolean
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetFilesAsArchiveJobsJobUuidFilesAsArchiveGetData = any

export type CAPBAKGetFilesAsArchiveJobsJobUuidFilesAsArchiveGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFilesAsArchiveJobsJobUuidFilesAsArchivePostParams {
    zip_filename?: string
    heic_to_jpeg?: boolean
    master_only?: boolean
    include_subrevisions?: boolean
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostFilesAsArchiveJobsJobUuidFilesAsArchivePostData = any

export type CAPBAKPostFilesAsArchiveJobsJobUuidFilesAsArchivePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchiveGetParams {
    zip_filename?: string
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchiveGetData =
    any

export type CAPBAKGetFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchiveGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchivePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchivePostData =
    any

export type CAPBAKPostFilesFromTrashAsArchiveJobsJobUuidFilesFromTrashAsArchivePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPublishShareJobsJobUuidPublishPostParams {
    to_email: string
    subject?: string
    message?: string
    to_self?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPublishShareJobsJobUuidPublishPostData =
    CAPBAKPublishShareResponse

export type CAPBAKPublishShareJobsJobUuidPublishPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFeatureFlagsFeatureFlagsGetParams {
    client_v: string
    key: CAPBAKAPIKey
    device_id: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetFeatureFlagsFeatureFlagsGetData = CAPBAKFeatureFlags

export type CAPBAKGetFeatureFlagsFeatureFlagsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetTrashCanFilesTrashCanGetParams {
    offset?: number
    limit?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Get Trash Can Files Trash Can Get */
export type CAPBAKGetTrashCanFilesTrashCanGetData = CAPBAKTrashcanFileItem[]

export type CAPBAKGetTrashCanFilesTrashCanGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Get Trash Can Albums Trash Can Albums Get */
export type CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetData =
    CAPBAKTrashcanAlbumItem[]

export type CAPBAKGetTrashCanAlbumsTrashCanAlbumsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetEmptyTrashCanEmptyTrashCanGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetEmptyTrashCanEmptyTrashCanGetData = any

export type CAPBAKGetEmptyTrashCanEmptyTrashCanGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostEmptyTrashCanEmptyTrashCanPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostEmptyTrashCanEmptyTrashCanPostData = any

export type CAPBAKPostEmptyTrashCanEmptyTrashCanPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteFilesFromTrashCanJobsJobUuidDeleteFilesFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDeleteFilesFromTrashCanJobsJobUuidDeleteFilesFromTrashCanDeleteData =
    any

export type CAPBAKDeleteFilesFromTrashCanJobsJobUuidDeleteFilesFromTrashCanDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteAlbumFromTrashCanJobsJobUuidDeleteAlbumFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDeleteAlbumFromTrashCanJobsJobUuidDeleteAlbumFromTrashCanDeleteData =
    any

export type CAPBAKDeleteAlbumFromTrashCanJobsJobUuidDeleteAlbumFromTrashCanDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteAlbumsFromTrashCanDeleteAlbumsFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKDeleteAlbumsFromTrashCanDeleteAlbumsFromTrashCanDeleteData =
    CAPBAKDeleteAlbumsResponse

export type CAPBAKDeleteAlbumsFromTrashCanDeleteAlbumsFromTrashCanDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKLoginPreflightTelenoridPreflightOauth2LoginGetParams {
    device_name: string
    locale: string
    utm_source?: string
    client_session_id?: string
    code_challenge?: string
    state?: string
    advertising_id?: string
    oauth_redirect_uri?: string
    use_telenor_id_pluss?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKLoginPreflightTelenoridPreflightOauth2LoginGetData =
    CAPBAKPreFlightOauthState

export type CAPBAKLoginPreflightTelenoridPreflightOauth2LoginGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKLoginPostflightTelenoridPostflightOauth2CallbackGetParams {
    state: string
    code: string
    error?: string
    error_description?: string
}

/** Response Login Postflight Telenorid Postflight Oauth2Callback Get */
export type CAPBAKLoginPostflightTelenoridPostflightOauth2CallbackGetData =
    | CAPBAKPostFlightSuccessResponse
    | CAPBAKPostFlightFailedResponse
    | CAPBAKPostFlightErrorResponse

export type CAPBAKLoginPostflightTelenoridPostflightOauth2CallbackGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKLoginTypeConnectLoginTypePostParams {
    key: string
    platform?: string
    platform_v?: string
    client_v?: string
    device_type?: string
    locale?: string
}

export type CAPBAKLoginTypeConnectLoginTypePostData = CAPBAKLoginTypeResp

export type CAPBAKLoginTypeConnectLoginTypePostError = CAPBAKHTTPValidationError

/** Response Register Login Success Connect Register Login Success  Unused  Post */
export type CAPBAKRegisterLoginSuccessConnectRegisterLoginSuccessUnusedPostData =
    Record<string, string>

export type CAPBAKRegisterLoginSuccessConnectRegisterLoginSuccessUnusedPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKVerifyAuthVerifyAuthGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKVerifyAuthVerifyAuthGetData = CAPBAKAuthVerificationResp

export type CAPBAKVerifyAuthVerifyAuthGetError = CAPBAKHTTPValidationError

export interface CAPBAKLogoutLogoutPostParams {
    redir?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKLogoutLogoutPostData = CAPBAKLogoutResponse

export type CAPBAKLogoutLogoutPostError = CAPBAKHTTPValidationError

export interface CAPBAKLogoutLogoutGetParams {
    redir?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKLogoutLogoutGetData = CAPBAKLogoutResponse

export type CAPBAKLogoutLogoutGetError = CAPBAKHTTPValidationError

export interface CAPBAKAccountInfoAccountInfoGetParams {
    want_name?: boolean
    want_push_token?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKAccountInfoAccountInfoGetData = CAPBAKAccountInfo

export type CAPBAKAccountInfoAccountInfoGetError = CAPBAKHTTPValidationError

export interface CAPBAKSetUserNameNamePostParams {
    name: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKSetUserNameNamePostData = any

export type CAPBAKSetUserNameNamePostError = CAPBAKHTTPValidationError

export interface CAPBAKGetDevicesDevicesGetParams {
    auth: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetDevicesDevicesGetData = CAPBAKDevicesWithAuth

export type CAPBAKGetDevicesDevicesGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetUserGrantsUserGrantsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetUserGrantsUserGrantsGetData = CAPBAKGrantInfoResponse

export type CAPBAKGetUserGrantsUserGrantsGetError = CAPBAKHTTPValidationError

export interface CAPBAKDeleteDevicesDevicesDeviceIdDeleteParams {
    auth: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    deviceId: string
}

export type CAPBAKDeleteDevicesDevicesDeviceIdDeleteData = CAPBAKMessageResponse

export type CAPBAKDeleteDevicesDevicesDeviceIdDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteDevicesDevicesDeviceIdDeletePostParams {
    auth: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    deviceId: string
}

export type CAPBAKDeleteDevicesDevicesDeviceIdDeletePostData =
    CAPBAKMessageResponse

export type CAPBAKDeleteDevicesDevicesDeviceIdDeletePostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetUserGroupsUserGroupsGetData = CAPBAKUserGroups

export type CAPBAKPutUserGroupMembershipUserGroupsUserGroupNamePutData =
    CAPBAKUserGroupEnrollmentResponse

export type CAPBAKPutUserGroupMembershipUserGroupsUserGroupNamePutError =
    CAPBAKHTTPValidationError

export type CAPBAKDeleteUserGroupMembershipUserGroupsUserGroupNameDeleteData =
    CAPBAKUserGroupWithdrawalResponse

export type CAPBAKDeleteUserGroupMembershipUserGroupsUserGroupNameDeleteError =
    CAPBAKHTTPValidationError

/** Response Get All User Groups User Groups Admin Get */
export type CAPBAKGetAllUserGroupsUserGroupsAdminGetData =
    CAPBAKUserGroupDefinition[]

export type CAPBAKPutUserGroupUserGroupsAdminUserGroupNamePutData =
    CAPBAKUserGroupCreationResponse

export type CAPBAKPutUserGroupUserGroupsAdminUserGroupNamePutError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetRegistereredRemoteVarsRemoteVarsRegistryGetParams {
    'super-auth': string
    api_key?: string
}

export type CAPBAKGetRegistereredRemoteVarsRemoteVarsRegistryGetData =
    CAPBAKRemoteVarDefinitionData

export type CAPBAKGetRegistereredRemoteVarsRemoteVarsRegistryGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPutRegisterRemoteVarRemoteVarsRegistryPutParams {
    name: string
    default_value: string
    'super-auth': string
    client_version?: string
    api_key?: string
}

export type CAPBAKPutRegisterRemoteVarRemoteVarsRegistryPutData = any

export type CAPBAKPutRegisterRemoteVarRemoteVarsRegistryPutError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteRegisteredRemoteVarRemoteVarsRegistryDeleteParams {
    name: string
    'super-auth': string
    api_key?: string
}

export type CAPBAKDeleteRegisteredRemoteVarRemoteVarsRegistryDeleteData = any

export type CAPBAKDeleteRegisteredRemoteVarRemoteVarsRegistryDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetAllRemoteVarsRemoteVarsGetParams {
    api_key: string
    client_v: string
}

export type CAPBAKGetAllRemoteVarsRemoteVarsGetData = CAPBAKRemoteVarData

export type CAPBAKGetAllRemoteVarsRemoteVarsGetError = CAPBAKHTTPValidationError

export interface CAPBAKPutUserRemoteVarRemoteVarsPutParams {
    api_key: string
    client_v: string
    name: string
    default_value: string
}

export type CAPBAKPutUserRemoteVarRemoteVarsPutData = any

export type CAPBAKPutUserRemoteVarRemoteVarsPutError = CAPBAKHTTPValidationError

export interface CAPBAKDeleteUserRemoteVarRemoteVarsDeleteParams {
    api_key: string
    client_v: string
    name: string
}

export type CAPBAKDeleteUserRemoteVarRemoteVarsDeleteData = any

export type CAPBAKDeleteUserRemoteVarRemoteVarsDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetDeletedFilesInfoJobsJobUuidDeletedFilesInfoGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetDeletedFilesInfoJobsJobUuidDeletedFilesInfoGetData =
    CAPBAKDeletedFilesInfoResponse

export type CAPBAKGetDeletedFilesInfoJobsJobUuidDeletedFilesInfoGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFilePropsJobsJobUuidFilesFileUuidPropertiesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKPostFilePropsJobsJobUuidFilesFileUuidPropertiesPostData = any

export type CAPBAKPostFilePropsJobsJobUuidFilesFileUuidPropertiesPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFilePropsJobsJobUuidFilesFileUuidPropertiesGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKGetFilePropsJobsJobUuidFilesFileUuidPropertiesGetData =
    CAPBAKFilePropResponse

export type CAPBAKGetFilePropsJobsJobUuidFilesFileUuidPropertiesGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFileExistsByChecksumHandlerJobsJobUuidFileExistsByChecksumChecksumGetParams {
    active_files?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum: string
}

/** Response Get File Exists By Checksum Handler Jobs  Job Uuid  File Exists By Checksum  Checksum  Get */
export type CAPBAKGetFileExistsByChecksumHandlerJobsJobUuidFileExistsByChecksumChecksumGetData =
    Record<string, boolean>

export type CAPBAKGetFileExistsByChecksumHandlerJobsJobUuidFileExistsByChecksumChecksumGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetFilesExistsByChecksumHandlerJobsJobUuidFilesExistsByChecksumGetParams {
    active_files?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Get Files Exists By Checksum Handler Jobs  Job Uuid  Files Exists By Checksum Get */
export type CAPBAKGetFilesExistsByChecksumHandlerJobsJobUuidFilesExistsByChecksumGetData =
    Record<string, boolean>

export type CAPBAKGetFilesExistsByChecksumHandlerJobsJobUuidFilesExistsByChecksumGetError =
    CAPBAKHTTPValidationError

export type CAPBAKGetSupportedExtensionsSupportedExtensionsGetData =
    CAPBAKSupportedExtensionsResponse

/** New Consents */
export type CAPBAKUpdateDataProtectionConsentsDataProtectionConsentValuesPostPayload =
    Record<string, boolean>

export interface CAPBAKUpdateDataProtectionConsentsDataProtectionConsentValuesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUpdateDataProtectionConsentsDataProtectionConsentValuesPostData =
    CAPBAKDataProtectionRequestResult

export type CAPBAKUpdateDataProtectionConsentsDataProtectionConsentValuesPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetDataProtectionConsentsDataProtectionConsentValuesGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetDataProtectionConsentsDataProtectionConsentValuesGetData =
    CAPBAKConsentsResponse

export type CAPBAKGetDataProtectionConsentsDataProtectionConsentValuesGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKRequestDataAccessDataProtectionRequestDataAccessGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKRequestDataAccessDataProtectionRequestDataAccessGetData = any

export type CAPBAKRequestDataAccessDataProtectionRequestDataAccessGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKAllowNewDataAccessRequestDataProtectionRequestDataAccessAvailabilityGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKAllowNewDataAccessRequestDataProtectionRequestDataAccessAvailabilityGetData =
    CAPBAKDataProtectionRequestResult

export type CAPBAKAllowNewDataAccessRequestDataProtectionRequestDataAccessAvailabilityGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKRequestAccountDeletionDataProtectionRequestAccountDeletionPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKRequestAccountDeletionDataProtectionRequestAccountDeletionPostData =
    CAPBAKAccountDeletionRequestResult

export type CAPBAKRequestAccountDeletionDataProtectionRequestAccountDeletionPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostCommentJobsJobUuidFilesByIdFileUuidCommentsPostParams {
    comment: string
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKPostCommentJobsJobUuidFilesByIdFileUuidCommentsPostData =
    CAPBAKCommentResponse

export type CAPBAKPostCommentJobsJobUuidFilesByIdFileUuidCommentsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKEditCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidPutParams {
    comment: string
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    commentUuid: string
}

export type CAPBAKEditCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidPutData =
    CAPBAKCommentResponse

export type CAPBAKEditCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidPutError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidDeleteParams {
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    commentUuid: string
}

export type CAPBAKDeleteCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidDeleteData =
    CAPBAKCommentResponse

export type CAPBAKDeleteCommentJobsJobUuidFilesByIdFileUuidCommentsCommentUuidDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostReactionJobsJobUuidFilesByIdFileUuidReactionPostParams {
    reaction: CAPBAKReactionType
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKPostReactionJobsJobUuidFilesByIdFileUuidReactionPostData =
    CAPBAKSetReactionResponse

export type CAPBAKPostReactionJobsJobUuidFilesByIdFileUuidReactionPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteReactionJobsJobUuidFilesByIdFileUuidReactionDeleteParams {
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export type CAPBAKDeleteReactionJobsJobUuidFilesByIdFileUuidReactionDeleteData =
    CAPBAKSetReactionResponse

export type CAPBAKDeleteReactionJobsJobUuidFilesByIdFileUuidReactionDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetReactionsForJobJobsJobUuidReactionsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetReactionsForJobJobsJobUuidReactionsGetData =
    CAPBAKReactionsResponse

export type CAPBAKGetReactionsForJobJobsJobUuidReactionsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetMittTelenorUserInfoMittTelenorNoUserInfoTelenorIdGetParams {
    locale?: string
    telenorId: string
}

export type CAPBAKGetMittTelenorUserInfoMittTelenorNoUserInfoTelenorIdGetData =
    CAPBAKNbsStorageMittTelenorUserInfo

export type CAPBAKGetMittTelenorUserInfoMittTelenorNoUserInfoTelenorIdGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKSetPrivacyModeJobsJobUuidPrivacyModePostParams {
    mode: CAPBAKPrivacyMode
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKSetPrivacyModeJobsJobUuidPrivacyModePostData =
    CAPBAKJobSetPermissionsResponse

export type CAPBAKSetPrivacyModeJobsJobUuidPrivacyModePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetPrivacyModeJobsJobUuidPrivacyModeGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetPrivacyModeJobsJobUuidPrivacyModeGetData =
    CAPBAKJobPrivacyModeResponse

export type CAPBAKGetPrivacyModeJobsJobUuidPrivacyModeGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKSetPermissionsJobsJobUuidPermissionsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    allow_comments?: 0 | 1
    allow_uploads?: 0 | 1
    allow_reactions?: 0 | 1
    allow_sharing?: 0 | 1
    allow_anonymous_access?: 0 | 1
    allow_discovery?: 0 | 1
    jobUuid: string
}

export type CAPBAKSetPermissionsJobsJobUuidPermissionsPostData =
    CAPBAKJobSetPermissionsResponse

export type CAPBAKSetPermissionsJobsJobUuidPermissionsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetPermissionsJobsJobUuidPermissionsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetPermissionsJobsJobUuidPermissionsGetData =
    CAPBAKJobPermissionsResponse

export type CAPBAKGetPermissionsJobsJobUuidPermissionsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKUpdatePushTokenPushNotificationUpdatePostParams {
    push_token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUpdatePushTokenPushNotificationUpdatePostData =
    CAPBAKPushTokenResponse

export type CAPBAKUpdatePushTokenPushNotificationUpdatePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKUpdatePushTokenUpdatePushTokenPostParams {
    push_token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUpdatePushTokenUpdatePushTokenPostData =
    CAPBAKPushTokenResponse

export type CAPBAKUpdatePushTokenUpdatePushTokenPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostParams {
    push_type: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostData =
    CAPBAKPushTokenResponse

export type CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostParams2 {
    push_type: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostResult =
    CAPBAKPushTokenResponse

export type CAPBAKPushNotificationFeedbackPushNotificationFeedbackPostFail =
    CAPBAKHTTPValidationError

export interface CAPBAKSubscribeToStoryJobsJobUuidSubscribePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKSubscribeToStoryJobsJobUuidSubscribePostData = any

export type CAPBAKSubscribeToStoryJobsJobUuidSubscribePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKUnsubscribeToStoryJobsJobUuidUnsubscribePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKUnsubscribeToStoryJobsJobUuidUnsubscribePostData = any

export type CAPBAKUnsubscribeToStoryJobsJobUuidUnsubscribePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostAppleStoreReceiptAppleStoreReceiptPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostAppleStoreReceiptAppleStoreReceiptPostData =
    CAPBAKAppleStoreResponse

export type CAPBAKPostAppleStoreReceiptAppleStoreReceiptPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKValidateAndroidPurchaseAndroidValidatePurchasePostParams {
    signature: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKValidateAndroidPurchaseAndroidValidatePurchasePostData =
    CAPBAKAndroidStoreResponse

export type CAPBAKValidateAndroidPurchaseAndroidValidatePurchasePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetAppleStoreProductsAppleStoreProductsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetAppleStoreProductsAppleStoreProductsGetData =
    CAPBAKProductListResponse

export type CAPBAKGetAppleStoreProductsAppleStoreProductsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetAndroidStoreProductsAndroidStoreProductsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetAndroidStoreProductsAndroidStoreProductsGetData =
    CAPBAKProductListResponse

export type CAPBAKGetAndroidStoreProductsAndroidStoreProductsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetStripeProductsStripeProductsGetParams {
    test?: boolean
    production?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetStripeProductsStripeProductsGetData =
    CAPBAKStripeProductListResponse

export type CAPBAKGetStripeProductsStripeProductsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKLogoutBackchannelLogoutBackchannelPostParams {
    logout_token?: string
}

export type CAPBAKLogoutBackchannelLogoutBackchannelPostData =
    CAPBAKMessageResponse

export type CAPBAKLogoutBackchannelLogoutBackchannelPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetProfileAlbumProfileAlbumGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetProfileAlbumProfileAlbumGetData = CAPBAKPictureJobResponse

export type CAPBAKGetProfileAlbumProfileAlbumGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKValidatePurchaseStatusStripePurchaseSubscriptionIdValidatePurchaseGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export type CAPBAKValidatePurchaseStatusStripePurchaseSubscriptionIdValidatePurchaseGetData =
    any

export type CAPBAKValidatePurchaseStatusStripePurchaseSubscriptionIdValidatePurchaseGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostStripePaymentMethodStripePaymentMethodPostParams {
    token: string
    card: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostStripePaymentMethodStripePaymentMethodPostData = any

export type CAPBAKPostStripePaymentMethodStripePaymentMethodPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetStripePaymentMethodStripePaymentMethodGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetStripePaymentMethodStripePaymentMethodGetData =
    CAPBAKPaymentInfoResponse

export type CAPBAKGetStripePaymentMethodStripePaymentMethodGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKCancelStripePurchaseStripePurchaseSubscriptionIdCancelPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export type CAPBAKCancelStripePurchaseStripePurchaseSubscriptionIdCancelPostData =
    any

export type CAPBAKCancelStripePurchaseStripePurchaseSubscriptionIdCancelPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKReactivateStripePurchaseStripePurchaseSubscriptionIdReactivatePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export type CAPBAKReactivateStripePurchaseStripePurchaseSubscriptionIdReactivatePostData =
    any

export type CAPBAKReactivateStripePurchaseStripePurchaseSubscriptionIdReactivatePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKUpdateStripePurchaseUpdateStripePurchasePostParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUpdateStripePurchaseUpdateStripePurchasePostData =
    CAPBAKUpdateStripePurchaseResponse

export type CAPBAKUpdateStripePurchaseUpdateStripePurchasePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKUpdateStripePurchaseNewStripeV1PurchasePutParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKUpdateStripePurchaseNewStripeV1PurchasePutData =
    CAPBAKUpdateStripePurchaseResponse

export type CAPBAKUpdateStripePurchaseNewStripeV1PurchasePutError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostStripePurchaseNewStripeV1PurchasePostParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Post Stripe Purchase New Stripe V1 Purchase Post */
export type CAPBAKPostStripePurchaseNewStripeV1PurchasePostData =
    | CAPBAKPurchaseStripeSubscriptionResponse
    | CAPBAKStripeSubscriptionActionRequiredResponse

export type CAPBAKPostStripePurchaseNewStripeV1PurchasePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostStripePurchaseStripePurchasePostParams {
    plan: string
    card: string
    token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Post Stripe Purchase Stripe Purchase Post */
export type CAPBAKPostStripePurchaseStripePurchasePostData =
    | CAPBAKPurchaseStripeSubscriptionResponse
    | CAPBAKStripeSubscriptionActionRequiredResponse

export type CAPBAKPostStripePurchaseStripePurchasePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostCreateSetupIntentStripeCreateSetupIntentPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostCreateSetupIntentStripeCreateSetupIntentPostData =
    CAPBAKStripeSetupIntentCreateResponse

export type CAPBAKPostCreateSetupIntentStripeCreateSetupIntentPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFinishSetupIntentStripeFinishSetupIntentPostParams {
    setup_intent_id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostFinishSetupIntentStripeFinishSetupIntentPostData =
    CAPBAKMessageResponse

export type CAPBAKPostFinishSetupIntentStripeFinishSetupIntentPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetCustomerExistsStripeCustomerExistsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetCustomerExistsStripeCustomerExistsGetData =
    CAPBAKMessageResponse

export type CAPBAKGetCustomerExistsStripeCustomerExistsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetCoverAlbumCoverAlbumGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetCoverAlbumCoverAlbumGetData = CAPBAKPictureJobResponse

export type CAPBAKGetCoverAlbumCoverAlbumGetError = CAPBAKHTTPValidationError

export interface CAPBAKPostProfilePictureProfilePicturePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostProfilePictureProfilePicturePostData =
    CAPBAKProfilePictureUploadResponse

export type CAPBAKPostProfilePictureProfilePicturePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteProfilePictureProfilePictureDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKDeleteProfilePictureProfilePictureDeleteData =
    CAPBAKMessageAndResultResponse

export type CAPBAKDeleteProfilePictureProfilePictureDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKRollbackAlbumJobsJobUuidRollbackJobPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKRollbackAlbumJobsJobUuidRollbackJobPostData = any

export type CAPBAKRollbackAlbumJobsJobUuidRollbackJobPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKRollbackAlbumsRollbackAlbumsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKRollbackAlbumsRollbackAlbumsPostData =
    CAPBAKRestoreAlbumsResponse

export type CAPBAKRollbackAlbumsRollbackAlbumsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKRollbackFileJobsJobUuidRollbackPostParams {
    id: string
    rollback_group?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKRollbackFileJobsJobUuidRollbackPostData =
    CAPBAKUsageAndQuotaResponse

export type CAPBAKRollbackFileJobsJobUuidRollbackPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFileDeleteJobsJobUuidFilesByIdFileUuidDeletePostParams {
    recursive: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

/** Response Post File Delete Jobs  Job Uuid  Files By Id  File Uuid  Delete Post */
export type CAPBAKPostFileDeleteJobsJobUuidFilesByIdFileUuidDeletePostData =
    CAPBAKUsageAndQuotaResponse

export type CAPBAKPostFileDeleteJobsJobUuidFilesByIdFileUuidDeletePostError =
    CAPBAKHTTPValidationError

export type CAPBAKPostUserCallbacksUsercallbacksRefIdPostData =
    CAPBAKCancelAccountDeletion

export type CAPBAKPostUserCallbacksUsercallbacksRefIdPostError =
    | CAPBAKCancelAccountDeletion
    | CAPBAKHTTPValidationError

export interface CAPBAKGetOptionNamesOptionsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

/** Response Get Option Names Options Get */
export type CAPBAKGetOptionNamesOptionsGetData = string[]

export type CAPBAKGetOptionNamesOptionsGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetOptionOptionsNameGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

/** Response Get Option Options  Name  Get */
export type CAPBAKGetOptionOptionsNameGetData = CAPBAKOptionResponse | object

export type CAPBAKGetOptionOptionsNameGetError = CAPBAKHTTPValidationError

export interface CAPBAKDeleteOptionOptionsNameDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

export type CAPBAKDeleteOptionOptionsNameDeleteData = any

export type CAPBAKDeleteOptionOptionsNameDeleteError = CAPBAKHTTPValidationError

export interface CAPBAKPutOptionOptionsNamePutParams {
    value?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

export type CAPBAKPutOptionOptionsNamePutData = any

export type CAPBAKPutOptionOptionsNamePutError = CAPBAKHTTPValidationError

export interface CAPBAKPostClientEventClientEventPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostClientEventClientEventPostData = any

export type CAPBAKPostClientEventClientEventPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteMemoryClientEventClientEventMemoryDeletionDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKDeleteMemoryClientEventClientEventMemoryDeletionDeleteData =
    any

export type CAPBAKDeleteMemoryClientEventClientEventMemoryDeletionDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostAccountAttributeAccountAttributePostParams {
    account_attribute_key: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostAccountAttributeAccountAttributePostData =
    CAPBAKAccountAttributeResponse

export type CAPBAKPostAccountAttributeAccountAttributePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetAccountAttributesAccountAttributeGetParams {
    account_attribute_key?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetAccountAttributesAccountAttributeGetData =
    CAPBAKAccountAttributeResponse

export type CAPBAKGetAccountAttributesAccountAttributeGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteAccountAttributesAccountAttributeDeleteParams {
    account_attribute_key?: string
    delete_all_account_attributes?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKDeleteAccountAttributesAccountAttributeDeleteData = any

export type CAPBAKDeleteAccountAttributesAccountAttributeDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetPushNotificationStatsPushNotificationStatsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKGetPushNotificationStatsPushNotificationStatsGetData =
    CAPBAKPushStatisticsResponse

export type CAPBAKGetPushNotificationStatsPushNotificationStatsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteJobDeleteJobsJobUuidDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDeleteJobDeleteJobsJobUuidDeleteData = any

export type CAPBAKDeleteJobDeleteJobsJobUuidDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobDeleteJobsJobUuidDeletePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobDeleteJobsJobUuidDeletePostData = any

export type CAPBAKPostJobDeleteJobsJobUuidDeletePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteCcStripeStripeUserCreditCardDeleteParams {
    card_id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKDeleteCcStripeStripeUserCreditCardDeleteData =
    CAPBAKStripeDeleteCCResponse

export type CAPBAKDeleteCcStripeStripeUserCreditCardDeleteError =
    | CAPBAKStripeDeleteCCResponse
    | CAPBAKHTTPValidationError

export interface CAPBAKKeepAllFilesJobsJobUuidKeepAllFilesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKKeepAllFilesJobsJobUuidKeepAllFilesPostData = any

export type CAPBAKKeepAllFilesJobsJobUuidKeepAllFilesPostError =
    | CAPBAKMessageResponse
    | CAPBAKKeepFilesQuotaExceeded
    | CAPBAKHTTPValidationError

export interface CAPBAKKeepFilesJobsJobUuidKeepFilesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKKeepFilesJobsJobUuidKeepFilesPostData = any

export type CAPBAKKeepFilesJobsJobUuidKeepFilesPostError =
    | CAPBAKMessageResponse
    | CAPBAKKeepFilesQuotaExceeded
    | CAPBAKHTTPValidationError

export interface CAPBAKPostApproveTosApproveTosPostParams {
    tos_version: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostApproveTosApproveTosPostData = any

export type CAPBAKPostApproveTosApproveTosPostError = CAPBAKHTTPValidationError

export interface CAPBAKPostRecipientsJobsJobUuidRecipientsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostRecipientsJobsJobUuidRecipientsPostData =
    CAPBAKSetJobRecipientResponse

export type CAPBAKPostRecipientsJobsJobUuidRecipientsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobExpirationJobsJobUuidExpirationDatePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobExpirationJobsJobUuidExpirationDatePostData = any

export type CAPBAKPostJobExpirationJobsJobUuidExpirationDatePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobPasswordJobsJobUuidPasswordPostParams {
    password: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobPasswordJobsJobUuidPasswordPostData = any

export type CAPBAKPostJobPasswordJobsJobUuidPasswordPostError =
    CAPBAKHTTPValidationError

/** Data */
export type CAPBAKClientStatsClientStatsPostPayload = object

export interface CAPBAKClientStatsClientStatsPostParams {
    device_id: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKClientStatsClientStatsPostData = any

export type CAPBAKClientStatsClientStatsPostError = CAPBAKHTTPValidationError

export interface CAPBAKPostJobCoverJobsJobUuidCoverPostParams {
    id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobCoverJobsJobUuidCoverPostData = any

export type CAPBAKPostJobCoverJobsJobUuidCoverPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKDeleteJobCoverJobsJobUuidCoverDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKDeleteJobCoverJobsJobUuidCoverDeleteData = any

export type CAPBAKDeleteJobCoverJobsJobUuidCoverDeleteError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobNameJobsJobUuidNamePostParams {
    name: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobNameJobsJobUuidNamePostData = any

export type CAPBAKPostJobNameJobsJobUuidNamePostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostJobStorePasswordJobsJobUuidStorePasswordPostParams {
    password: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKPostJobStorePasswordJobsJobUuidStorePasswordPostData = any

export type CAPBAKPostJobStorePasswordJobsJobUuidStorePasswordPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetMetatagsInjectMetatagsJobUuidGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Get Metatags Inject Metatags  Job Uuid  Get */
export type CAPBAKGetMetatagsInjectMetatagsJobUuidGetData = string

export type CAPBAKGetMetatagsInjectMetatagsJobUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetSearchVerifyJobAccessSearchVerifyJobUuidGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKGetSearchVerifyJobAccessSearchVerifyJobUuidGetData =
    CAPBAKSearchUserAttrs

export type CAPBAKGetSearchVerifyJobAccessSearchVerifyJobUuidGetError =
    CAPBAKHTTPValidationError

export type CAPBAKPostSearchNotifySearchNotifyJobUuidPostData =
    CAPBAKSearchUserAttrs

export type CAPBAKPostSearchNotifySearchNotifyJobUuidPostError =
    CAPBAKHTTPValidationError

export type CAPBAKGetSearchUserInfoSearchUserInfoJobUuidGetData =
    CAPBAKSearchUserAttrs

export type CAPBAKGetSearchUserInfoSearchUserInfoJobUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetPhotosClassifiedAsDocumentsJobsJobUuidClassificationGetParams {
    category: CAPBAKClassificationCategory
    threshold: number
    jobUuid: string
}

/** Response Get Photos Classified As Documents Jobs  Job Uuid  Classification Get */
export type CAPBAKGetPhotosClassifiedAsDocumentsJobsJobUuidClassificationGetData =
    object

export type CAPBAKGetPhotosClassifiedAsDocumentsJobsJobUuidClassificationGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostUserStatisticsEventsUserStatisticsEventsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostUserStatisticsEventsUserStatisticsEventsPostData =
    CAPBAKPostCumulusEventsResponse

export type CAPBAKPostUserStatisticsEventsUserStatisticsEventsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostDebugEventsDebugEventsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostDebugEventsDebugEventsPostData =
    CAPBAKPostCumulusEventsResponse

export type CAPBAKPostDebugEventsDebugEventsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostRunTimeStatisticsRunTimeStatisticsPostParams {
    device_id: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKPostRunTimeStatisticsRunTimeStatisticsPostData = any

export type CAPBAKPostRunTimeStatisticsRunTimeStatisticsPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKShouldSendUserStatisticsEventsShouldSendUserStatisticsEventsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKShouldSendUserStatisticsEventsShouldSendUserStatisticsEventsGetData =
    CAPBAKShouldSendUserStatsEventsResponse

export type CAPBAKShouldSendUserStatisticsEventsShouldSendUserStatisticsEventsGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetProfilePicture3ProfileUserUuidPictureGetParams {
    area?: number
    userUuid: string
}

export type CAPBAKGetProfilePicture3ProfileUserUuidPictureGetData = any

export type CAPBAKGetProfilePicture3ProfileUserUuidPictureGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetThumbnail3FileUuidGetParams {
    share: string
    area?: number
    width?: number
    height?: number
    original_size?: boolean
    fileUuid: string
}

export type CAPBAKGetThumbnail3FileUuidGetData = any

export type CAPBAKGetThumbnail3FileUuidGetError = CAPBAKHTTPValidationError

export interface CAPBAKGetThumbnailTrash3TrashJobUuidFileUuidGetParams {
    area?: number
    original_size?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    fileUuid: string
    jobUuid: string
}

export type CAPBAKGetThumbnailTrash3TrashJobUuidFileUuidGetData = any

export type CAPBAKGetThumbnailTrash3TrashJobUuidFileUuidGetError =
    CAPBAKHTTPValidationError

export interface CAPBAKPostFilesByUuidJobsJobUuidFilesByUuidPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Response Post Files By Uuid Jobs  Job Uuid  Files By Uuid Post */
export type CAPBAKPostFilesByUuidJobsJobUuidFilesByUuidPostData =
    CAPBAKFileObjectResponse[]

export type CAPBAKPostFilesByUuidJobsJobUuidFilesByUuidPostError =
    CAPBAKHTTPValidationError

/** Contacts */
export type CAPBAKSaveDigitalLegacyDigitalLegacyPostPayload =
    CAPBAKDigitalLegacyContact[]

export interface CAPBAKSaveDigitalLegacyDigitalLegacyPostParams {
    digital_legacy_choice: CAPBAKDigitalLegacyChoice
    language: CAPBAKLanguage
}

export type CAPBAKSaveDigitalLegacyDigitalLegacyPostData = any

export type CAPBAKSaveDigitalLegacyDigitalLegacyPostError =
    CAPBAKHTTPValidationError

export interface CAPBAKGetDigitalLegacyDigitalLegacyGetParams {
    user_uuid: string
}

export type CAPBAKGetDigitalLegacyDigitalLegacyGetData = CAPBAKDigitalLegacyInfo

export type CAPBAKGetDigitalLegacyDigitalLegacyGetError =
    CAPBAKHTTPValidationError
